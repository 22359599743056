import { FC } from 'react';
import classes from './styles/index.module.scss';
import { Section } from "../../components/Section";
import { Collapse } from "../../../../components/Collapse";
import { FlexBox } from "../../../../../../components/FlexBox";
import { Title } from "../../../../components/Topography/Title";
import { FAQ } from "./const";
import { useResizeContext } from "../../../../providers/ResizeProvider/ResizeProvider";

export const FAQSection: FC = () => {
    const { isMobile } = useResizeContext();

    return (
        <Section withoutPadding>
            <FlexBox column gap='large' justify='center' style={{ maxWidth: '80%', margin: 'auto' }}>
                <FlexBox justify='center'>
                    <Title level={4} size='big' weight='extraBold'>FAQ</Title>
                </FlexBox>
                {FAQ.map(({ name, FAQs }) => {
                    const FAQsFirst = FAQs.slice(0, Math.ceil(FAQs.length / 2));
                    const FAQsSecond = FAQs.slice(-Math.floor(FAQs.length / 2));
                    return (
                        <FlexBox gap='middle' column justify='center' key={name}>
                            <FlexBox justify='center'>
                                <Title level={5} size='normal' weight='bold'>{name}</Title>
                            </FlexBox>
                            {isMobile ? (
                                <FlexBox column gap='medium' className={classes.collapse}>
                                    {FAQs.map(({ title, content }) => (
                                        <Collapse key={title} title={title} children={content}/>
                                    ))}
                                </FlexBox>
                            ) : (
                                <FlexBox gap='middle' justify='center'>
                                    <FlexBox column gap='medium' className={classes.collapse}>
                                        {FAQsFirst.map(({ title, content }) => (
                                            <Collapse key={title} title={title} children={content}/>
                                        ))}
                                    </FlexBox>
                                    <FlexBox column gap='medium' className={classes.collapse}>
                                        {FAQsSecond.map(({ title, content }) => (
                                            <Collapse key={title} title={title} children={content}/>
                                        ))}
                                    </FlexBox>
                                </FlexBox>
                            )}
                        </FlexBox>
                    )
                })}
            </FlexBox>
        </Section>
    );
}