import { FAQSectionType } from "./types";
import { Link } from "react-router-dom";
import { SUPPORT_EMAIL } from "../../../../const";

export const FAQ: FAQSectionType[] = [
    {
        name: 'Screenshots',
        FAQs: [
            {
                title: 'How do I take a screenshot using this extension?',
                content: 'To take a screenshot using Screeny, open extension popup, select screenshot tab in the top-left corner, select mode such as “Whole page”, “Visible area” or “Custom area”, take a screenshot, markup if needed and save to your computer or upload to Google Drive',
            },
            {
                title: 'What types of screenshots can I capture with this extension?',
                content: 'You can capture the whole webpage, or just the visible area of the webpage, or even just a selected custom area that is determined by you',
            },
            {
                title: 'What image formats are supported for saving screenshots?',
                content: 'Currently, there is an option to save screenshots in PNG or PDF formats',
            },
            {
                title: 'How do I access my saved screenshots?',
                content: 'If you download a screenshot to your computer, the screenshot will be located within the download folder that is set by default in your browser. If you choose to upload to Google Drive, the screenshot will be saved to your root Google Drive folder. After upload to Google Drive is completed, a popup message will appear containing a link to your latest screenshot. Finally, there is an option to access the full history of your screenshots by accessing the history tab in Screeny extension popup',
            },
            {
                title: 'Can I customize the keyboard shortcuts for taking screenshots?',
                content: 'To set a custom keyboard shortcut, open the extensions tab by pressing a puzzle icon at the top right corner of Chrome and pressing the “Manage Extensions” button at the bottom. Select the “Keyboard shortcuts” tab at the top left corner, scroll down to Screeny section and set your own hotkey using a pencil button',
            },
            {
                title: 'Does the extension support capturing screenshots of long web pages?',
                content: 'Yes, you can capture the whole web page with Screeny',
            },
        ]
    },
    {
        name: 'Screen Videos',
        FAQs: [
            {
                title: 'How do I record my screen using this extension?',
                content: 'To record your screen using Screeny, navigate to the “Screen record” tab in the top middle of extension popup, select capturing mode such as “Desktop” or “Both” (which means desktop and camera), select “Entire screen”, “Window” or “Chrome Tab” screen sharing option, record your action and press “Stop sharing” button. Then you will be able to replay your screen record and save it to your computer or upload to Google Drive.',
            },
            {
                title: 'Can I capture the entire screen, a specific window, or a selected region?',
                content: 'If you would like to capture a screen record, there is an option to capture the entire screen, or a selected window, or just a specific Chrome tab. Unfortunately, there is no option to capture a selected region.',
            },
            {
                title: 'What video formats are supported for saving recorded videos?',
                content: 'Currently, Screeny supports only WEBM format',
            },
            {
                title: 'How do I access my saved video recordings?',
                content: 'You can save video recordings to your downloads folder or upload them to Google Drive and find them in a root folder. Finally, you can view a full history of your screen records by accessing the history tab in Screeny extension popup',
            },
            {
                title: 'Is there a built-in video editor for editing recorded videos?',
                content: 'Currently, Screeny supports editing screenshots only',
            },
            {
                title: 'Is it possible to record screen and webcam simultaneously?',
                content: 'Yes, to record screen and webcam simultaneously, navigate to “Both” mode within Screen record tab of the extension',
            },
        ],
    },
    {
        name: 'General',
        FAQs: [
            {
                title: 'Is the extension free, or does it require a purchase or subscription?',
                content: 'Screeny extension is 100% free, there is no purchases or subscriptions required',
            },
            {
                title: 'How do I contact customer support for this extension?',
                content: <>
                    Please send email at <Link to={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link> to contact us
                </>,
            },
        ],
    }
];