import { FC } from 'react';
import classes from './styles/index.module.scss';
import classNames from "classnames";
import { PaddingBox } from "../../../../../../components/PaddingBox";

type SectionPropsType = {
    withoutPadding?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>

export const Section: FC<SectionPropsType> = (
    {
        className,
        children,
        withoutPadding,
        ...props
    }
) => {
    const sectionClasses = classNames(className, classes.section);

    return (
        <section className={sectionClasses} {...props}>
            <PaddingBox largeVR largeHR={!withoutPadding}>
                {children}
            </PaddingBox>
        </section>
    );
}