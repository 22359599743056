import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';

type RerenderStateValueType = {
    state: boolean,
    rerender: () => void;
}

const RerenderContext = createContext(undefined as unknown as RerenderStateValueType);

export function useRerenderContext () {
    return useContext(RerenderContext);
}

type RerenderProviderType = {
    children: ReactNode;
}

export const RerenderProvider: FC<RerenderProviderType> = ({ children }) => {
    const [state, setState] = useState(false);

    const rerender = () => {
        setState(prevState => !prevState)
    };

    useEffect(() => {
        try {
            chrome.storage.local.onChanged.addListener(() => {
                rerender();
            })
        }
        catch (error) {}
    }, []);

    return (
        <RerenderContext.Provider value={{state, rerender}}>
            {children}
        </RerenderContext.Provider>
    );
}