import React, { FC, useEffect } from 'react'
import { useLocation, useSearchParams } from "react-router-dom";
import { RoutePages, RouteParams } from "./const";
import { Main } from "./pages/Main";
import { Error404 } from "../Main/pages/Error404";
import { HowToUse } from "./pages/HowToUse";
import { AboutUs } from "./pages/AboutUs";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

export const WebsiteRoutes: FC = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const page = searchParams.get(RouteParams.Page) as RoutePages;

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [location]);

    return (
        <>
            {/*@ts-ignore*/}
            {page === null && (
                <Main/>
            )}
            {page === RoutePages.HowToUse && (
                <HowToUse/>
            )}
            {page === RoutePages.AboutUs && (
                <AboutUs/>
            )}
            {page === RoutePages.PrivacyPolicy && (
                <PrivacyPolicy/>
            )}
            {/*@ts-ignore*/}
            {searchParams.size > 0 && !Object.values(RoutePages).includes(page) && (
                <Error404 isWebsite/>
            )}
        </>
    );
};