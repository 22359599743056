import React, { FC } from 'react';
import { Button as ButtonAntd, Typography } from "antd";
import { Error404Image } from "./Error404Image";
import { FlexBox } from "../../../../components/FlexBox";
import { PaddingBox } from "../../../../components/PaddingBox";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { RoutePath } from "../../const";
import { Button } from "../../../Website/components/Button";
import { RouteUrls } from "../../../Website/const";

const { Title } = Typography;

type Error404PropsType = {
    isWebsite?: boolean;
}

export const Error404: FC<Error404PropsType> = ({ isWebsite }) => {
    return (
        <FlexBox justify='center' align='middle' style={{ minHeight: 'calc(100vh - 255px)' }}>
            <Helmet>
                <title>Error 404</title>
            </Helmet>
            <PaddingBox largeVR>
                <PaddingBox largeVR>
                    <FlexBox justify='center' align='middle' column>
                        <Error404Image/>
                        <Title level={4}>Sorry, the page you visited does not exist.</Title>
                        {isWebsite ? (
                            <Link to={RouteUrls.Home}>
                                <Button>Home</Button>
                            </Link>
                        ) : (
                            <Link to={RoutePath.History}>
                                <ButtonAntd>History</ButtonAntd>
                            </Link>
                        )}
                    </FlexBox>
                </PaddingBox>
            </PaddingBox>
        </FlexBox>
    );
}