import { FC } from 'react';
import { Section } from "../../components/Section";
import { FlexBox } from "../../../../../../components/FlexBox";
import { TryExtensionCatImage } from "../../../../images/TryExtensionCatImage";
import { Title } from "../../../../components/Topography/Title";
import { Link } from "react-router-dom";
import { EXTENSION_STORE_URL } from "../../../../const";
import { Button } from "../../../../components/Button";
import { Span } from "../../../../components/Topography/Span";
import { ArrowRight } from "../../../../icons/ArrowRight";
import { useResizeContext } from "../../../../providers/ResizeProvider/ResizeProvider";


export const TryExtensionSection: FC = () => {
    const { isMobile } = useResizeContext();
    return (
        <Section>
            <FlexBox column gap='large' justify='center' align='middle'>
                <FlexBox column gap='extraLarge' justify='center' align='middle' style={{ maxWidth: '780px' }}>
                    <TryExtensionCatImage/>
                    <Title level={4} size={isMobile ? 'normal' : 'big'} weight='extraBold' style={{ textAlign: 'center' }}>
                        You are just a few clicks away from the perfect Screenshot Tool
                    </Title>
                </FlexBox>
                <Link to={EXTENSION_STORE_URL} target='_blank'>
                    <Button>
                        <Span>Try Extension Now</Span>
                        <ArrowRight/>
                    </Button>
                </Link>
            </FlexBox>
        </Section>
    );
}