import React from 'react';
import ReactDOM from 'react-dom/client';
import '../../styles/index.css';
import '../../styles/colors.css';
import './styles/index.css'
import { HTMLFile } from "../../const";
import { BrowserRouter as Router } from "react-router-dom";
import { Website } from "./Website";

const rootElement = document.getElementById(HTMLFile.Main);

const root = ReactDOM.createRoot(
    rootElement as HTMLElement,
);

root.render(
    <React.StrictMode>
        <Router>
            <Website/>
        </Router>
    </React.StrictMode>
);
