import React, { FC } from 'react'
import classNames from "classnames";
import classes from './styles/index.module.scss';
import useDarkMode from "../hooks/useDarkMode";

export type SVGType = {
    isIcon?: boolean;
    isThemed?: boolean;
} & React.SVGProps<SVGSVGElement>

export const SVG: FC<SVGType> = (
    { isIcon, isThemed, className, ...prop }
) => {
    const size = isIcon ? {
        width: '22px',
        height: '22px',
    } : {};
    const isDark = useDarkMode();
    const svgClasses = classNames(className, {
        [classes.dark]: isThemed && isDark,
        [classes.light]: isThemed && !isDark,
    });

    return (
        <svg
            {...size}
            className={svgClasses}
            {...prop}
        />
    );
};
