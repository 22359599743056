import React, { FC, useCallback, useRef } from 'react';
import classes from './styles/index.module.scss';
import { WelcomeComponent } from "../../components/WelcomeComponent";
import { FlexBox } from "../../../../components/FlexBox";
import { Title } from "../../components/Topography/Title";
import { Button } from "../../components/Button";
import { ArrowRight } from "../../icons/ArrowRight";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import howToUseImage1Url from '../../images/HowToUse1.png';
import howToUseImage2Url from '../../images/HowToUse2.png';
import howToUseImage3Url from '../../images/HowToUse3.png';
import howToUseImage4Url from '../../images/HowToUse4.png';
import howToUseImage5Url from '../../images/HowToUse5.png';
import { Section } from "../Main/components/Section";
import { Span } from "../../components/Topography/Span";
import { UList } from "../../components/Topography/UList";
import { getPageTitle } from "../../utils/getPageTitle";
import { Head } from "../../components/Head";

const title = 'How to Use';
const description = 'Launch your web browser and navigate to the extensions or add-ons store...';

export const HowToUse: FC = () => {
    const { isMobile } = useResizeContext();
    const nodeRef = useRef<HTMLDivElement>(null);

    const onContinueClick = useCallback(() => {
        window.scroll({ top: (nodeRef.current?.getBoundingClientRect().top ?? 0) - (isMobile ? 72 : 104) });
    }, [isMobile]);

    return (
        <>
            <Head title={getPageTitle(title)} description={description}/>
            <WelcomeComponent
                content={(
                    <FlexBox column align='middle' justify={isMobile ? 'end' : 'space-evenly'} gap='large' style={{ height: '100%', maxWidth: '1000px' }}>
                        <FlexBox column align='middle' gap='large'>
                            <Title level={2} size={isMobile ? "big" : 'extraLarge'} weight="extraBold" style={{ textAlign: 'center' }}>
                                {title}
                            </Title>
                        </FlexBox>
                        <Button onClick={onContinueClick}>
                            <span>Continue</span>
                            <ArrowRight style={{ transform: 'rotate(90deg)' }}/>
                        </Button>
                    </FlexBox>
                )}
            />
            <div ref={nodeRef}>
                <Section>
                    <FlexBox column align='middle' justify='center'>
                        <FlexBox style={{ maxWidth: '744px' }} align='middle' justify='center' gap='extraLarge' column>
                            <FlexBox gap='middle' column>
                                <div className={classes.image}>
                                    <img src={howToUseImage1Url} alt=""/>
                                </div>
                                <Title level={3} size='big' weight='bold'>
                                    Installation
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    <Span>
                                        To use Screeny, you must first install it within your web browser. Follow these instructions:
                                    </Span>
                                    <UList markType='decimal'>
                                        <li>Launch your web browser and navigate to the extensions or add-ons store</li>
                                        <li>Search for “Screeny”</li>
                                        <li>Select “Add to Browser” or “Install”</li>
                                        <li>Once the extension is installed, locate the puzzle icon on the browser toolbar, and then click the pin button adjacent to the Screeny extension</li>
                                    </UList>
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <div className={classes.image}>
                                    <img src={howToUseImage2Url} alt=""/>
                                </div>
                                <Title level={3} size='big' weight='bold'>
                                    Capturing a Screenshot
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    <UList markType='decimal'>
                                        <li>Click the Screeny icon located in your browser's toolbar</li>
                                        <li>From the top menu, opt for the “Capture a screenshot” feature</li>
                                        <li>You'll have the choice to capture the entire page, the visible area, or a specific screen region</li>
                                        <li>After choosing your desired area, simply click on it</li>
                                        <li>You can then enhance the screenshot with the available editing tools</li>
                                        <li>When you're satisfied with your edits, click “Download”, and the screenshot will be stored locally on your device or click “Google Drive” to store it there</li>
                                    </UList>
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <div className={classes.image}>
                                    <img src={howToUseImage3Url} alt=""/>
                                </div>
                                <Title level={3} size='big' weight='bold'>
                                    Screen Recording
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    <UList markType='decimal'>
                                        <li>MacOS: Open System Settings and navigate to Privacy & Security. Locate “Screen Recording” and turn on the switch for your browser Windows: Allow extension to capture screen in a pop-up</li>
                                        <li>Click the Screeny icon located in your browser's toolbar</li>
                                        <li>Opt for the “Capture a screen record” option from the top menu</li>
                                        <li>If audio recording is desired, turn on the microphone switch</li>
                                        <li>Choose what you wish to record: desktop, webcam or a both. This will initiate the recording</li>
                                        <li>To conclude the recording, access the Screeny extension and press “Stop”</li>
                                        <li>Your screen recording will be ready to be saved locally on your device in WEBM format, or uploaded to Google Drive based on your preference</li>
                                    </UList>
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <div className={classes.image}>
                                    <img src={howToUseImage4Url} alt=""/>
                                </div>
                                <Title level={3} size='big' weight='bold'>
                                    Organizing Your Screenshots and Recordings
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    <UList markType='decimal'>
                                        <li>Click the Screeny icon within your toolbar</li>
                                        <li>Navigate to the History tab in the top right corner and select “Show History”</li>
                                        <li>Within History section, you can access, download, or remove your screenshots and screen recordings as needed</li>
                                    </UList>
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <div className={classes.image}>
                                    <img src={howToUseImage5Url} alt=""/>
                                </div>
                                <Title level={3} size='big' weight='bold'>
                                    Sharing Your Screenshots and Screen Recordings
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    <UList markType='decimal'>
                                        <li>Once you've captured a screenshot or completed a screen recording, save it to your computer and send the downloaded file to your recipient</li>
                                        <li>Another option is to upload to Google Drive, copy file link and share it</li>
                                    </UList>
                                </Span>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Section>
            </div>
        </>
    );
}