import { getBrowserType } from "./utils/getBrowserType";

export enum RouteParams {
    Page = 'p',
}

export enum RoutePages {
    PrivacyPolicy = 'privacy_policy',
    AboutUs = 'about_us',
    HowToUse = 'how_to_use',
}
export const RouteUrls = {
    Home: '/',
    HowToUse: `?${RouteParams.Page}=${RoutePages.HowToUse}`,
    PrivacyPolicy: `?${RouteParams.Page}=${RoutePages.PrivacyPolicy}`,
    AboutUs: `?${RouteParams.Page}=${RoutePages.AboutUs}`,
};

export const BROWSER_TYPE = getBrowserType();
export const EXTENSION_STORE_URL = BROWSER_TYPE === "Edge"
    ? "https://microsoftedge.microsoft.com/addons/detail/screenshot-and-screen-vid/lcphghadcjlhhbjnbgkpphieileglfnn"
    : "https://chrome.google.com/webstore/detail/screenshot-screen-video-r/djekgpcemgcnfkjldcclcpcjhemofcib?hl=uk";

export const SUPPORT_EMAIL = 'eugenijs98saulite@gmail.com';
export const SITE_NAME = 'Screeny';
export const SITE_URL = 'https://getscreeny.com/';