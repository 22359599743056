import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { SVG } from "../../SVG";

export const WebcamCat: FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <SVG {...props} height='78px' width='97px' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 97 78" xmlSpace="preserve">
            <g>
                <defs>
                    <rect id="SVGID_W1_" x="13.6" y="29.9" transform="matrix(0.866 -0.5 0.5 0.866 -14.0982 12.4858)"
                          width="5.4" height="5.4"/>
                </defs>
                <clipPath id="SVGID_W2_">
                    <use xlinkHref="#SVGID_W1_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st0}>
                    <path className={classes.st1} d="M18.5,32.6c-0.1,0.7-0.5,1.3-1.2,1.7c-0.6,0.4-1.4,0.4-2.1,0.2c-0.2,0.4-0.2,0.9,0,1.2
			c0.4,0.7,1.1-0.3,2.3-0.9c1.2-0.7,2.4-0.8,2-1.5C19.3,32.9,18.9,32.7,18.5,32.6z"/>
                    <path className={classes.st1} d="M17.1,34c1.1-0.6,1.4-2,0.8-3c-0.6-1.1-2-1.4-3-0.8c-1.1,0.6-1.4,2-0.8,3C14.7,34.3,16.1,34.6,17.1,34z
			 M15.5,31.2c0.5-0.3,1.2-0.1,1.5,0.4c0.3,0.5,0.1,1.2-0.4,1.5c-0.5,0.3-1.2,0.1-1.5-0.4C14.8,32.2,14.9,31.5,15.5,31.2z"/>
                    <path className={classes.st1} d="M16.4,32.8c0.4-0.2,0.5-0.7,0.3-1.1c-0.2-0.4-0.7-0.5-1.1-0.3c-0.4,0.2-0.5,0.7-0.3,1.1
			C15.5,32.9,16,33,16.4,32.8z M16,31.7c0.1-0.1,0.2,0,0.3,0.1c0.1,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0-0.3-0.1
			C15.8,31.9,15.9,31.7,16,31.7z"/>
                </g>
            </g>
            <g>
                <defs>

                    <rect id="SVGID_W3_" x="1.8" y="26.3"
                          transform="matrix(0.7278 -0.6858 0.6858 0.7278 -18.8919 11.5541)" width="6.6" height="6.6"/>
                </defs>
                <clipPath id="SVGID_W4_">
                    <use xlinkHref="#SVGID_W3_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st2}>
                    <path className={classes.st1} d="M7.8,29c0,0.9-0.3,1.7-1,2.3c-0.7,0.6-1.5,0.9-2.4,0.8c-0.1,0.6,0,1.1,0.3,1.4C5.4,34.4,6,33,7.2,31.8
			c1.2-1.2,2.7-1.6,1.9-2.4C8.8,29.1,8.3,28.9,7.8,29z"/>
                    <path className={classes.st1} d="M6.5,31.1c1.1-1,1.1-2.7,0.1-3.8c-1-1.1-2.7-1.1-3.8-0.1c-1.1,1-1.1,2.7-0.1,3.8C3.8,32.1,5.5,32.1,6.5,31.1z
			 M3.8,28.2c0.5-0.5,1.4-0.5,1.9,0.1c0.5,0.5,0.5,1.4-0.1,1.9c-0.5,0.5-1.4,0.5-1.9-0.1C3.2,29.5,3.2,28.7,3.8,28.2z"/>
                    <path className={classes.st1} d="M5.4,29.9c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4C4.4,30.2,5,30.2,5.4,29.9z
			 M4.5,28.6c0.1-0.1,0.3-0.1,0.3,0C5,28.7,5,28.9,4.9,29c-0.1,0.1-0.3,0.1-0.3,0C4.4,28.9,4.4,28.7,4.5,28.6z"/>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_W5_" x="6.8" y="13.1" transform="matrix(0.866 -0.5 0.5 0.866 -7.8466 8.9782)"
                          width="12.1" height="12.1"/>
                </defs>
                <clipPath id="SVGID_W6_">
                    <use xlinkHref="#SVGID_W5_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st3}>
                    <path className={classes.st1} d="M17.8,19.3c-0.3,1.5-1.2,2.9-2.7,3.8s-3.1,1-4.6,0.4c-0.4,1-0.5,1.9,0,2.7c1,1.7,2.6-0.6,5.3-2.1
			c2.7-1.6,5.4-1.8,4.5-3.5C19.7,19.8,18.8,19.4,17.8,19.3z"/>
                    <path className={classes.st1} d="M14.8,22.5c2.4-1.4,3.2-4.4,1.8-6.8c-1.4-2.4-4.4-3.2-6.8-1.8c-2.4,1.4-3.2,4.4-1.8,6.8
			C9.4,23,12.4,23.8,14.8,22.5z M11,16c1.2-0.7,2.7-0.3,3.4,0.9c0.7,1.2,0.3,2.7-0.9,3.4c-1.2,0.7-2.7,0.3-3.4-0.9
			C9.5,18.2,9.9,16.7,11,16z"/>
                    <path className={classes.st1} d="M13.2,19.7c0.9-0.5,1.2-1.6,0.7-2.5c-0.5-0.9-1.6-1.2-2.5-0.7c-0.9,0.5-1.2,1.6-0.7,2.5
			C11.2,19.9,12.3,20.2,13.2,19.7z M12.2,17.2c0.2-0.1,0.5-0.1,0.6,0.2c0.1,0.2,0.1,0.5-0.2,0.6c-0.2,0.1-0.5,0.1-0.6-0.2
			C11.9,17.6,12,17.3,12.2,17.2z"/>
                </g>
            </g>
            <ellipse className={classes.st4} cx="51.6" cy="73.4" rx="26" ry="3.5"/>
            <path className={classes.st5} d="M63,37.5c-6.3,0.8-10.9-0.3-16.2,0c-3.1-0.1-0.5-0.3-5.3,2.7c-1.2,0.8-2.6,1.4-3.8,2.2
	c-4.3,2.9-4.2,4.5-5.3,5.7c-0.6,0.7,0.2,0.3-0.9,0.6c-0.6-1.1,1.3-3.4,1.9-4.2c3.8-4.3,13.6-7.1,11.1-11c-0.4-0.6-1.3-0.8-2.2-1.1
	c-4.1-1-11.8,1.1-14.5,4.3c-7.9,9.1-3,14.5-2.3,19.5c1.2,8.5,3.1,13.4,7.9,16.3c0.2,0.2,0.4,0.3,0.7,0.5c-0.2,0.5-0.4,0.7-0.5,0.9
	c-0.6,0.7,0.6,1.5,1.1,0.7c0.2-0.2,0.3-0.6,0.7-0.9c0.1,0,0.3,0.1,0.5,0.2c-0.1,0.4-0.3,0.7-0.4,1.1c-0.2,0.5,0.7,1.2,1.2,0.4
	c0.2-0.3,0.3-0.5,0.5-1c0.3,0.1,0.5,0.2,0.8,0.2c0,0.3-0.1,0.4-0.2,1.1c-0.1,1,1.1,1.1,1.4,0.1c0.1-0.3,0.2-0.6,0.2-0.8
	c0.3,0,0.5,0.1,0.8,0.1c0,0.3-0.2,0.6-0.2,0.9c0,1,1.2,1.3,1.4-0.1l0.2-0.6c3.9,0.4,8.5,0.1,12.5-0.6c0.3,0.6,0.4,1,0.4,1.1
	c0.1,0.3,1.3,0.8,1.3-0.3c0-0.5-0.5-0.8-0.3-1.1c0.5-0.1,1-0.2,1.4-0.3c0.2,0.2,0.1,0.6,0.3,1.1c0.3,0.7,1.5,0.5,1-0.6l-0.2-0.6
	c0.3-0.1,0.6-0.2,0.9-0.2c0.2,0.2,0.3,0.4,0.3,0.7c0.4,1.2,1.4,0.6,1.1-0.3l-0.1-0.7c0.2-0.1,0.4-0.1,0.6-0.2
	c0.2,0.3,0.3,0.6,0.4,0.8c0.3,1.4,1.6,0.8,1.1-0.2c-0.1-0.2-0.3-0.4-0.3-1c0.5-0.2,0.9-0.4,1.3-0.6c2.5-0.6,0.9,0.3,5.1,0.2
	c14.1-0.7,18.4-22,18-33.1C86,25.7,75.8,26.4,74.9,39.9c-0.5,6.7-2.1,19.8-5.2,21.7c2.8-8.1,1.9-18.4,0.4-21
	c0.2-1.3,2.1-4.2,2.8-7.8c3.2-16.3-15.2-16.8-12.2-9.9c1.2,2.7,3.7,1.7,4.4,6.4C65.4,31.1,65.2,36.5,63,37.5z"/>
            <path className={classes.st5} d="M44.3,30.7c0-1.6-0.4-3.1-0.2-5c0.4-5,7.7-2.5,13.4-3.1l0.1-1c-0.2-1.8,1-1.9,2.1-2.1c0-0.4-0.5-0.7-0.6-0.8
	c-1.2-0.9,0-1.2,0.7-1c0.5,0.2,0.8,0.6,1,0.8c0.1-0.2,0.3-0.2,0.3-0.3c-0.2-0.5-0.4-0.6-0.6-0.8c-0.9-1.2,0.4-1.5,0.9-0.7
	c0.2,0.3,0.3,0.8,0.6,1.2l0.6-0.3l-0.2-1.1c0.2-1.1,1.1-0.4,1.2,0.6c0,0.3,0.2,0.5,1.3,0.6c-0.1-2.1-1.5-4-2.4-4.8
	c-1.4-0.1-3,1.1-3.9,1.7c-2.2,1.4-1.2,0.8-3.5,0c-5.4-2-13.3-2.2-18.7,0.2c-2.6,1.1-0.9,0.6-3.6-0.2c-1.1-0.3-3.6-0.9-4.6-0.9
	c-0.4,1.4-0.8,4.9-0.5,6.5c0.7,3.6,0.4,2.1-0.6,5.4c-0.5,1.8-2,11.2-1.5,12c2.1-2.1,6-7,18.2-6.1L44.3,30.7z"/>
            <path className={classes.st6} d="M57.6,21.6l-0.1,1c-0.5,0-0.9,0.1-1.4,0.1c-0.4,0-0.8-2.7-1.2-2.7c-1.3,0-2.5-0.1-3.7-0.2c-0.5,0-1,2.7-1.4,2.7
	c-3.1,0-5.5,0.4-5.7,3.2c-0.2,1.8,0.3,3.4,0.2,5c0.7-0.5,1.4,0.2,1,0.6c-0.2,0.3-0.5,0.6-0.7,1l0.4,0.3l1-0.5
	c0.9-0.4,1.2,0.5,0.7,0.9c-0.3,0.3-0.6,0.4-0.9,0.6c0.1,0.2,0.2,0.5,0.2,0.7c0.3,0,0.7,0,0.9,0c1,0.1,1,1.3,0.2,1.3
	c-0.3,0-0.6,0-0.9,0c0.1,0.3-0.2,0.6-0.2,0.7c0.7,0.2,1.4,0.2,1.4,0.8c0,0.1-0.2,0.3-0.4,0.4c1.3,0.4,5.3,0.5,7.9,0.5
	c2.9,0,5.4-0.2,8.2-0.5c0.5-0.8,1.1-1.4,1.1-4.4c0-1.7,0.1-3.5-0.5-5.1c-1-2.8-4.5-2.4-4.3-6.9h-0.8C58.5,22,57.9,22.1,57.6,21.6z"
            />
            <path className={classes.st5}
                  d="M48.4,33c1.9,5.2,15.3,5.1,11.8-4.3c-1-2.9-4.3-4.2-8.1-3.5C49.3,25.8,47,29.5,48.4,33z"/>
            <path className={classes.st7} d="M51.5,30.6c-0.7-1.6,0.7-2.9,1.9-2.2C54.5,29,53.5,31,51.5,30.6z M51.6,26.9c-1.9,0.9-3,4.3-1.5,6.2
	c1.2,1.6,4.1,2.6,7,1.7C62.2,33,58.1,23.9,51.6,26.9z"/>
            <path className={classes.st6} d="M35.1,26.9c-0.8-0.6-1.8-0.4-0.7,0.8c1.3,1.6,5.1,1.6,6.6,0.7c1.7-1,0.6-1.6-0.5-1.1
	C38.8,28.1,37.2,28.2,35.1,26.9z"/>
            <g>
                <defs>

                    <rect id="SVGID_W7_" x="78.1" y="18.5"
                          transform="matrix(0.2588 -0.9659 0.9659 0.2588 39.3843 93.6965)" width="5.4" height="5.4"/>
                </defs>
                <clipPath id="SVGID_W8_">
                    <use xlinkHref="#SVGID_W7_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st8}>
                    <path className={classes.st1} d="M82.3,22.8c-0.6,0.4-1.3,0.5-2,0.3c-0.7-0.2-1.3-0.7-1.6-1.3c-0.4,0.2-0.8,0.5-0.9,0.8c-0.2,0.8,1,0.6,2.3,1
			c1.3,0.4,2.3,1.1,2.5,0.3C82.7,23.6,82.6,23.2,82.3,22.8z"/>
                    <path className={classes.st1} d="M80.3,22.8c1.2,0.3,2.4-0.4,2.7-1.6c0.3-1.2-0.4-2.4-1.6-2.7c-1.2-0.3-2.4,0.4-2.7,1.6
			C78.4,21.3,79.1,22.5,80.3,22.8z M81.2,19.7c0.6,0.2,0.9,0.8,0.8,1.3c-0.2,0.6-0.8,0.9-1.3,0.8c-0.6-0.2-0.9-0.8-0.8-1.3
			C80,19.8,80.6,19.5,81.2,19.7z"/>
                    <path className={classes.st1} d="M80.7,21.5c0.4,0.1,0.9-0.1,1-0.6c0.1-0.4-0.1-0.9-0.6-1c-0.4-0.1-0.9,0.1-1,0.6C80,20.9,80.2,21.4,80.7,21.5
			z M81.2,20.4c0.1,0,0.2,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.1c-0.1,0-0.2-0.1-0.1-0.2C81,20.4,81.1,20.4,81.2,20.4z"/>
                </g>
            </g>
            <g>
                <defs>

                    <rect id="SVGID_W9_" x="71.7" y="7.9"
                          transform="matrix(2.972277e-02 -0.9996 0.9996 2.972277e-02 61.544 85.81)" width="6.6"
                          height="6.6"/>
                </defs>
                <clipPath id="SVGID_W10_">
                    <use xlinkHref="#SVGID_W9_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st9}>
                    <path className={classes.st1} d="M77.2,12.7c-0.6,0.6-1.4,1-2.3,1c-0.9,0-1.7-0.5-2.3-1.1c-0.5,0.3-0.8,0.8-0.8,1.2c0,1.1,1.4,0.5,3,0.5
			c1.7,0.1,3,0.7,3.1-0.3C78,13.5,77.7,13,77.2,12.7z"/>
                    <path className={classes.st1} d="M74.9,13.3c1.5,0,2.7-1.1,2.8-2.6c0-1.5-1.1-2.7-2.6-2.8c-1.5,0-2.7,1.1-2.8,2.6C72.3,12,73.4,13.3,74.9,13.3
			z M75,9.3c0.7,0,1.3,0.6,1.3,1.4c0,0.7-0.6,1.3-1.4,1.3c-0.7,0-1.3-0.6-1.3-1.4C73.7,9.8,74.3,9.2,75,9.3z"/>
                    <path className={classes.st1} d="M75,11.6c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C74,11.1,74.4,11.6,75,11.6z M75.2,10.1
			c0.1,0,0.2,0.1,0.2,0.3c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.3C75,10.2,75.1,10.1,75.2,10.1z"/>
                </g>
            </g>
            <g>
                <defs>

                    <rect id="SVGID_W11_" x="81.8" y="3.2"
                          transform="matrix(0.2588 -0.9659 0.9659 0.2588 56.1263 91.7015)" width="12.1" height="12.1"/>
                </defs>
                <clipPath id="SVGID_W12_">
                    <use xlinkHref="#SVGID_W11_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st10}>
                    <path className={classes.st1} d="M91.2,12.9c-1.3,0.9-2.9,1.2-4.6,0.8c-1.6-0.4-2.9-1.5-3.6-3c-1,0.4-1.7,1-1.9,1.9c-0.5,1.9,2.2,1.4,5.2,2.2
			c3,0.8,5.1,2.6,5.6,0.7C92.2,14.6,91.9,13.7,91.2,12.9z"/>
                    <path className={classes.st1} d="M86.8,13c2.6,0.7,5.3-0.9,6.1-3.5c0.7-2.6-0.9-5.3-3.5-6.1c-2.6-0.7-5.3,0.9-6.1,3.5
			C82.6,9.6,84.2,12.3,86.8,13z M88.7,5.8c1.3,0.4,2.1,1.7,1.7,3c-0.4,1.3-1.7,2.1-3,1.7c-1.3-0.4-2.1-1.7-1.7-3
			C86.1,6.3,87.4,5.5,88.7,5.8z"/>
                    <path className={classes.st1} d="M87.6,10c1,0.3,1.9-0.3,2.2-1.3c0.3-1-0.3-1.9-1.3-2.2c-1-0.3-1.9,0.3-2.2,1.3C86.1,8.7,86.7,9.7,87.6,10z
			 M88.8,7.5C89,7.5,89.2,7.8,89.1,8c-0.1,0.2-0.3,0.4-0.6,0.3c-0.2-0.1-0.4-0.3-0.3-0.6C88.3,7.5,88.5,7.4,88.8,7.5z"/>
                </g>
            </g>
            <ellipse className={classes.st11} cx="49.6" cy="57.9" rx="8" ry="10"/>
        </SVG>
    );
}