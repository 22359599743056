import React, { FC, useEffect } from 'react';
import { FallbackProps } from "react-error-boundary";
import useNotification from "../../hooks/useNotification";

export const ErrorFallback: FC<FallbackProps> = (
    { error }
) => {
    const { openNotification, contextHolder } = useNotification('error', 'bottomLeft');
    
    useEffect(() => {
        openNotification({
            message: error.name,
            description: error.message,
        })
    }, []);

    return (
        <>
            {contextHolder}
        </>
    );
}