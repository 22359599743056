import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { FlexBox } from "../../../../components/FlexBox";
import { PaddingBox } from "../../../../components/PaddingBox";
import { Span } from "../Topography/Span";
import { ArrowDown } from "../../icons/ArrowDown";
import classes from './styles/index.module.scss';
import classNames from "classnames";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";

type CollapseType = {
    title: string;
    children: ReactNode;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Collapse: FC<CollapseType> = (
    { title, children, className, ...props }
) => {
    const { innerWidth } = useResizeContext();
    const [isOpen, setIsOpen] = useState(false);
    const [buttonHeight, setButtonHeight] = useState(0);
    const [collapseHeight, setCollapseHeight] = useState(0);
    const buttonRef = useRef<HTMLDivElement>(null);
    const collapseRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(buttonRef.current) {
            setButtonHeight(buttonRef.current.getBoundingClientRect().height)
        }
    }, [buttonRef.current, innerWidth]);

    useEffect(() => {
        if(collapseRef.current) {
            setCollapseHeight(collapseRef.current.getBoundingClientRect().height)
        }
        else {
            setCollapseHeight(0);
        }
    }, [collapseRef.current, isOpen, innerWidth]);

    const arrowClasses = classNames(classes.arrow, {
        [classes.arrow_up]: isOpen,
    });
    const containerClasses = classNames(classes.collapseContainer, className);

    const handleCollapseSwitch = () => {
        setIsOpen(prevState => !prevState);
    };

    return (
        <FlexBox column className={containerClasses} style={{ height: `${buttonHeight + collapseHeight}px` }} {...props}>
            <div ref={buttonRef} className={classes.collapseButton} onClick={handleCollapseSwitch}>
                <PaddingBox large>
                    <FlexBox justify='space-between' align='middle'>
                        <Span size='small' weight='extraBold'>
                            {title}
                        </Span>
                        <ArrowDown className={arrowClasses}/>
                    </FlexBox>
                </PaddingBox>
            </div>
            {isOpen && (
                <div ref={collapseRef}>
                    <PaddingBox largeHR largeBottom>
                        <Span size='small' weight='regular' secondary>
                            {children}
                        </Span>
                    </PaddingBox>
                </div>
            )}
        </FlexBox>
    );
}