import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { Segmented, Typography } from "antd";
import { PopupSegmentedOptionValue } from "../../../../../Popup/const";
import { FlexBox } from "../../../../../../components/FlexBox";
import { Camera, Record, Story } from "../../../../../../icons";
import { PaddingBox } from "../../../../../../components/PaddingBox";
import { BigButton } from "../../../../../../components/BigButton";
import { WholePageCat } from "../../../../../../icons/cats/WholePageCat";
import { Tag } from "../../../../../../components/Tag";
import { VisibleAreaCat } from "../../../../../../icons/cats/VisibleAreaCat";
import { CustomAreaCat } from "../../../../../../icons/cats/CustomAreaCat";
import { isMac, isWindows } from "../../../../../../utils";
import classNames from "classnames";

type ScreenshotPopupPropsType = {} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const { Title, Text } = Typography;

export const ScreenshotPopup: FC<ScreenshotPopupPropsType> = ({ className, ...props }) => {
    const ctrl = isMac() ? "Command" : isWindows() ? "Ctrl" : "";
    const containerClasses = classNames(className, classes.container);

    const segmentedOptions = [
        {
            label: (
                <FlexBox justify='center' align='middle'>
                    <Camera />
                </FlexBox>
            ),
            value: PopupSegmentedOptionValue.Camera,
        },
        {
            label: (
                <FlexBox justify='center' align='middle'>
                    <Record />
                </FlexBox>
            ),
            value: PopupSegmentedOptionValue.Record,
        },
        {
            label: (
                <FlexBox justify='center' align='middle'>
                    <Story />
                </FlexBox>
            ),
            value: PopupSegmentedOptionValue.Story,
        },
    ];
    return (
        <div className={containerClasses} {...props}>
            <Segmented block options={segmentedOptions} onChange={() => {}} value={PopupSegmentedOptionValue.Camera} />

            <PaddingBox mediumHR mediumBottom>
                <FlexBox column gap='medium'>
                    <div>
                        <Title level={4}>Capture a screenshot</Title>
                        <Text type="secondary">Choose the type of screen capture</Text>
                    </div>
                    <FlexBox gap='small'>
                        <BigButton
                            className={classes.wholePageButton}
                            onClick={() => {}}
                        >
                            <FlexBox align='middle' justify='center' column gap='small'>
                                <WholePageCat/>
                                <Text>Whole page</Text>
                                <FlexBox gap='tiny'>
                                    <Tag>{ctrl}</Tag>
                                    <Tag>Shift</Tag>
                                    <Tag>Y</Tag>
                                </FlexBox>
                            </FlexBox>
                        </BigButton>
                        <FlexBox gap='tiny' column>
                            <BigButton className={classes.visibleAreaButton} onClick={() => {}}>
                                <FlexBox align='middle' justify='center' column gap='small'>
                                    <VisibleAreaCat/>
                                    <Text>Visible area</Text>
                                    <FlexBox gap='tiny'>
                                        <Tag>{ctrl}</Tag>
                                        <Tag>Shift</Tag>
                                        <Tag>S</Tag>
                                    </FlexBox>
                                </FlexBox>
                            </BigButton>
                            <BigButton
                                className={classes.customAreaButton}
                                onClick={() => {}}
                            >
                                <FlexBox align='middle' justify='center' column gap='small'>
                                    <CustomAreaCat/>
                                    <Text>Custom area</Text>
                                </FlexBox>
                            </BigButton>
                        </FlexBox>
                    </FlexBox>
                    <Text type="secondary">
                        Screenshot Master v.1.0.1
                    </Text>
                </FlexBox>
            </PaddingBox>
        </div>
    );
}