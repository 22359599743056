import React, { FC } from 'react';
import { WelcomeComponent } from "../../components/WelcomeComponent";
import { FlexBox } from "../../../../components/FlexBox";
import { Title } from "../../components/Topography/Title";
import { Button } from "../../components/Button";
import { ArrowRight } from "../../icons/ArrowRight";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import { EXTENSION_STORE_URL } from "../../const";
import { Link } from "react-router-dom";
import { CaptureAScreenshotSection } from "./sections/CaptureAScreenshotSection";
import { CaptureAScreenRecordSection } from "./sections/CaptureAScreenRecordSection";
import { GoogleDriveIntegrationSection } from "./sections/GoogleDriveIntegrationSection";
import { FAQSection } from "./sections/FAQSection";
import { TryExtensionSection } from "./sections/TryExtensionSection";
import { getPageTitle } from "../../utils/getPageTitle";
import { Head } from "../../components/Head";

const description = 'Visualize What You Are Talking About';
export const Main: FC = () => {
    const { isMobile } = useResizeContext();

    return (
        <>
            <Head title={getPageTitle()} description={description}/>
            <WelcomeComponent
                content={(
                    <FlexBox column align='middle' justify={isMobile ? 'end' : 'space-evenly'} gap='large' style={{ height: '100%', maxWidth: '1000px' }}>
                        <FlexBox column align='middle' gap='large'>
                            <Title level={2} size={isMobile ? "big" : 'extraLarge'} weight="extraBold" style={{ textAlign: 'center' }}>
                                {description}
                            </Title>
                            <Title level={3} size="normal" weight="medium" style={{ textAlign: 'center' }}>
                                Capture Screenshots & Screen Recordings Instantly
                            </Title>
                        </FlexBox>
                        <Link to={EXTENSION_STORE_URL} target="_blank">
                            <Button>
                                <span>Continue</span>
                                <ArrowRight/>
                            </Button>
                        </Link>
                    </FlexBox>
                )}
            />
            <main>
                <CaptureAScreenshotSection/>
                <CaptureAScreenRecordSection/>
                <GoogleDriveIntegrationSection/>
                <FAQSection/>
                <TryExtensionSection/>
            </main>
        </>
    );
}