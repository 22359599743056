import React, { FC } from 'react';
import { SVG } from "../../SVG";
import classes from "./styles/index.module.scss";

export const VisibleAreaCat: FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <SVG {...props} height='68px' width='101px'>
            <path className={classes.st0} d="M99.5,63.3H0.7v4.2h98.7V63.3z"/>
            <path className={classes.st1} d="M79.8,6.3H18.2c-0.8,0-1.5,0.7-1.5,1.5v42.3c0,0.8,0.7,1.5,1.5,1.5h61.6c0.8,0,1.5-0.7,1.5-1.5V7.8
	C81.3,7,80.7,6.3,79.8,6.3z"/>
            <path className={classes.st1} d="M54.4,50.3H42.8v11.6h11.6V50.3z"/>
            <path className={classes.st1} d="M58.5,60.8H38.7v2.5h19.8V60.8z"/>
            <path className={classes.st1}
                  d="M48,6c1.4-0.2,2.4-1.5,2.1-2.9c-0.2-1.4-1.5-2.3-2.9-2.1c-1.4,0.2-2.4,1.5-2.1,2.9C45.3,5.3,46.6,6.2,48,6z"/>
            <path className={classes.st1} d="M48.3,4.6h-1.5v1.8h1.5V4.6z"/>
            <path className={classes.st1}
                  d="M48.9,3.5c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6C48.1,5.1,48.9,4.3,48.9,3.5z"/>
            <path className={classes.st2}
                  d="M48.3,2.9c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5C48,3.4,48.3,3.2,48.3,2.9z"/>
            <path className={classes.st3} d="M94.2,50.2H69v0.7h12.9c1.3,0,2.4,1.1,2.4,2.4s-1.1,2.4-2.4,2.4H69v0.7h25.2c1.7,0,3.1-1.4,3.1-3.1
	C97.2,51.6,95.9,50.2,94.2,50.2z"/>
            <path className={classes.st4} d="M85.4,53.3c0-1.7-1.4-3.1-3.1-3.1H69v0.7h12.9c1.3,0,2.4,1.1,2.4,2.4s-1.1,2.4-2.4,2.4H69v0.7h13.3
	C84.1,56.3,85.4,54.9,85.4,53.3z"/>
            <path className={classes.st5} d="M81.9,50.9H69v0c1.2,0.1,2.1,1.1,2.1,2.3s-0.9,2.2-2.1,2.3v0h12.9c1.3,0,2.4-1.1,2.4-2.4
	C84.3,52,83.2,50.9,81.9,50.9z"/>
            <path className={classes.st6} d="M84.3,53.3c0-1.3-1.1-2.4-2.4-2.4H69v0c0.6,0.1,1.1,0.4,1.5,0.8c2.6-0.2,10.3-0.4,11.1,3.9h0.3
	C83.2,55.6,84.3,54.6,84.3,53.3z"/>
            <path className={classes.st7} d="M73.1,56.3h26.4v0.8H85.9c-1.4,0-2.5,1.2-2.5,2.7c0,1.5,1.1,2.7,2.5,2.7h13.5v0.8H73.1c-1.8,0-3.2-1.6-3.2-3.5
	C69.9,57.9,71.3,56.3,73.1,56.3z"/>
            <path className={classes.st8} d="M82.3,59.8c0-1.9,1.4-3.5,3.2-3.5h14v0.8H85.9c-1.4,0-2.5,1.2-2.5,2.7c0,1.5,1.1,2.7,2.5,2.7h13.5v0.8h-14
	C83.7,63.3,82.3,61.7,82.3,59.8z"/>
            <path className={classes.st5} d="M85.9,57.1h13.5v0c-1.2,0.2-2.2,1.3-2.2,2.7c0,1.4,1,2.5,2.2,2.7v0H85.9c-1.4,0-2.5-1.2-2.5-2.7
	C83.5,58.3,84.6,57.1,85.9,57.1z"/>
            <path className={classes.st6} d="M83.5,59.8c0-1.5,1.1-2.7,2.5-2.7h13.5v0c-0.6,0.1-1.2,0.4-1.6,0.9c-2.7-0.2-10.8-0.5-11.6,4.5h-0.3
	C84.6,62.5,83.5,61.3,83.5,59.8z"/>
            <path className={classes.st9} d="M78.4,9.3H19.2v38.4h59.2V9.3z"/>
            <path className={classes.st5} d="M31.5,48.4c-0.1-2.5-2.7-2.9-5.8-2.9c-3.1,0-5.6,1.2-5.6,3.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
	c0,1.4,1.2,2.6,2.6,2.6c0.3,0,0.6-0.1,0.9-0.2c0.4,0.9,1.3,1.5,2.4,1.5c1.1,0,2.1-0.7,2.5-1.7c0.1,0,0.3,0,0.4,0
	c1.4,0,2.6-1.2,2.6-2.6C31.5,48.5,31.5,48.5,31.5,48.4z"/>
            <path className={classes.st5} d="M65.4,48.5c0.1-2.5,3-3.2,6-3.2c3.1,0,5.3,1.4,5.3,3.4c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
	c0,1.4-1.2,2.6-2.6,2.6c-0.3,0-0.6-0.1-0.9-0.2c-0.4,0.9-1.3,1.5-2.4,1.5c-1.1,0-2.1-0.7-2.5-1.7c-0.1,0-0.3,0-0.4,0
	c-1.4,0-2.6-1.2-2.6-2.6C65.4,48.6,65.4,48.5,65.4,48.5z"/>
            <path className={classes.st5} d="M63.3,23.3c1-0.5,1.6,0,1.3,1.1l-2.7,9.4c-0.3,1.1-1.3,1.4-2.2,0.7l-5.7-4.7c-0.9-0.7-0.8-1.7,0.3-2.2
	L63.3,23.3z"/>
            <path className={classes.st9} d="M62,25.5c0.5-0.2,0.8,0,0.6,0.5l-1.3,4.6c-0.2,0.5-0.6,0.7-1.1,0.3l-2.8-2.3c-0.4-0.4-0.4-0.8,0.1-1.1L62,25.5z
	"/>
            <path className={classes.st5}
                  d="M34.1,22.7c-1-0.5-1.6,0-1.3,1.1l2.7,9.4c0.3,1.1,1.3,1.4,2.2,0.7l5.7-4.7c0.9-0.7,0.8-1.7-0.3-2.2L34.1,22.7z"
            />
            <path className={classes.st9} d="M35.5,24.9c-0.5-0.2-0.8,0-0.6,0.5l1.3,4.6c0.2,0.5,0.6,0.7,1.1,0.3l2.8-2.3c0.4-0.4,0.4-0.8-0.1-1.1L35.5,24.9
	z"/>
            <path className={classes.st5}
                  d="M48.2,24.6c-13.5,0.3-16,11.5-16.9,22.8c0,0.1,0,0.3,0,0.4h34.5C64,37,64.3,24.3,48.2,24.6z"/>
            <path className={classes.st10}
                  d="M42.6,38.5c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3C40.3,37.5,41.3,38.5,42.6,38.5z"/>
            <path className={classes.st5} d="M43.3,36.1c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C42.7,35.8,42.9,36.1,43.3,36.1
	z"/>
            <path className={classes.st10}
                  d="M55.2,38.5c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3C52.9,37.5,54,38.5,55.2,38.5z"/>
            <path className={classes.st5} d="M55.9,36.1c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C55.3,35.8,55.6,36.1,55.9,36.1
	z"/>
            <path className={classes.st10} d="M48,39.8C48,39.8,48,39.8,48,39.8c0,0,0.4,0,0.7-0.4c0.3-0.3,0.6-1,0.7-2.1c0-0.1-0.1-0.2-0.1-0.2
	c-0.1,0-0.2,0.1-0.2,0.1c0,1.1-0.3,1.6-0.6,1.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.2
	C47.8,39.8,47.9,39.8,48,39.8z"/>
            <path className={classes.st10} d="M50.3,39.8C50.3,39.8,50.3,39.8,50.3,39.8c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0-0.1,0
	c-0.3-0.1-0.9-0.5-0.9-2.1c0-0.1-0.1-0.2-0.2-0.1c-0.1,0-0.2,0.1-0.1,0.2c0,1.2,0.3,1.8,0.7,2.1C50,39.8,50.3,39.8,50.3,39.8z"/>
            <path className={classes.st10} d="M49.1,37.6c0.6,0,1.7-0.9,1.7-1.3c0-0.4-0.7-0.7-1.7-0.7c-0.9,0-1.7,0.3-1.7,0.7
	C47.5,36.7,48.6,37.7,49.1,37.6z"/>
            <path className={classes.st11} d="M34.8,36.8c0,0,0-0.1,0-0.1l-6.3-2.8c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1l6.3,2.8C34.7,36.8,34.7,36.8,34.8,36.8
	C34.8,36.8,34.8,36.8,34.8,36.8z"/>
            <path className={classes.st11} d="M34.5,37.6c0,0,0-0.1-0.1-0.1l-8.6-1.8c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0.1,0.1l8.6,1.8
	C34.4,37.7,34.4,37.7,34.5,37.6C34.4,37.7,34.5,37.7,34.5,37.6z"/>
            <path className={classes.st11} d="M34.3,38.7C34.3,38.6,34.3,38.6,34.3,38.7l-6.4-0.5c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0.1,0.1L34.3,38.7
	C34.3,38.7,34.3,38.7,34.3,38.7z"/>
            <path className={classes.st11} d="M62.8,38l6.5-2.2c0,0,0.1-0.1,0.1-0.1c0,0-0.1-0.1-0.1-0.1l-6.5,2.2c0,0-0.1,0.1-0.1,0.1
	C62.7,38,62.7,38,62.8,38C62.7,38,62.7,38,62.8,38z"/>
            <path className={classes.st11} d="M63,38.9l8.8-1c0,0,0.1-0.1,0.1-0.1c0,0-0.1-0.1-0.1-0.1l-8.8,1c0,0-0.1,0-0.1,0.1C62.9,38.9,63,38.9,63,38.9
	C63,38.9,63,38.9,63,38.9z"/>
            <path className={classes.st11}
                  d="M63,40l6.3,0.1c0,0,0.1,0,0.1-0.1c0,0,0-0.1-0.1-0.1L63,39.8C63,39.8,63,39.9,63,40C63,40,63,40,63,40z"/>
        </SVG>
    );
};