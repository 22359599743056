import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { SVG } from "../../SVG";

export const CustomAreaCat: FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <SVG {...props} height='68px' width='101px' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 93 65" xmlSpace="preserve">
            <path className={classes.st0} d="M22.8,16.8h50c3,0,5.5,2.5,5.5,5.5v36c0,3-2.5,5.5-5.5,5.5h-50c-3,0-5.5-2.5-5.5-5.5v-36
	C17.3,19.2,19.8,16.8,22.8,16.8z"/>
            <g>
                <defs>
                    <rect id="SVGID_5_" x="0.8" y="1.3" width="88" height="49"/>
                </defs>
                <clipPath id="SVGID_6_">
                    <use xlinkHref="#SVGID_5_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st1}>
                    <path className={classes.st2} d="M10.6,43.7c0.2-2.6,0.8-5.3,1.9-7.8c0.2-0.5,0.6-0.9,1.1-1.2c1-0.7,2-1.1,3.1-1.6c1.4-0.5,2.8-0.5,4.2,0.1
			c1.6,0.7,3,1.7,4.1,3.1c1.4,1.7,1.8,3.7,1.3,5.8c-0.6,2.5-1.6,4.7-2.9,6.9c-0.2,0.3-0.3,0.5-0.5,0.7c-0.7,0.7-1.3,0.7-1.7-0.2
			c-0.4-0.7-0.7-1.4-0.9-2.1c-0.5-1.5-0.8-3.1-1.1-4.7c0-0.1,0-0.1,0-0.2c-0.4-1.9-0.8-2-2.2-0.8c-1.2,1-2.4,2-3.6,3
			c-0.3,0.3-0.6,0.5-0.9,0.7c-0.8,0.5-1.4,0.2-1.6-0.7C10.6,44.5,10.6,44.1,10.6,43.7z"/>
                    <path className={classes.st3} d="M49,1.3c2.1-0.1,4.6,0.1,7.2,0.2c2.6,0.1,5,1,7.3,1.9c6.7,2.5,12.1,6.8,16.5,12.4c2.9,3.8,5.6,7.6,7.5,12.1
			c0.5,1.1,0.9,2.2,1.2,3.3c0.2,1,0.3,2-0.1,3c-0.4,1-1,1.7-2.1,1.9c-1.1,0.1-2-0.3-2.5-1.3c-0.6-1.1-1.4-1.9-2.2-2.9
			c-2.3-3.1-5.5-5-9.2-6c-3.6-1-7.4-1.6-11.1-1.8c-1.7-0.1-3.5,0.1-5.2,0.4c-1.2,0.2-2.3,0.6-3.3,1.1c-3,1.7-5.4,4-6.9,7.2
			c-1.1,2.3-2,4.8-3.1,7.2c-0.4,0.8-0.7,1.6-1.2,2.3c-0.4,0.6-0.8,1.1-1.4,1.4c-1.7,1-3.7,0.2-4.1-1.8c-0.3-1.1,0-2.3,0.2-3.4
			c0.5-2.3,0.8-4.7,0.8-7.1c0-1.1-0.2-2.1-0.9-3c-1-1.1-1.7-1.1-2.6,0.1c-0.8,1.1-1.3,2.2-1.8,3.5c-0.4,1.1-0.6,2.2-0.7,3.3
			c-0.4,4.4-3.8,7-7.2,7.7c-2.4,0.5-4.7,0.1-7-0.8C15.8,41.9,15,41,14.2,40c-0.8-1.1-1.4-2.3-1.8-3.6c-0.6-1.7-0.2-3.4,0.3-5.1
			c0.6-2,1.7-3.7,3.2-5.1c0.3-0.3,0.7-0.7,0.9-1.1c0.4-0.7,0.7-1.5,0.1-2.3c-0.5-0.7-1.4-0.8-2.2-0.7c-1.1,0.2-2,0.8-2.9,1.6
			c-1.1,1-2.1,2-3.1,3.2c-1.4,1.7-2.8,3.4-4.6,4.7c-0.5,0.4-1,0.7-1.5,0.9c-0.9,0.3-1.4,0-1.7-0.8c-0.3-1-0.2-1.9-0.1-2.9
			c0.2-1.1,0.6-2,1-3c2.3-5.3,5.9-9.5,10.4-13.1C19.6,6.9,27.7,3.5,36.8,2C40.6,1.4,44.6,1.3,49,1.3z"/>
                    <path className={classes.st4} d="M48.9,1.3c3.1,0.1,5.6,0.1,8.1,0.4c1.6,0.1,3.2,0.7,4.7,1.2c3.5,1.2,6.8,2.8,9.8,4.9c0.2,0.2,0.4,0.3,0.6,0.5
			c0.3,0.2,0.3,0.4,0.1,0.7c-0.1,0.2-0.2,0.3-0.3,0.5c-2.7,4.2-6.4,7.1-11,8.7c-3.1,1.1-6.4,1.7-9.7,2c-6.3,0.4-12.5-0.4-18.5-2.6
			c-1.8-0.7-3.6-1.5-5.2-2.6c-1.3-0.9-2.5-2-3.4-3.3c-0.9-1.3-1.8-2.5-2.6-3.8C21.1,7,21.1,7,21.9,6.7c3.7-1.8,7.6-3.1,11.6-4
			C38.7,1.4,44.1,1.3,48.9,1.3z"/>
                    <path className={classes.st5} d="M84,31.8c0-0.8,0.5-1.2,1.1-1.5c0.6-0.3,1.1-0.2,1.6,0.2c0.4,0.4,0.6,0.8,0.4,1.3c-0.1,0.3-0.3,0.7-0.4,1
			c-0.1,0.5-0.5,0.7-1,0.4c-0.3-0.2-0.6-0.3-0.9-0.4C84.3,32.7,84.1,32.2,84,31.8z"/>
                    <path className={classes.st5} d="M39.2,38.2c0.6,0,1.2,0.3,1.4,0.9c0.2,0.7,0.1,1.3-0.5,1.7c-0.3,0.2-0.5,0.4-0.8,0.6
			c-0.5,0.3-1.1,0.1-1.2-0.4c-0.1-0.4-0.2-0.7-0.3-1C37.7,39.1,38.4,38.2,39.2,38.2z"/>
                    <path className={classes.st5} d="M3.7,26.4c0.8,0,1.5,0.4,1.7,1.2c0.1,0.5,0,1.1-0.4,1.3c-0.5,0.3-1,0.4-1.5,0.7c-0.3,0.2-0.7-0.1-0.7-0.5
			c0-0.4-0.2-0.8-0.3-1.2C2.3,27.1,2.9,26.4,3.7,26.4z"/>
                    <path className={classes.st6} d="M20.8,33.3c0.5-0.2,1-0.4,1.6-0.3c0.7,0.1,1.2,0.5,1.3,1.2c0.1,0.3,0.1,0.6-0.2,0.7c-0.3,0.1-0.4-0.1-0.5-0.3
			c-0.5-0.8-1.2-0.9-1.9-0.3c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.3,0.2-0.5,0.1c-0.2-0.1-0.3-0.3-0.2-0.5
			c0.1-0.3,0.2-0.6,0.2-0.9c-0.1-0.7-0.5-1-1.1-0.7c-0.3,0.1-0.6,0.3-0.7-0.1c-0.1-0.3,0.1-0.6,0.4-0.7c0.6-0.4,1.4-0.2,1.9,0.3
			C20.5,32.8,20.6,33,20.8,33.3z"/>
                    <path className={classes.st6} d="M16.7,38.6c-1.3,0-2.1-0.9-2.1-2c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.2c0.2,0.1,0.3,0.2,0.3,0.4
			c0,0.1,0,0.2,0,0.3c0,1,0.4,1.3,1.3,1.1c0.3,0,0.7-0.2,0.8,0.3c0.1,0.5-0.3,0.6-0.7,0.7C16.9,38.6,16.8,38.6,16.7,38.6z"/>
                    <path className={classes.st6} d="M23,40.2c-0.7,0-1.3-0.4-1.7-1c-0.1-0.2-0.2-0.4-0.1-0.5c0.2-0.2,0.4-0.2,0.6-0.1c0.1,0,0.1,0.1,0.2,0.1
			c1,0.7,1,0.7,2-0.1c0.2-0.1,0.4-0.2,0.6-0.1c0.3,0.2,0.2,0.5,0,0.7C24.2,39.9,23.7,40.2,23,40.2z"/>
                    <path className={classes.st5}
                          d="M19.8,37.2c-0.5,0-1-0.4-0.9-1c0-0.4,0.3-0.7,0.8-0.7c0.5,0,1,0.5,1,0.9C20.7,36.9,20.3,37.3,19.8,37.2z"/>
                    <path className={classes.st5} d="M87.3,34.7c0,0.3-0.1,0.5-0.5,0.5c-0.4,0-0.7-0.4-0.7-0.8c0-0.3,0.2-0.6,0.5-0.6
			C86.9,33.8,87.2,34.2,87.3,34.7z"/>
                    <path className={classes.st5} d="M85.6,34.3c0,0.4-0.2,0.7-0.6,0.7c-0.4,0-0.5-0.3-0.5-0.7c0-0.4,0.1-0.7,0.6-0.7
			C85.5,33.6,85.6,33.9,85.6,34.3z"/>
                    <path className={classes.st5} d="M37.2,40.6c0.4,0,0.6,0.2,0.6,0.6c0,0.4-0.3,0.6-0.6,0.6c-0.4,0-0.6-0.2-0.6-0.5
			C36.6,40.9,36.8,40.6,37.2,40.6z"/>
                    <path className={classes.st5} d="M40.7,42.3c-0.1,0.3-0.2,0.5-0.6,0.5c-0.4-0.1-0.6-0.3-0.5-0.7c0-0.3,0.2-0.5,0.5-0.5
			C40.4,41.6,40.7,41.9,40.7,42.3z"/>
                    <path className={classes.st5} d="M88.3,33.1c0,0.3-0.2,0.5-0.5,0.5c-0.4,0-0.7-0.2-0.7-0.6c0-0.4,0.1-0.6,0.5-0.6C88,32.4,88.3,32.7,88.3,33.1
			z"/>
                    <path className={classes.st5} d="M38.8,42.5c0,0.4-0.2,0.7-0.6,0.7c-0.4,0-0.5-0.2-0.5-0.6c0-0.3,0.2-0.6,0.5-0.6C38.5,42,38.7,42.2,38.8,42.5
			z"/>
                    <path className={classes.st5} d="M3.4,30.5C3.3,30.8,3.2,31,2.9,31c-0.4,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.5,0.5-0.5C3.2,29.9,3.4,30.1,3.4,30.5z
			"/>
                    <path className={classes.st5}
                          d="M4.9,30.2c0,0.3-0.1,0.5-0.5,0.6c-0.4,0-0.6-0.2-0.6-0.5c0-0.3,0.2-0.5,0.5-0.5C4.7,29.7,4.9,29.9,4.9,30.2z"
                    />
                    <path className={classes.st5}
                          d="M1.6,28.5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.1,0.4-0.4,0.4c-0.3,0-0.5-0.2-0.5-0.5C1.2,28.7,1.3,28.5,1.6,28.5z"
                    />
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_7_" x="64.8" y="49.3" width="9" height="9"/>
                </defs>
                <clipPath id="SVGID_8_">
                    <use xlinkHref="#SVGID_7_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st7}>
                    <path className={classes.st8} d="M70.4,53.4c0.1,0.1,0.2,0.1,0.3,0l1.9-1.9l0,0.9c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1l0.9,0
			c0.1,0,0.2-0.1,0.2-0.2l-0.1-2.8c0-0.1-0.1-0.2-0.2-0.2l-2.8-0.1c-0.1,0-0.2,0.1-0.2,0.2l0,0.9c0,0.1,0,0.1,0.1,0.1
			c0,0,0.1,0.1,0.1,0.1l0.9,0l-1.9,1.9c-0.1,0.1-0.1,0.2,0,0.3L70.4,53.4z"/>
                    <path className={classes.st8} d="M69,54.8l-0.6-0.6c-0.1-0.1-0.2-0.1-0.3,0L66.2,56l0-0.9c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1L65,55
			c-0.1,0-0.2,0.1-0.2,0.2l0.1,2.8c0,0.1,0.1,0.2,0.2,0.2l2.8,0.1h0c0.1,0,0.2-0.1,0.2-0.2l0-0.9c0-0.1,0-0.1-0.1-0.1
			c0,0-0.1-0.1-0.1-0.1l-0.9,0l1.9-1.9C69,55,69,54.9,69,54.8z"/>
                </g>
            </g>
        </SVG>
    );
}