import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { Row } from "antd";
import { RowProps } from "antd/es/grid/row";
import { FlexBoxGap } from "./const";
import classNames from "classnames";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../ErrorFallback";

type FlexBoxType = {
    gap?: keyof typeof FlexBoxGap;
    column?: boolean;
    flexWrap?: boolean;
}

export const FlexBox: FC<FlexBoxType & RowProps> = (
    {
        gap,
        children,
        column,
        flexWrap,
        className,
        ...props
    }
) => {
    const flexBoxClassNames = classNames( className, {
        [classes.flexBox]: true,
        [classes[`gap_${gap}`]]: !!gap,
        [classes.column]: column,
        [classes.flexWrap]: flexWrap,
    });

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Row className={flexBoxClassNames} {...props}>
                {children}
            </Row>
        </ErrorBoundary>
    );
}