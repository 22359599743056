import { FC } from 'react';
import classes from './styles/index.module.scss';
import { Section } from "../../components/Section";
import { FlexBox } from "../../../../../../components/FlexBox";
import { Title } from "../../../../components/Topography/Title";
import { Span } from "../../../../components/Topography/Span";
import { RoundedContainer } from "../../../../components/RoundedContainer";
import { PaddingBox } from "../../../../../../components/PaddingBox";
import { UList } from "../../../../components/Topography/UList";
import { useResizeContext } from "../../../../providers/ResizeProvider/ResizeProvider";
import { ScreenRecordPopup } from "../../components/ScreenRecordPopup";
import { ScreenRecordingImage } from "../../../../images/ScreenRecordingImage";
import { ArrowForRecordImage } from "../../../../images/ArrowForRecordImage";


export const CaptureAScreenRecordSection: FC = () => {
    const { innerWidth, isMobile } = useResizeContext();
    const isColumn = innerWidth < 1280;

    return (
        <Section>
            <FlexBox gap='middle' justify='center' align='middle' column={isColumn}>
                <FlexBox column gap='large' align='middle'>
                    <Title level={4} size={isMobile ? 'normal' : 'big'} weight='extraBold'>
                        <FlexBox align='middle' gap='small'>
                            <Span>
                                Capture a
                            </Span>
                            <RoundedContainer>
                                <PaddingBox normalHR mediumVR>
                                    Screen Record
                                </PaddingBox>
                            </RoundedContainer>
                        </FlexBox>
                    </Title>
                    <UList markType='decimal' style={{ maxWidth: '550px'}}>
                        <FlexBox column gap='small'>
                            <li>
                                <Span size='small' weight='regular'>Choose screen video</Span>
                            </li>
                            <li>
                                <Span size='small' weight='regular'>
                                    Record your Desktop or Camera or Both
                                </Span>
                            </li>
                            <li>
                                <Span size='small' weight='regular'>
                                    Narrate with your microphone’s audio
                                </Span>
                            </li>
                            <li>
                                <Span size='small' weight='regular'>Share with anyone</Span></li>
                        </FlexBox>
                    </UList>
                </FlexBox>
                <PaddingBox large>
                    <FlexBox className={classes.imagesContainer} justify='center' align='middle' style={{ width: isMobile ? 'auto' : '500px'}}>

                        <ScreenRecordPopup className={classes.popup} style={{ position: isMobile ? 'static' : 'absolute'}}/>
                        {!isMobile && (
                            <>
                                <ScreenRecordingImage className={classes.svgImage}/>
                                <ArrowForRecordImage className={classes.arrow}/>
                            </>
                        )}
                    </FlexBox>
                </PaddingBox>
            </FlexBox>
        </Section>
    );
}