import React, { FC } from 'react';
import { SVG } from "./SVG";

export const Record: FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <SVG {...props} isIcon>
        <g clipPath="url(#clip0)">
            <path d="M12.1977 4.66672H2.38095C1.06659 4.66821 0.0014881 5.73331 0 7.04767V14.95C0.0014881 16.2644 1.06659 17.3295 2.38095 17.331H12.1977C13.5121 17.3295 14.5772 16.2644 14.5787 14.95V7.04767C14.5772 5.73331 13.5121 4.66821 12.1977 4.66672V4.66672Z" fill="#A5ADC2"/>
            <path d="M21.7598 5.90642C21.6114 5.82178 21.4289 5.82308 21.2816 5.90995L16.0167 9.01859C15.8716 9.10434 15.7827 9.26022 15.7827 9.42874V12.3004C15.7827 12.4475 15.8506 12.5865 15.9669 12.6767L21.2317 16.7641C21.3753 16.8756 21.5699 16.8956 21.7332 16.8157C21.8966 16.7357 22 16.5697 22 16.3878V6.31992C22 6.14898 21.9083 5.99124 21.7598 5.90642Z" fill="#A5ADC2"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="22" height="22" fill="white"/>
            </clipPath>
        </defs>
    </SVG>
);