export enum StorageKey {
    Screenshots = 'screenshots',
    Records = 'records',
    LastScreenshot = 'lastScreenshot',
    LastRecord = 'lastRecord',
    TempImage = 'tempImage',
    TempScrolledData = 'TempScrolledData',
    Tabs = 'tabs',
    PopupData = 'popupData',
    RecordData = 'recordData',
}