import React, { FC, useCallback, useRef } from 'react';
import classes from './styles/index.module.scss';
import { getPageTitle } from "../../utils/getPageTitle";
import { WelcomeComponent } from "../../components/WelcomeComponent";
import { FlexBox } from "../../../../components/FlexBox";
import { Title } from "../../components/Topography/Title";
import { Button } from "../../components/Button";
import { ArrowRight } from "../../icons/ArrowRight";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import { Section } from "../Main/components/Section";
import aboutUsImage1Url from '../../images/AboutUs1.png';
import aboutUsImage2Url from '../../images/AboutUs2.png';
import aboutUsImage3Url from '../../images/AboutUs3.png';
import { Span } from "../../components/Topography/Span";
import { UList } from "../../components/Topography/UList";
import { AboutUsCatImage } from "../../images/AboutUsCatImage";
import { Link } from "react-router-dom";
import { EXTENSION_STORE_URL } from "../../const";
import { AboutUsTryExtensionCatImage } from "../../images/AboutUsTryExtensionCatImage";
import { Head } from "../../components/Head";

const title = 'About Us';
const description = 'In the ever-evolving digital era, the need to capture, share, and edit screen...';

export const AboutUs: FC = () => {
    const { isMobile } = useResizeContext();
    const nodeRef = useRef<HTMLDivElement>(null);

    const onContinueClick = useCallback(() => {
        window.scroll({ top: (nodeRef.current?.getBoundingClientRect().top ?? 0) - (isMobile ? 72 : 104) });
    }, [isMobile]);

    return (
        <>
            <Head title={getPageTitle(title)} description={description}/>
            <WelcomeComponent
                content={(
                    <FlexBox column align='middle' justify={isMobile ? 'end' : 'space-evenly'} gap='large' style={{ height: '100%', maxWidth: '1000px' }}>
                        <FlexBox column align='middle' gap='large'>
                            <Title level={2} size={isMobile ? "big" : 'extraLarge'} weight="extraBold" style={{ textAlign: 'center' }}>
                                {title}
                            </Title>
                        </FlexBox>
                        <Button onClick={onContinueClick}>
                            <span>Continue</span>
                            <ArrowRight style={{ transform: 'rotate(90deg)' }}/>
                        </Button>
                    </FlexBox>
                )}
            />
            <div ref={nodeRef}>
                <Section>
                    <FlexBox column align='middle' justify='center'>
                        <FlexBox style={{ maxWidth: '744px' }} align='middle' justify='center' gap='extraLarge' column>
                            <FlexBox justify='center' align='middle' style={{ width: '160px', height: '160px', borderRadius: '32px', background: '#5EBF72' }}>
                                <AboutUsCatImage/>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <Title level={3} size='big' weight='bold'>
                                    Welcome to Screeny!
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    In the ever-evolving digital era, the need to capture, share, and edit screen moments has become more vital than ever. That’s where Screeny steps in – an innovative screenshot app meticulously crafted to transform the way you capture your digital experiences
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <div className={classes.image}>
                                    <img src={aboutUsImage1Url} alt=""/>
                                </div>
                                <Title level={3} size='normal' weight='bold'>
                                    Our Story
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    Born out of a passion for seamless technology and a vision to streamline digital communication, Screeny was developed by a team of tech enthusiasts who believed in the power of visual storytelling. We recognized that while there are myriad ways to share information online, the simplicity and efficiency of a screenshot are unmatched
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <div className={classes.image}>
                                    <img src={aboutUsImage2Url} alt=""/>
                                </div>
                                <Title level={3} size='normal' weight='bold'>
                                    Why Screeny?
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    <UList markType='decimal'>
                                        <li><Span weight='bold'>Innovative Features:</Span> Our cutting-edge app offers a range of unique features, from intuitive screen capturing tools to advanced editing capabilities. Whether you want to take a quick snap or annotate detailed information, Screeny has got you covered</li>
                                        <li><Span weight='bold'>User-Centric Design:</Span> We prioritize our users. Hence, Screeny is designed to be user-friendly, ensuring even those new to screenshot tools can navigate with ease</li>
                                        <li><Span weight='bold'>Privacy First:</Span> We understand the importance of privacy in the digital age. Screeny guarantees that your captured screens remain in your control, always</li>
                                        <li><Span weight='bold'>Constant Evolution:</Span> In this rapidly changing tech landscape, we remain committed to evolving. Regular updates and improvements ensure Screeny remains at the forefront of screenshot technology</li>
                                    </UList>
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <div className={classes.image}>
                                    <img src={aboutUsImage3Url} alt=""/>
                                </div>
                                <Title level={3} size='normal' weight='bold'>
                                    Our Mission
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    To provide users with a powerful yet simple screenshot tool that elevates their digital interactions, ensuring no moment or detail is ever lost in translation
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <Title level={3} size='normal' weight='bold'>
                                    Join the Screeny Family
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    Whether you’re a professional seeking a streamlined communication tool, a student wanting to capture vital information, or someone simply looking to remember and share moments, Screeny is here to redefine your screenshot experience                                </Span>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Section>
                <Section>
                    <FlexBox column align='middle' justify='center' gap='large'>
                        <FlexBox column gap='extraLarge' justify='center' align='middle' style={{ maxWidth: '780px' }}>
                            <FlexBox justify='end' align='middle' style={{ width: '160px', height: '160px', borderRadius: '32px', background: '#1A32C8' }}>
                                <AboutUsTryExtensionCatImage/>
                            </FlexBox>
                            <Title level={4} size={isMobile ? 'normal' : 'big'} weight='extraBold' style={{ textAlign: 'center' }}>
                                Thank you for choosing Screeny
                                Dive in and explore the myriad possibilities with us!
                            </Title>
                        </FlexBox>
                        <Link to={EXTENSION_STORE_URL} target='_blank'>
                            <Button>
                                <Span>Try Extension Now</Span>
                                <ArrowRight/>
                            </Button>
                        </Link>
                    </FlexBox>
                </Section>
            </div>
        </>
    );
}