import { FC } from 'react';
import { Section } from "../../components/Section";
import { FlexBox } from "../../../../../../components/FlexBox";
import { GoogleDriveIcon } from "../../../../icons/GoogleDriveIcon";
import { Title } from "../../../../components/Topography/Title";
import { RoundedContainer } from "../../../../components/RoundedContainer";
import { PaddingBox } from "../../../../../../components/PaddingBox";
import { Span } from "../../../../components/Topography/Span";
import { googleDriveInformation } from "./const";
import { useResizeContext } from "../../../../providers/ResizeProvider/ResizeProvider";

export const GoogleDriveIntegrationSection: FC = () => {
    const { isMobile } = useResizeContext();
    return (
        <Section>
            <FlexBox column justify='space-evenly'>
                <FlexBox gap='medium' align='middle' justify='center'>
                    <GoogleDriveIcon/>
                    <Title size={isMobile ? 'normal' : 'big'} weight='extraBold' style={{ textAlign: 'center' }}>
                        Google Drive Integration
                    </Title>
                </FlexBox>
                <PaddingBox large>
                    <FlexBox gap='medium' justify='center' column={isMobile}>
                        {googleDriveInformation.map(({ icon, title, description }) => {
                            const Icon = icon;
                            return (
                                <FlexBox column gap='large' key={title} style={{ maxWidth: '360px' }}>
                                    <FlexBox>
                                        <RoundedContainer>
                                            <PaddingBox middle>
                                                <Icon/>
                                            </PaddingBox>
                                        </RoundedContainer>
                                    </FlexBox>
                                    <FlexBox column gap='medium'>
                                        <Span size='normal' weight='bold'>
                                            {title}
                                        </Span>
                                        <Span size='small' weight="regular" secondary>
                                            {description}
                                        </Span>
                                    </FlexBox>
                                </FlexBox>
                            )
                        })}
                    </FlexBox>
                </PaddingBox>
            </FlexBox>
        </Section>
    );
}