import { FC, useRef, useState } from 'react';
import classes from './styles/index.module.scss';
import { FlexBox } from "../../../../../components/FlexBox";
import { menuItems } from "./MenuItem/const";
import { MenuItem } from "./MenuItem";
import { useResizeContext } from "../../../providers/ResizeProvider/ResizeProvider";
import { Button } from "../../Button";
import { MenuIcon } from "../../../icons/MenuIcon";
import { CSSTransition } from "react-transition-group";
import { UList } from "../../Topography/UList";
import { BrowserIcon } from "../../../icons/BrowserIcon";
import { EXTENSION_STORE_URL } from "../../../const";
import { Link } from "react-router-dom";

export const Menu: FC = () => {
    const { isMobile } = useResizeContext();
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const nodeRef = useRef(null);
    const switchMenu = () => {
        setIsMenuOpened(prevState => !prevState);
    };

    return (
        <>
            {isMobile ? (
                <>
                    <Button link onClick={switchMenu}>
                        <MenuIcon isOpened={isMenuOpened}/>
                    </Button>
                    <CSSTransition
                        in={isMenuOpened}
                        timeout={300}
                        nodeRef={nodeRef}
                        mountOnEnter
                        unmountOnExit
                        classNames={{
                            enterActive: classes.menu_visible,
                            enterDone: classes.menu_visible,
                            exitActive: classes.menu_hidden,
                            exitDone:  classes.menu_hiddenDone
                        }}
                    >
                        <div ref={nodeRef} className={classes.menu}>
                            <FlexBox column justify='space-between' style={{ height: '100%' }}>
                                <nav>
                                    <UList isMarkerHidden>
                                        <FlexBox column>
                                            <FlexBox column>
                                                {menuItems.map(({ p, ...rest }) => {
                                                    return (
                                                        <MenuItem key={rest.title} {...rest} page={p} onClick={switchMenu}/>
                                                    )
                                                })}
                                            </FlexBox>
                                        </FlexBox>
                                    </UList>
                                </nav>
                                <Link to={EXTENSION_STORE_URL} target="_blank">
                                    <Button>
                                        <BrowserIcon/>
                                        <span>Try for free</span>
                                    </Button>
                                </Link>
                            </FlexBox>
                        </div>
                    </CSSTransition>
                </>
            ) : (
                <nav>
                    <UList isMarkerHidden>
                        <FlexBox gap='medium'>
                            {menuItems.map(({ p, ...rest }) => {
                                return (
                                    <MenuItem key={rest.title} {...rest} page={p}/>
                                )
                            })}
                        </FlexBox>
                    </UList>
                </nav>
            )}
        </>
    );
}