import { FC } from 'react';
import classes from './styles/index.module.scss';
import classNames from "classnames";
import { FlexBox } from "../../../../components/FlexBox";

type ButtonPropsType = {
    link?: boolean;
    light?: boolean;
    dark?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const Button: FC<ButtonPropsType> = (
    {
        link,
        dark,
        light,
        className,
        children,
        ...props
    }
) => {
    const buttonClasses = classNames(className, classes.button, {
        [classes.button_primary]: !link,
        [classes.button_link]: link,
        [classes.button_dark]: dark,
        [classes.button_light]: light,
    });

    return (
        <button className={buttonClasses} {...props}>
            <FlexBox justify='center' align='middle' gap='tiny'>
                {children}
            </FlexBox>
        </button>
    );
}