import { FC } from 'react';
import classes from './styles/index.module.scss';
import { FlexBox } from "../../../../components/FlexBox";
import { Logo } from "../Logo";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { BROWSER_TYPE, EXTENSION_STORE_URL, RouteUrls, SUPPORT_EMAIL } from "../../const";
import { Span } from "../Topography/Span";
import { BrowserIcon } from "../../icons/BrowserIcon";
import { PaddingBox } from "../../../../components/PaddingBox";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";

export const Footer: FC = () => {
    const { isMobile } = useResizeContext();

    return (
        <footer className={classes.footer}>
            <PaddingBox largeTop>
                <FlexBox column justify='end' align='middle' gap='large'>
                    <FlexBox gap={isMobile ? 'small' : 'middle'} justify='space-evenly' align='middle' style={{ width: '100%' }} column={isMobile}>
                        <Logo colorType='light'/>
                        <FlexBox gap={isMobile ? 'small' : 'large'} column={isMobile}>
                            <Link to={RouteUrls.HowToUse}>
                                <Button link light>
                                    How to Use
                                </Button>
                            </Link>
                            <Link to={RouteUrls.AboutUs}>
                                <Button link light>
                                    About Us
                                </Button>
                            </Link>
                            <Link to={`mailto:${SUPPORT_EMAIL}}`}>
                                <Button link light>
                                    Contact Us
                                </Button>
                            </Link>
                            <Link to={RouteUrls.PrivacyPolicy}>
                                <Button link light>
                                    Privacy Policy
                                </Button>
                            </Link>
                        </FlexBox>
                        <Link to={EXTENSION_STORE_URL} target='_blank'>
                            <Button>
                                <Span>Add to {BROWSER_TYPE}</Span>
                                <BrowserIcon/>
                            </Button>
                        </Link>
                    </FlexBox>
                    <PaddingBox large>
                        <Span secondary size='tiny' weight='regular'>
                            Copyright © 2023 Screeny. All rights reserved.
                        </Span>
                    </PaddingBox>
                </FlexBox>
            </PaddingBox>
        </footer>
    );
}