import { HistorySegmentedOptionValue } from "./pages/History/const";

const index = 'index.html';

export enum RouteNames {
    Editor = 'editor',
    History = 'history',
    Record = 'record',
    RecordingPage = 'recording-page',
    WebcamRecorder = 'webcam-recorder',
    FullScreenCameraPage = 'full-screen-camera',
}

export enum RoutePathParam {
    Page = 'page',
    ContentId = 'id',
    Tab = 'tab',
}

export const RoutePath = {
    Main: `/${index}`,
    Editor: `/${index}?${RoutePathParam.Page}=${RouteNames.Editor}`,
    History: `/${index}?${RoutePathParam.Page}=${RouteNames.History}`,
    Records: `/${index}?${RoutePathParam.Page}=${RouteNames.History}&${RoutePathParam.Tab}=${HistorySegmentedOptionValue.ScreenRecords}`,
    Screenshots: `/${index}?${RoutePathParam.Page}=${RouteNames.History}&${RoutePathParam.Tab}=${HistorySegmentedOptionValue.Screenshots}`,
    Recorder: `/${index}?${RoutePathParam.Page}=${RouteNames.RecordingPage}`,
    WebcamRecorder: `/${index}?${RoutePathParam.Page}=${RouteNames.WebcamRecorder}`,
    FullScreenCamera: `/${index}?${RoutePathParam.Page}=${RouteNames.FullScreenCameraPage}`,
}