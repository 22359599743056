import { FC } from 'react';
import classes from './styles/index.module.scss';
import classNames from "classnames";

type RoundedContainerPropsType = {} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const RoundedContainer: FC<RoundedContainerPropsType> = (
    {
        className, ...props
    }
) => {
    const containerClasses = classNames(className, classes.container)
    return (
        <div className={containerClasses} {...props}/>
    );
}