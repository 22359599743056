import { StorageKey } from "../../../extension/StorageKey";
import { StorageScreenshotType } from "../types";

export const getAllScreenshots = () => {
    return new Promise<StorageScreenshotType>((resolve, reject) => {
        try {
            chrome.storage.local.get(StorageKey.Screenshots, res => {
                const screenshots: StorageScreenshotType = res[StorageKey.Screenshots] ?? {};
                resolve(screenshots)
            })
        }
        catch (error) {
            reject(new Error(`'chrome' is not defined`));
        }
    });
}