import React, { FC } from 'react';
import { SVG } from "../../SVG";
import classes from "./styles/index.module.scss";

export const WholePageCat: FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <SVG {...props} height='195px' width='75px'>
            <g>
                <defs>
                    <rect id="SVGID_1_" x="15.9" y="142.1" width="58.2" height="52.2"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st0}>
                    <path className={classes.st1} d="M26.9,187.4c-0.2-0.4-0.3-0.9-0.5-1.4c-0.8-1.8-0.3-9,0-11.6l-7.7-2.1c0.1,2.6,0.3,12.3-0.5,14.1
			c-0.2,0.4-0.3,0.7-0.5,1c-0.8,0.4-1.4,1.3-1.4,2.3c0,1.2,0.8,2.2,2,2.4c0.2,1.2,1.2,2,2.5,2c0.6,0,1.2-0.2,1.6-0.6
			c0.5,0.4,1.1,0.7,1.8,0.7c1.3,0,2.3-0.9,2.5-2.1c1.2-0.2,2-1.2,2-2.5C28.7,188.6,27.9,187.7,26.9,187.4z"/>
                    <path className={classes.st2} d="M19.9,186.2c0-1.5,1.1-0.9,2.5-0.9c1.4,0,2.5-0.6,2.5,0.9c0,1.5-1.1,2.7-2.5,2.7
			C21,188.9,19.9,187.7,19.9,186.2z"/>
                    <path className={classes.st2} d="M25.5,190.4c-0.5-0.4-1-1.4-0.7-1.9c0.3-0.5,1.3-0.2,1.9,0.2c0.5,0.4,0.6,1.1,0.3,1.6
			C26.7,190.7,26,190.8,25.5,190.4z"/>
                    <path className={classes.st2} d="M23.1,192.1c-0.2-0.7-0.1-2,0.4-2.2c0.6-0.2,1.4,0.6,1.7,1.3c0.2,0.7,0,1.5-0.6,1.7
			C24,193.1,23.3,192.8,23.1,192.1z"/>
                    <path className={classes.st2} d="M22.2,191.9c0.2-0.7,0-2-0.6-2.1c-0.6-0.2-1.4,0.8-1.5,1.5c-0.2,0.7,0.1,1.5,0.7,1.6
			C21.3,193.1,22,192.6,22.2,191.9z"/>
                    <path className={classes.st2} d="M19.2,190.5c0.5-0.4,0.9-1.4,0.6-1.9c-0.3-0.5-1.4-0.2-1.8,0.2c-0.5,0.4-0.6,1.2-0.2,1.6
			C18.1,190.9,18.8,190.9,19.2,190.5z"/>
                    <path className={classes.st1} d="M45.4,187.4c0.2-0.4,0.3-0.9,0.5-1.4c0.8-1.8,0.3-9,0-11.6l7.7-1.6c-0.1,2.6-0.3,11.7,0.5,13.6
			c0.2,0.4,0.3,0.7,0.5,1c0.8,0.4,1.4,1.3,1.4,2.3c0,1.2-0.8,2.2-2,2.4c-0.2,1.2-1.2,2-2.5,2c-0.6,0-1.2-0.2-1.6-0.6
			c-0.5,0.4-1.1,0.7-1.8,0.7c-1.3,0-2.3-0.9-2.5-2.1c-1.2-0.2-2-1.2-2-2.5C43.7,188.6,44.4,187.7,45.4,187.4z"/>
                    <path className={classes.st2} d="M52.5,186.2c0-1.5-1.1-0.9-2.5-0.9c-1.4,0-2.5-0.6-2.5,0.9c0,1.5,1.1,2.7,2.5,2.7
			C51.4,188.9,52.5,187.7,52.5,186.2z"/>
                    <path className={classes.st2} d="M46.8,190.4c0.5-0.4,1-1.4,0.7-1.9c-0.3-0.5-1.3-0.2-1.9,0.2c-0.5,0.4-0.6,1.1-0.3,1.6
			C45.7,190.7,46.3,190.8,46.8,190.4z"/>
                    <path className={classes.st2} d="M49.3,192.1c0.2-0.7,0.1-2-0.4-2.2c-0.6-0.2-1.4,0.6-1.7,1.3c-0.2,0.7,0,1.5,0.6,1.7
			C48.4,193.1,49.1,192.8,49.3,192.1z"/>
                    <path className={classes.st2}
                          d="M50.2,191.9c-0.2-0.7,0-2,0.6-2.1s1.4,0.8,1.5,1.5c0.2,0.7-0.1,1.5-0.7,1.6C51,193.1,50.4,192.6,50.2,191.9z"
                    />
                    <path className={classes.st2} d="M53.1,190.5c-0.5-0.4-0.9-1.4-0.6-1.9c0.3-0.5,1.4-0.2,1.8,0.2c0.5,0.4,0.6,1.2,0.2,1.6
			C54.3,190.9,53.6,190.9,53.1,190.5z"/>
                    <path className={classes.st1}
                          d="M17.1,142.1c-0.8,9.8-4.1,26.3,4.6,33.5c7.8,6.4,29.4,5,33.5-5.4c2.3-6.5,1.8-20.4,1-28.1H17.1z"/>
                    <path className={classes.st1} d="M26.3,166.3c0.6-0.1,1.2-0.2,1.8-0.3c0.3,0,0.6-0.1,0.9-0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.2,0-0.1,0
			c0.1,0,0.2,0,0.3,0c1.3-0.1,2.6-0.1,3.9,0.1c0.3,0,0.6,0.1,0.9,0.1c-0.5-0.1,0,0,0.1,0c0.2,0,0.3,0.1,0.4,0.1
			c0.6,0.1,1.3,0.4,1.9,0.6c0.1,0.1,0.3,0.1,0.4,0.2c0.5,0.2-0.4-0.2,0.1,0c0.3,0.1,0.6,0.3,0.8,0.5c0.3,0.2,0.6,0.3,0.8,0.5
			c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.2c-0.2-0.2-0.2-0.2-0.1,0c1.9,1.7,3.3,3.9,5,5.9c1.4,1.6,3.2,2.9,5.2,3.6
			c3.5,1.2,7.4,0.3,10.5-1.5c3.3-1.9,6-4.9,7.8-8.2c1.3-2.6,2.2-5.4,2.1-8.4c0-1.1-0.8-2.1-2-2.2c-1.1,0-2.2,0.8-2.1,2
			c0,0.9,0,1.7-0.2,2.6c0.1-0.7,0,0-0.1,0.2c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.4-0.2,0.9-0.4,1.3c-0.1,0.4-0.3,0.8-0.5,1.2
			c0,0.1-0.1,0.2-0.1,0.3c0.3-0.6,0.1-0.2,0.1-0.1c-0.1,0.2-0.2,0.4-0.3,0.7c-0.4,0.8-0.9,1.5-1.4,2.3c-0.1,0.2-0.3,0.3-0.4,0.5
			c-0.1,0.1-0.6,0.7-0.1,0.2c-0.3,0.4-0.6,0.7-0.9,1c-0.6,0.6-1.3,1.2-2,1.7c-0.2,0.1-0.3,0.2-0.5,0.4c-0.3,0.2,0.4-0.3,0.1-0.1
			c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.2-0.8,0.5-1.1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5c-0.2,0.1-0.4,0.1-0.5,0.2c0.7-0.3-0.1,0-0.2,0.1
			c-0.4,0.1-0.7,0.2-1.1,0.3c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0,0.6,0,0.1,0c-0.4,0-0.8,0-1.1,0c-0.2,0-0.4,0-0.6,0
			c-0.1,0-0.2,0-0.3,0c-0.3-0.1,0.6,0.1,0.1,0c-0.4-0.1-0.7-0.2-1.1-0.3c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1,0-0.2-0.1-0.3-0.1
			c0.7,0.3,0.2,0.1,0.1,0c-0.4-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.3-0.3-0.5-0.4c-0.3-0.3,0.4,0.3,0,0c-0.1-0.1-0.3-0.3-0.4-0.4
			c-1.8-1.8-3.1-4-4.8-5.8c-2-2.1-4.6-3.6-7.4-4.2c-3.5-0.8-7.2-0.5-10.7,0.1c-1.1,0.2-1.8,1.4-1.6,2.4
			C24.1,165.8,25.2,166.5,26.3,166.3z"/>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_3_" x="10.8" y="1.5" width="50.2" height="28.1"/>
                </defs>
                <clipPath id="SVGID_4_">
                    <use xlinkHref="#SVGID_3_" style={{overflow:'visible'}}/>
                </clipPath>
                <g className={classes.st3}>
                    <path className={classes.st1} d="M57.4,29.7c0.4-2.6,0.2-4.9,0.1-5.7C57,16.6,53.1,7.8,48.4,2.4c-2.4,2.4-3.5,5.9-3.7,8.8
			c-6.5-2.3-10.1-2.1-16.4-0.3c-1-2.6-2.5-6.6-4.2-9.4c-4.6,2.8-8.2,16.3-8.2,16.3s-1.4,6.3-0.4,11.8H57.4z"/>
                    <path className={classes.st2} d="M19.4,13.9c0,0,3.8-2.5,6.4-3.1l-2.2-5.4C23.6,5.4,20,11.9,19.4,13.9z"/>
                    <path className={classes.st2}
                          d="M53.3,14.7c0,0-4.9-2.3-6-2.9C46.5,11.5,48.9,6,48.9,6S52.7,12.7,53.3,14.7z"/>
                    <path className={classes.st4} d="M15.4,22.2c-1.3-0.2-2.6-0.4-3.9-0.5c-0.1,0-0.3,0.2-0.1,0.2c1.3,0.1,2.7,0.3,4,0.5
			C15.4,22.3,15.4,22.3,15.4,22.2z"/>
                    <path className={classes.st4} d="M15.2,24.5c-1.1,0.2-2.2,0.4-3.4,0.1c-0.1,0-0.4,0.1-0.3,0.1c1.2,0.3,2.4,0.1,3.6-0.2
			C15.2,24.5,15.2,24.5,15.2,24.5z"/>
                    <path className={classes.st4} d="M15.4,26.4c-0.9,0.5-1.7,1.1-2.7,1.3c-0.2,0-0.2,0.2,0.1,0.2c1-0.2,1.9-0.8,2.7-1.3
			C15.4,26.5,15.4,26.5,15.4,26.4z"/>
                    <path className={classes.st4} d="M60.4,21.4c-1.2,0-2.3,0.2-3.5,0.3c0,0.1,0.1,0.1,0.1,0.2c1.2-0.2,2.3-0.3,3.5-0.4
			C60.7,21.6,60.5,21.4,60.4,21.4z"/>
                    <path className={classes.st4}
                          d="M59.9,24.2c-0.8,0.2-1.6,0.1-2.4,0c0,0,0,0.1,0,0.1c0.9,0.1,1.8,0.2,2.7,0C60.3,24.4,60,24.2,59.9,24.2z"/>
                    <path className={classes.st4} d="M59.7,27.7c-0.8-0.2-1.6-0.6-2.3-1.1c0,0.1,0,0.1,0,0.2c0.7,0.5,1.4,0.9,2.3,1.1
			C59.9,27.9,59.9,27.7,59.7,27.7z"/>
                    <path className={classes.st5}
                          d="M30.2,21.2c0,2.1-1.7,3.8-3.7,3.8s-3.7-1.7-3.7-3.8c0-2.1,1.7-3.8,3.7-3.8S30.2,19.1,30.2,21.2z"/>
                    <path className={classes.st2}
                          d="M29,21.4c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4C28,18.9,29,20,29,21.4z"/>
                    <path className={classes.st1}
                          d="M28.4,21c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6C28.1,20.5,28.4,20.7,28.4,21z"
                    />
                    <path className={classes.st5} d="M49.5,21.2c0,2.1-1.7,3.8-3.7,3.8c-2,0-3.7-1.7-3.7-3.8c0-2.1,1.7-3.8,3.7-3.8C47.8,17.4,49.5,19.1,49.5,21.2
			z"/>
                    <path className={classes.st2} d="M48.3,21.4c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4C47.2,18.9,48.3,20,48.3,21.4
			z"/>
                    <path className={classes.st1}
                          d="M46.1,21c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6C45.8,20.5,46.1,20.7,46.1,21z"
                    />
                    <path className={classes.st2} d="M36.1,30.2v-6.5h0.4v6.5H36.1z"/>
                    <path className={classes.st2}
                          d="M38.4,23.4c0,0.9-2.1,2.4-2.1,2.4s-2.1-1.6-2.1-2.4c0-0.9,0.9-1,2.1-1C37.5,22.4,38.4,22.6,38.4,23.4z"/>
                </g>
            </g>
            <path className={classes.st6} d="M3.8,31.2h65.3c0.8,0,1.5,0.7,1.5,1.5v121.5c0,0.8-0.7,1.5-1.5,1.5H3.8c-0.8,0-1.5-0.7-1.5-1.5V32.7
	C2.3,31.8,3,31.2,3.8,31.2z"/>
            <rect x="28.9" y="157.2" className={classes.st7} width="15.1" height="40.2"/>
            <rect x="16.9" y="197.3" className={classes.st7} width="38.2" height="4"/>
            <path className={classes.st1} d="M15.6,30.7c-0.1-3-3.3-3.5-6.9-3.5C5,27.2,2,28.6,2,31c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
	c0,1.7,1.4,3.1,3.1,3.1c0.4,0,0.7-0.1,1.1-0.2C6.7,35.3,7.8,36,9,36c1.4,0,2.5-0.9,3-2.1c0.2,0,0.3,0,0.5,0c1.7,0,3.1-1.4,3.1-3.1
	C15.6,30.8,15.6,30.8,15.6,30.7z"/>
            <path className={classes.st1} d="M56.3,30.8c0.1-3,3.6-3.8,7.2-3.8c3.7,0,6.4,1.7,6.4,4.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
	c0,1.7-1.4,3.1-3.1,3.1c-0.4,0-0.7-0.1-1.1-0.2c-0.5,1.1-1.6,1.8-2.8,1.8c-1.4,0-2.5-0.9-3-2.1c-0.2,0-0.3,0-0.5,0
	c-1.7,0-3.1-1.4-3.1-3.1C56.3,30.9,56.3,30.9,56.3,30.8z"/>
            <path className={classes.st7} d="M43.9,46H28.3c0,0-0.1,0-0.1-0.1v-1.2c0,0,0-0.1,0.1-0.1h15.6c0,0,0.1,0,0.1,0.1L43.9,46
	C43.9,46,43.9,46,43.9,46z"/>
            <path className={classes.st7} d="M62,50.5H28.3c0,0-0.1,0-0.1-0.1v-1.2c0,0,0-0.1,0.1-0.1H62c0,0,0.1,0,0.1,0.1L62,50.5C62,50.5,62,50.5,62,50.5
	z"/>
            <path className={classes.st7} d="M54.2,53.9H28.3c0,0-0.1,0-0.1-0.1v-1.2c0,0,0-0.1,0.1-0.1h25.9c0,0,0.1,0,0.1,0.1L54.2,53.9
	C54.3,53.9,54.2,53.9,54.2,53.9z"/>
            <path className={classes.st7} d="M43.9,66.7H28.3c0,0-0.1,0-0.1-0.1v-1.2c0,0,0-0.1,0.1-0.1h15.6c0,0,0.1,0,0.1,0.1L43.9,66.7
	C43.9,66.7,43.9,66.7,43.9,66.7z"/>
            <path className={classes.st7}
                  d="M62,71.2H28.3c0,0-0.1,0-0.1-0.1V70c0,0,0-0.1,0.1-0.1H62c0,0,0.1,0,0.1,0.1L62,71.2C62,71.2,62,71.2,62,71.2z"
            />
            <path className={classes.st7} d="M54.2,74.6H28.3c0,0-0.1,0-0.1-0.1v-1.2c0,0,0-0.1,0.1-0.1h25.9c0,0,0.1,0,0.1,0.1L54.2,74.6
	C54.3,74.6,54.2,74.6,54.2,74.6z"/>
            <path className={classes.st7} d="M43.9,89.9H28.3c0,0-0.1,0-0.1-0.1v-1.2c0,0,0-0.1,0.1-0.1h15.6c0,0,0.1,0,0.1,0.1L43.9,89.9
	C43.9,89.9,43.9,89.9,43.9,89.9z"/>
            <path className={classes.st7} d="M62,94.4H28.3c0,0-0.1,0-0.1-0.1v-1.2c0,0,0-0.1,0.1-0.1H62c0,0,0.1,0,0.1,0.1L62,94.4C62,94.4,62,94.4,62,94.4
	z"/>
            <path className={classes.st7} d="M54.2,97.8H28.3c0,0-0.1,0-0.1-0.1v-1.2c0,0,0-0.1,0.1-0.1h25.9c0,0,0.1,0,0.1,0.1L54.2,97.8
	C54.3,97.8,54.2,97.8,54.2,97.8z"/>
            <path className={classes.st7} d="M43.9,112.4H28.3c0,0-0.1,0-0.1-0.1v-1.2c0,0,0-0.1,0.1-0.1h15.6c0,0,0.1,0,0.1,0.1L43.9,112.4
	C43.9,112.3,43.9,112.4,43.9,112.4z"/>
            <path className={classes.st7} d="M62,116.9H28.3c0,0-0.1,0-0.1-0.1v-1.2c0,0,0-0.1,0.1-0.1H62c0,0,0.1,0,0.1,0.1L62,116.9
	C62,116.8,62,116.9,62,116.9z"/>
            <path className={classes.st7} d="M54.2,120.3H28.3c0,0-0.1,0-0.1-0.1V119c0,0,0-0.1,0.1-0.1h25.9c0,0,0.1,0,0.1,0.1L54.2,120.3
	C54.3,120.2,54.2,120.3,54.2,120.3z"/>
            <path className={classes.st7} d="M62.6,143.1H13.3c-0.2,0-0.4-0.2-0.4-0.4v-9.4c0-0.2,0.2-0.4,0.4-0.4h49.4c0.2,0,0.4,0.2,0.4,0.4v9.4
	C63,142.9,62.9,143.1,62.6,143.1z"/>
            <rect x="12.8" y="44.7" className={classes.st7} width="12.2" height="12"/>
            <rect x="12.8" y="65.1" className={classes.st7} width="12.2" height="12"/>
            <rect x="12.8" y="89.1" className={classes.st7} width="12.2" height="12"/>
            <rect x="12.8" y="111.9" className={classes.st7} width="12.2" height="12"/>
        </SVG>
    );
};