import React, { FC } from 'react';

export const ScreenRecordingImage: FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg preserveAspectRatio="xMidYMid meet" data-bbox="36.7 1 739.3 578.7" viewBox="0 0 813 647.7" xmlns="http://www.w3.org/2000/svg" data-type="ugc" role="presentation" aria-hidden="true" {...props}>
        <g>
            <path fill="#ffffff" d="M741.6 56v348.8H71V56h670.6z"></path>
            <path fill="#ffffff" d="M741.6 56v348.8H71V56h670.6z"></path>
            <path d="M508.8 568c6.4 10.6-15.8 11.7-15.8 11.7H319.6s-22.2-1.1-15.8-11.7c6.4-10.6 23-31.5 25.7-49.8 2.6-17.8 2.7-22.9 2.7-23.1h148.2c0 .3.1 5.3 2.7 23.1 2.7 18.4 19.3 39.3 25.7 49.8z" fill="#b9c0c9"></path>
            <path d="M776 430.5v46.2c0 10.2-8.2 18.4-18.4 18.4H55.1c-10.2 0-18.4-8.2-18.4-18.4v-46.2H776z" fill="#ecf0f4"></path>
            <path d="M776 45.3v385.3H36.7V45.3c0-10.2 8.2-18.4 18.4-18.4h702.5c10.2 0 18.4 8.2 18.4 18.4zm-34.3 359.6V56H71v348.8h670.7z" opacity=".75" fill="#b9c0c9"></path>
            <path d="M687.1 212.8H126c-1.8 0-3.3-1.5-3.3-3.3V86.8c0-1.8 1.5-3.3 3.3-3.3h561.2c1.8 0 3.3 1.5 3.3 3.3v122.7c-.1 1.8-1.6 3.3-3.4 3.3z" fill="#ecf0f4"></path>
            <path d="M684.9 378.9H457.2c-3.1 0-5.5-2.5-5.5-5.5v-129c0-3.1 2.5-5.5 5.5-5.5h227.6c3.1 0 5.5 2.5 5.5 5.5v129.1c.1 3-2.4 5.4-5.4 5.4z" fill="#ecf0f4"></path>
            <path fill="#ecf0f4" d="M224.5 235.3v24.9H122.7v-24.9h101.8z"></path>
            <path fill="#ecf0f4" d="M343.1 235.3v24.9H241.3v-24.9h101.8z"></path>
            <path fill="#ecf0f4" d="M427.8 283.1v12.5H121.6v-12.5h306.2z"></path>
            <path fill="#ecf0f4" d="M427.8 308.7v12.5H121.6v-12.5h306.2z"></path>
            <path fill="#ecf0f4" d="M427.8 334.2v12.5H121.6v-12.5h306.2z"></path>
            <path fill="#ecf0f4" d="M427.8 359.8v12.5H121.6v-12.5h306.2z"></path>
            <path fill="none" stroke="#ffc500" strokeWidth="2" strokeMiterlimit="10" d="M446.4 41c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40z"></path>
            <path fill="#5e646e" d="M413.1 41a6.7 6.7 0 1 1-13.4 0 6.7 6.7 0 0 1 13.4 0z"></path>
            <path fill="none" stroke="#ffc500" strokeWidth="2" strokeMiterlimit="10" d="M424.1 41c0 9.775-7.925 17.7-17.7 17.7-9.775 0-17.7-7.925-17.7-17.7 0-9.775 7.925-17.7 17.7-17.7 9.775 0 17.7 7.925 17.7 17.7z"></path>
            <path fill="none" stroke="#ffc500" strokeWidth="2" strokeMiterlimit="10" d="M435.1 41c0 15.85-12.85 28.7-28.7 28.7S377.7 56.85 377.7 41s12.85-28.7 28.7-28.7 28.7 12.85 28.7 28.7z"></path>
        </g>
    </svg>
);