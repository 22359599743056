import React, { FC } from 'react';
import { SVG } from "./SVG";

export const Camera: FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <SVG {...props} isIcon>
        <g clipPath="url(#clip0)">
            <path d="M19.2032 5.13983H16.0474V4.80668C16.0474 3.3183 14.8718 2.11034 13.4234 2.11034H8.40447C6.95542 2.11034 5.78044 3.3183 5.78044 4.80668V5.13983H2.62403C1.17498 5.13983 0 6.34719 0 7.83618V17.6233C0 19.1117 1.17498 20.3197 2.62403 20.3197H19.2044C20.6534 20.3197 21.8284 19.1117 21.8284 17.6233V7.83618C21.8272 6.34659 20.6523 5.13983 19.2032 5.13983ZM10.913 17.5226C8.17939 17.5226 5.95654 15.2385 5.95654 12.4295C5.95654 9.62116 8.17939 7.33645 10.913 7.33645C13.6467 7.33645 15.8695 9.62056 15.8695 12.4295C15.8695 15.2385 13.6461 17.5226 10.913 17.5226ZM13.5371 12.4295C13.5371 13.9149 12.3592 15.1259 10.913 15.1259C9.46691 15.1259 8.28901 13.9149 8.28901 12.4295C8.28901 10.9436 9.46691 9.7332 10.913 9.7332C12.3592 9.7332 13.5371 10.9436 13.5371 12.4295Z" fill="#A5ADC2"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="21.8284" height="22.43" fill="white"/>
            </clipPath>
        </defs>
    </SVG>
);