import { FC } from 'react';
import classes from './styles/index.module.scss';
import { Section } from "../../components/Section";
import { FlexBox } from "../../../../../../components/FlexBox";
import { ScreenshotPopup } from "../../components/ScreenshotPopup";
import { PaddingBox } from "../../../../../../components/PaddingBox";
import { ArrowImage } from "../../../../images/ArrowImage";
import { Title } from "../../../../components/Topography/Title";
import { Span } from "../../../../components/Topography/Span";
import { RoundedContainer } from "../../../../components/RoundedContainer";
import { UList } from "../../../../components/Topography/UList";
import { useResizeContext } from "../../../../providers/ResizeProvider/ResizeProvider";
import browserImage from '../../../../images/browserImage.svg';

export const CaptureAScreenshotSection: FC = () => {
    const { innerWidth, isMobile } = useResizeContext();
    const isColumn = innerWidth < 1350;

    return (
        <Section>
            <FlexBox gap='middle' justify='center' align='middle' style={{ flexDirection: isColumn ? "column-reverse" : 'row' }}>
                <PaddingBox large>
                    <FlexBox className={classes.imagesContainer} align='middle'>
                        <ScreenshotPopup className={classes.popup} style={{ position: isMobile ? 'static' : 'absolute'}}/>
                        {!isMobile && (
                            <>
                                <div className={classes.browserImage}>
                                    <img src={browserImage} alt=''/>
                                </div>
                                <ArrowImage className={classes.arrowImage}/>
                            </>
                        )}
                    </FlexBox>
                </PaddingBox>
                <FlexBox column gap='large' align='middle'>
                    <Title level={4} size={isMobile ? 'normal' : 'big'} weight='extraBold'>
                        <FlexBox align='middle' gap='small'>
                            <Span>
                                Capture a
                            </Span>
                            <RoundedContainer>
                                <PaddingBox normalHR mediumVR>
                                    Screenshot
                                </PaddingBox>
                            </RoundedContainer>
                        </FlexBox>
                    </Title>
                    <UList markType='decimal' style={{ maxWidth: '550px'}}>
                        <FlexBox column gap='small'>
                            <li>
                                <Span size='small' weight='regular'>Choose screen capture</Span>
                            </li>
                            <li>
                                <Span size='small' weight='regular'>
                                    Capture part or the entire screen, or a full webpage
                                </Span>
                            </li>
                            <li>
                                <Span size='small' weight='regular'>
                                    Annotate by adding text, color highlight and add shapes like lines,
                                    circles, arrows, and boxes
                                </Span>
                            </li>
                            <li>
                                <Span size='small' weight='regular'>Share with anyone</Span></li>
                        </FlexBox>
                    </UList>
                </FlexBox>
            </FlexBox>
        </Section>
    );
}