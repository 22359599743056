import React, { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import { PaddingBox } from "../PaddingBox";

type TagType = {
    children: ReactNode,
}

export const Tag: FC<TagType> = (
    { children }
) => {
    return (
        <PaddingBox tiny className={classes.tag}>{children}</PaddingBox>
    );
}