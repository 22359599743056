import { useEffect, useState } from "react";

const useDarkMode = (): boolean => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const [isDarkMode, setIsDarkMode] = useState<boolean>(mediaQuery.matches);

    useEffect(() => {
        const handler = (event: MediaQueryListEvent) => setIsDarkMode(event.matches);
        mediaQuery.addEventListener("change", handler);
        return () => mediaQuery.removeEventListener("change", handler);
    }, [mediaQuery]);

    return isDarkMode;
};

export default useDarkMode;