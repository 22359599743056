import { FC } from 'react';
import classes from './styles/index.module.scss';
import { Link, useSearchParams } from "react-router-dom";
import { PaddingBox } from "../../../../../../components/PaddingBox";
import { Span } from "../../../Topography/Span";
import classNames from "classnames";
import { RoutePages, RouteParams } from "../../../../const";

type MenuItemPropsType = {
    url: string;
    title: string;
    onClick?: () => void;
    page: RoutePages | null;
}

export const MenuItem: FC<MenuItemPropsType> = (
    { url, title, onClick, page, }
) => {
    const [searchParams] = useSearchParams();
    const currentPage = searchParams.get(RouteParams.Page) as (RoutePages | null);

    const menuItemClasses = classNames(classes.menuItem, {
        [classes.menuItem_selected]: page === currentPage,
    });

    return (
        <li onClick={onClick}>
            <Link to={url} className={menuItemClasses}>
                <PaddingBox small>
                    <Span size='small' weight='medium'>
                        {title}
                    </Span>
                </PaddingBox>
            </Link>
        </li>
    );
}