import { FC } from 'react';
import classes from './styles/index.module.scss';
import { FlexBox } from "../../../../components/FlexBox";
import { PaddingBox } from "../../../../components/PaddingBox";
import { Logo } from "../Logo";
import { Menu } from "./Menu";
import { Button } from "../Button";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import classNames from "classnames";
import { BrowserIcon } from "../../icons/BrowserIcon";
import { Link } from "react-router-dom";
import { EXTENSION_STORE_URL } from "../../const";

export const Header: FC = () => {
    const { isMobile } = useResizeContext();
    const headerContainerClasses = classNames(classes.headerContainer, {
        [classes.headerContainer_desktop]: !isMobile,
        [classes.headerContainer_mobile]: isMobile,
    });

    return (
        <header className={headerContainerClasses}>
            <PaddingBox middle>
                <FlexBox align='middle' justify='space-between'>
                    {isMobile ? (
                        <>
                            <PaddingBox mediumHR>
                                <Logo/>
                            </PaddingBox>
                            <Menu/>
                        </>
                    ) : (
                        <>
                            <FlexBox gap='small' align='middle' justify='space-between'>
                                <PaddingBox mediumHR>
                                    <Logo/>
                                </PaddingBox>
                                <Menu/>
                            </FlexBox>
                            <Link to={EXTENSION_STORE_URL} target="_blank">
                                <Button>
                                    <BrowserIcon/>
                                    <span>Try for free</span>
                                </Button>
                            </Link>
                        </>
                    )}
                </FlexBox>
            </PaddingBox>
        </header>
    );
}