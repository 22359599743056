import React, { FC } from 'react';
import { SVG } from "./SVG";
import useDarkMode from "../hooks/useDarkMode";

export const MicrophoneOff: FC<React.SVGProps<SVGSVGElement>> = (props) => {
    const isDark = useDarkMode();

    return (
        <SVG {...props} isIcon>
            <path d="M9.5001 11.3844C8.70673 11.3844 8.02778 11.1019 7.4629 10.5373C6.89811 9.97259 6.61569 9.2933 6.61569 8.49998V3.88469C6.61569 3.09138 6.89789 2.41237 7.4629 1.84743C8.02778 1.28261 8.70673 1 9.5001 1C10.2934 1 10.9724 1.28261 11.5373 1.84743C12.1023 2.41227 12.3847 3.09138 12.3847 3.88469V8.49998C12.3847 9.29326 12.1022 9.97259 11.5373 10.5373C10.9725 11.1019 10.2934 11.3844 9.5001 11.3844Z" fill="#EA5252"/>
            <path d="M4.47924 6.94047C4.5931 6.82627 4.72861 6.76915 4.88486 6.76915C5.04096 6.76915 5.17616 6.82627 5.29049 6.94047C5.40456 7.05464 5.46165 7.18983 5.46165 7.34603V8.49994C5.46165 9.61176 5.85688 10.5627 6.64716 11.353C7.43719 12.1432 8.38827 12.5384 9.50012 12.5384C10.6119 12.5384 11.5629 12.1432 12.3532 11.353C13.1435 10.5629 13.5385 9.61179 13.5385 8.49994V7.34603C13.5385 7.18983 13.5957 7.05464 13.7098 6.94047C13.824 6.82627 13.9591 6.76915 14.1154 6.76915C14.2718 6.76915 14.407 6.82627 14.5211 6.94047C14.6354 7.05464 14.6924 7.18983 14.6924 7.34603V8.49994C14.6924 9.82807 14.2492 10.9835 13.3628 11.9659C12.4765 12.9484 11.3812 13.5118 10.0771 13.6559V14.8459H12.3847C12.541 14.8459 12.6762 14.9031 12.7903 15.0173C12.9045 15.1314 12.9617 15.2667 12.9617 15.4229C12.9617 15.579 12.9045 15.7145 12.7903 15.8286C12.6762 15.9427 12.541 16 12.3847 16H6.61568C6.45946 16 6.32407 15.9427 6.21006 15.8286C6.09573 15.7145 6.03858 15.579 6.03858 15.4229C6.03858 15.2667 6.09573 15.1315 6.21006 15.0173C6.32404 14.9031 6.45942 14.8459 6.61568 14.8459H8.92306V13.6559C7.61911 13.5118 6.52396 12.9484 5.63751 11.9659C4.75106 10.9835 4.30764 9.82807 4.30764 8.49994V7.34603C4.30764 7.18986 4.36488 7.05476 4.47924 6.94047Z" fill="#EA5252"/>
            <rect x="1.29289" y="14.8492" width="19.7595" height="2" rx="1" transform="rotate(-45 1.29289 14.8492)" fill="#EA5252" stroke={isDark ? '#202124' : 'white'} />
        </SVG>
    );
};