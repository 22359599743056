import { RoutePages, RouteParams, RouteUrls } from "../../../../const";

export const menuItems: MenuItemType[] = [
    {
        title: "Home",
        url: RouteUrls.Home,
        p: null,
    },
    {
        title: "How to use",
        url: RouteUrls.HowToUse,
        p: RoutePages.HowToUse,
    },
    {
        title: "About us",
        url: RouteUrls.AboutUs,
        p: RoutePages.AboutUs,
    }
];

export type MenuItemType = {
    title: string;
    url: string;
    [RouteParams.Page]: RoutePages | null;
};