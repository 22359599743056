import React, { FC, ReactNode, useEffect, useState } from 'react';
import { ScrollContext } from './const';
import { ScrollStateValueType } from "./types";

type ScrollProviderPropsType = {
    children: ReactNode;
}

export const ScrollProvider: FC<ScrollProviderPropsType> = ({ children }) => {
    const [value, setValue] = useState<ScrollStateValueType>({ scrollTop: 0, clientHeight: 0, clientWidth: 0 });
    useEffect(() => {
        document.addEventListener('scroll', () => {
            const { scrollTop, clientHeight, clientWidth } = (document.documentElement ?? document.body.parentNode ?? document.body);
            setValue({ scrollTop, clientHeight, clientWidth });
        });
    }, []);
    return (
        <ScrollContext.Provider value={value}>
            {children}
        </ScrollContext.Provider>
    );
}