import { StorageKey } from "../StorageKey";
import {
    CroppingDataType,
    PopupDataType,
    RecordDataType,
    StorageRecordsType,
    StorageTabsType,
    StorageTabType,
} from "./types";
import { isArray } from "lodash";
import moment from "moment";
import { IRecordVideoProps } from "../../classes/RecordVideo/types";
import { getAllScreenshots } from "../../classes/Screenshot/utils";

export const storage = {
    getTabsData: () => new Promise<StorageTabsType>(resolve => {
        chrome.storage.local.get(StorageKey.Tabs, res => {
            const tabs: StorageTabsType = res[StorageKey.Tabs] ?? {};
            resolve(tabs);
        });
    }),
    getTabData: (tabId?: number) => storage.getTabsData().then(tabs => new Promise<StorageTabType>(resolve => {
        const getTabDataById = (id: number) => new Promise<StorageTabType>(resolve => {
            const tab: StorageTabType | null = tabs[id] ?? {};
            resolve(tab);
        });
        if(!chrome.tabs && !tabId) {
            throw new Error('You should pass tabId in content script!');
        }
        if(tabId) {
            getTabDataById(tabId).then(resolve);
        }
        else {
            chrome.tabs.query({ active: true }, ([{ id }]) => {
                getTabDataById(id ?? 0).then(resolve);
            });
        }
    })),
    setTabProps: (props: Partial<StorageTabType>, tabId?: number) => new Promise(resolve => {
        const setPropsById = (id: number) => {
            return storage.getTabsData().then(tabs => {
                const tab = tabs[id];
                chrome.storage.local.set({
                    [StorageKey.Tabs]: {
                        ...tabs,
                        [id]: {
                            ...tab,
                            ...props,
                        },
                    },
                });
            });
        };
        if(!chrome.tabs && !tabId) {
            throw new Error('You should pass tabId in content script!');
        }
        if(tabId) {
            setPropsById(tabId).then(resolve);
        }
        else {
            chrome.tabs.query({ active: true }, ([{ id }]) => {
                setPropsById(id ?? 0).then(resolve);
            });
        }
    }),
    removeTabProps: (props?: (keyof StorageTabType)[] | keyof StorageTabType, tabId?: number) => new Promise(resolve => {
        const removeTabPropsById = (id: number) => {
            return storage.getTabsData().then(tabs => {
                const tab = tabs[id];
                if (!tab) return;
                let newTabs = tabs;
                if(!props) {
                    delete newTabs[id];
                }
                else {
                    const newProps: (keyof StorageTabType)[] = [];
                    if(!isArray(props)) {
                        newProps.push(props);
                    }
                    else {
                        newProps.push(...props);
                    }
                    newProps.forEach(prop => {
                        delete tab[prop];
                    });
                    newTabs = {
                        ...newTabs,
                        [id]: tab,
                    };
                }
                return chrome.storage.local.set({
                    [StorageKey.Tabs]: newTabs,
                });
            });
        }
        if(!chrome.tabs && !tabId) {
            throw new Error('You should pass tabId in content script!');
        }
        if(tabId) {
            removeTabPropsById(tabId).then(resolve);
        }
        else {
            chrome.tabs.query({ active: true }, ([{ id }]) => {
                removeTabPropsById(id ?? 0).then(resolve);
            });
        }
    }),
    removeTabData: (tabId?: number) => new Promise(resolve => {
        const removeTabDataById = (id: number) => {
            return storage.getTabsData().then(tabs => {
                delete tabs[id];
                chrome.storage.local.set({ [StorageKey.Tabs]: tabs });
            });
        };
        if(!chrome.tabs && !tabId) {
            throw new Error('You should pass tabId in content script!');
        }
        if(tabId) {
            removeTabDataById(tabId).then(resolve);
        }
        else {
            chrome.tabs.query({ active: true }, ([{ id }]) => {
                removeTabDataById(id ?? 0).then(resolve);
            });
        }
    }),
    setDefaultTabData: async (tabId?: number) => {
        await storage.removeCropping(tabId);
    },
    removeCropping: (tabId?: number) => storage.removeTabProps([
        'croppingData', 'isCroppingCanBeSaved', 'isCropping'
    ], tabId),
    setCroppingData: (data: CroppingDataType, tabId?: number) => storage.setTabProps({
        croppingData: data,
        isCroppingCanBeSaved: true,
    }, tabId),
    getPopupData: () => new Promise<PopupDataType>(resolve => {
        chrome.storage.local.get(StorageKey.PopupData, res => {
            const popup: PopupDataType = res[StorageKey.PopupData] ?? {};
            resolve(popup);
        });
    }),
    setPopupData: (data: PopupDataType) => storage.getPopupData().then(res => {
        return chrome.storage.local.set({
            [StorageKey.PopupData]: {
                ...res,
                ...data
            }
        });
    }),
    getRecordData: () => new Promise<RecordDataType>(resolve => {
        chrome.storage.local.get(StorageKey.RecordData, res => {
            const recordData: RecordDataType = res[StorageKey.RecordData] ?? {};
            resolve(recordData);
        });
    }),
    setRecordData: (data: RecordDataType) => storage.getRecordData().then(res => {
        return chrome.storage.local.set({
            [StorageKey.RecordData]: {
                ...res,
                ...data
            }
        });
    }),
    getRecords: () => new Promise<StorageRecordsType>(resolve => {
        chrome.storage.local.get(StorageKey.Records, res => {
            const recordsStorage: StorageRecordsType = res[StorageKey.Records] ?? {};
            resolve(recordsStorage);
        });
    }),
    getRecordById: (id: number) => storage.getRecords().then((records) => new Promise<IRecordVideoProps>((resolve, reject) => {
        if(records[id]){
            resolve(records[id]);
        }
        else {
            reject(new Error("The record wasn't found!"));
        }
    })),
    addRecord: async (record: Omit<IRecordVideoProps, 'id' | 'title'> & { id?: number }) => {
        const id = record.id ?? Date.now();
        const records = await storage.getRecords();
        const newRecords: StorageRecordsType = {
            ...records,
            [id]: {
                ...record,
                id,
                title: moment(id).format("DD.MM.YYYY HH:mm"),
            },
        };
        await chrome.storage.local.set({
            [StorageKey.Records]: newRecords,
        });
        return id;
    },
    removeRecordById: async (id: number) => {
        const records = await storage.getRecords();
        delete records[id];
        await chrome.storage.local.set({ [StorageKey.Records]: { ...records } });
    },
    changeUploadedRecord: async (id: number, url?: string) => {
        const records = await storage.getRecords();
        const newRecord = { ...records[id], isUploaded: !!url, url };
        await chrome.storage.local.set({
            [StorageKey.Records]: {
                ...records,
                [id]: newRecord,
            },
        });
    },
    changeUploadedScreenshot: async (id: number, url?: string) => {
        const screenshots = await getAllScreenshots();
        const newScreenshot = { ...screenshots[id], _isUploaded: !!url, _url: url };
        await chrome.storage.local.set({
            [StorageKey.Screenshots]: {
                ...screenshots,
                [id]: newScreenshot,
            },
        });
    },
    removeAllRecords: async () => {
        await chrome.storage.local.remove(StorageKey.Records);
    },
    addTabWithCamera: async (id: number) => {
        const recordData = await storage.getRecordData();
        const newRecordData: RecordDataType = {
            ...recordData,
            tabsWithCamera: [...(recordData.tabsWithCamera ?? []), id],
        };
        return await storage.setRecordData(newRecordData);
    },
    removeTabWithCamera: async (id: number) => {
        const recordData = await storage.getRecordData();
        const newRecordData: RecordDataType = {
            ...recordData,
            tabsWithCamera: (recordData.tabsWithCamera ?? []).filter(value => value !== id),
        };
        return await storage.setRecordData(newRecordData);
    },
    removeTabsWithCamera: async () => {
        const recordData = await storage.getRecordData();
        recordData.tabsWithCamera = [];
        return await storage.setRecordData(recordData);
    }
}