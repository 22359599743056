export const CONTENT_NODE_ID = 'SCREENSHOT_MASTER_ID';
export const CONTENT_ROUNDED_CAMERA_ID = 'SCREENSHOT_MASTER_ROUNDED_CAMERA_ID';
export enum AlarmName {
    RecordDelay = 'recordDelay',
    BudgeTime = 'budgeTime',
}
export enum RecordControl {
    Pause = 'pause',
    Stop = 'stop',
    Continue = 'continue',
}