import React, { FC } from 'react';
import { WebsiteRoutes } from "./WebsiteRoutes";
import { Providers } from "../../providers";
import { Header } from "./components/Header";
import { ResizeProvider } from "./providers/ResizeProvider";
import { ScrollProvider } from "./providers/ScrollProvider";
import { Footer } from "./components/Footer";

export const Website: FC = () => {
    return (
        <Providers>
            <ResizeProvider>
                <ScrollProvider>
                    <Header/>
                    <WebsiteRoutes/>
                    <Footer/>
                </ScrollProvider>
            </ResizeProvider>
        </Providers>
    );
}