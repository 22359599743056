import React, { FC, useState } from 'react';
import classes from './styles/index.module.scss';
import { Segmented, Switch, Typography } from "antd";
import { PopupSegmentedOptionValue } from "../../../../../Popup/const";
import { FlexBox } from "../../../../../../components/FlexBox";
import { Camera, MicrophoneOff, MicrophoneOn, Record, Story } from "../../../../../../icons";
import { PaddingBox } from "../../../../../../components/PaddingBox";
import { BigButton } from "../../../../../../components/BigButton";
import { Tag } from "../../../../../../components/Tag";
import { isMac, isWindows } from "../../../../../../utils";
import classNames from "classnames";
import { DesktopCat } from "../../../../../../icons/cats/DesktopCat";
import { WebcamCat } from "../../../../../../icons/cats/WebcamCat";
import { BothCat } from "../../../../../../icons/cats/BothCat";

type ScreenRecordPopupPropsType = {} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const { Title, Text } = Typography;

export const ScreenRecordPopup: FC<ScreenRecordPopupPropsType> = ({ className, ...props }) => {
    const ctrl = isMac() ? "Command" : isWindows() ? "Ctrl" : "";
    const containerClasses = classNames(className, classes.container);
    const [isMicrophone, setIsMicrophone] = useState(false);

    const segmentedOptions = [
        {
            label: (
                <FlexBox justify='center' align='middle'>
                    <Camera />
                </FlexBox>
            ),
            value: PopupSegmentedOptionValue.Camera,
        },
        {
            label: (
                <FlexBox justify='center' align='middle'>
                    <Record />
                </FlexBox>
            ),
            value: PopupSegmentedOptionValue.Record,
        },
        {
            label: (
                <FlexBox justify='center' align='middle'>
                    <Story />
                </FlexBox>
            ),
            value: PopupSegmentedOptionValue.Story,
        },
    ];
    return (
        <div className={containerClasses} {...props}>
            <Segmented block options={segmentedOptions} onChange={() => {}} value={PopupSegmentedOptionValue.Record} />

            <PaddingBox mediumHR mediumBottom>
                <FlexBox column gap='medium'>
                    <div>
                        <Title level={4}>Capture a screen record</Title>
                        <Text type="secondary">Choose the type of screen video</Text>
                    </div>
                    <FlexBox gap='small'>
                        <BigButton className={classes.desktopButton}>
                            <FlexBox align='middle' justify='center' column gap='small'>
                                <DesktopCat/>
                                <Text>Desktop</Text>
                                <FlexBox gap='tiny'>
                                    <Tag>{ctrl}</Tag>
                                    <Tag>Shift</Tag>
                                    <Tag>V</Tag>
                                </FlexBox>
                            </FlexBox>
                        </BigButton>
                        <FlexBox gap='tiny' column>
                            <BigButton className={classes.webcamButton}>
                                <FlexBox align='middle' justify='center' column gap='small'>
                                    <WebcamCat/>
                                    <Text>Webcam</Text>
                                </FlexBox>
                            </BigButton>
                            <BigButton
                                className={classes.bothButton}
                            >
                                <FlexBox align='middle' justify='center' column gap='small'>
                                    <BothCat/>
                                    <Text>Both</Text>
                                </FlexBox>
                            </BigButton>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox>
                        {isMicrophone ? (
                            <MicrophoneOn/>
                        ) : (
                            <MicrophoneOff/>
                        )}
                        <Switch size='small' onChange={setIsMicrophone} checked={isMicrophone}/>
                    </FlexBox>
                </FlexBox>
            </PaddingBox>
        </div>
    );
}