import { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import { MainCatImage } from "../../icons/MainCatImage";
import { FlexBox } from "../../../../components/FlexBox";
import { useScrollContext } from "../../providers/ScrollProvider/useScrollContext";
import { PaddingBox } from "../../../../components/PaddingBox";

type WelcomeComponentPropsType = {
    content?: ReactNode;
}

export const WelcomeComponent: FC<WelcomeComponentPropsType> = (
    { content = 'Screeny' }
) => {
    const { scrollTop, clientHeight } = useScrollContext();
    const imageContainerStyles: React.CSSProperties = {
        transform: `scale(${1 + (scrollTop / clientHeight)})`,
    };

    return (
        <>
            <FlexBox style={{ height: '100vh', zIndex: -1, pointerEvents: 'none' }}/>
            <FlexBox className={classes.welcomeComponent} justify='end' align='middle' column>
                <div className={classes.imgContainer} style={imageContainerStyles}>
                    <MainCatImage/>
                </div>
                <div className={classes.gradient}/>
                <PaddingBox large>
                    <FlexBox column justify='space-evenly' align='middle' style={{ height: '550px' }}>
                        {content}
                    </FlexBox>
                </PaddingBox>
            </FlexBox>
        </>
    );
}