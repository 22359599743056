import React, { FC, useCallback, useRef } from 'react';
import { getPageTitle } from "../../utils/getPageTitle";
import { WelcomeComponent } from "../../components/WelcomeComponent";
import { FlexBox } from "../../../../components/FlexBox";
import { Title } from "../../components/Topography/Title";
import { Button } from "../../components/Button";
import { ArrowRight } from "../../icons/ArrowRight";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import { Section } from "../Main/components/Section";
import { Span } from "../../components/Topography/Span";
import { Head } from "../../components/Head";

const title = 'Privacy Policy';
const description = 'We are proud to inform that our extension not tracks users’ browsing activities...';

export const PrivacyPolicy: FC = () => {
    const { isMobile } = useResizeContext();
    const nodeRef = useRef<HTMLDivElement>(null);

    const onContinueClick = useCallback(() => {
        window.scroll({ top: (nodeRef.current?.getBoundingClientRect().top ?? 0) - (isMobile ? 72 : 104) });
    }, [isMobile]);

    return (
        <>
            <Head title={getPageTitle(title)} description={description}/>
            <WelcomeComponent
                content={(
                    <FlexBox column align='middle' justify={isMobile ? 'end' : 'space-evenly'} gap='large' style={{ height: '100%', maxWidth: '1000px' }}>
                        <FlexBox column align='middle' gap='large'>
                            <Title level={2} size={isMobile ? "big" : 'extraLarge'} weight="extraBold" style={{ textAlign: 'center' }}>
                                {title}
                            </Title>
                        </FlexBox>
                        <Button onClick={onContinueClick}>
                            <span>Continue</span>
                            <ArrowRight style={{ transform: 'rotate(90deg)' }}/>
                        </Button>
                    </FlexBox>
                )}
            />
            <div ref={nodeRef}>
                <Section>
                    <FlexBox column align='middle'>
                        <FlexBox style={{ maxWidth: '744px' }} gap='extraLarge' column>
                            <FlexBox gap='middle' column>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    We are proud to inform that our extension not tracks users’ browsing activities or other personal information. We don’t use any analytics service or other similar tool to measure user interactions, item usage etc. We neither collect any kind of personal or otherwise information from the installation of our item. Privacy of our users is our top priority.
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <Title level={3} size='normal' weight='bold'>
                                    Disclosure
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    Screenshot Master & Screen Recorder browser extension use of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <Title level={3} size='normal' weight='bold'>
                                    User Activity
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    We do not monitor user activity.
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <Title level={3} size='normal' weight='bold'>
                                    Permissions and Security
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    The permissions You agree to when installing the extensions are the minimum requirements for the extensions to perform their basic purpose. Optional permissions which you may grant after installing the extensions give you more control and safety.
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <Title level={3} size='normal' weight='bold'>
                                    Collecting, storing & sharing user data​
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    This extension use local storage to store the history of captured screenshots. We don't collect or share any user data with third parties.
                                </Span>
                            </FlexBox>
                            <FlexBox gap='middle' column>
                                <Title level={3} size='normal' weight='bold'>
                                    How to delete all my user data related to the extension?
                                </Title>
                                <Span size='small' weight='regular' style={{ lineHeight: '150%' }}>
                                    Simply uninstall the extension normally and all local user data will be erased.
                                </Span>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Section>
            </div>
        </>
    );
}