import React, { FC } from 'react';
import { Helmet } from "react-helmet";
import image from './128.png';
import { useSearchParams } from "react-router-dom";
import { RoutePages, RouteParams, SITE_URL } from "../../const";

type HeadPropsType = {
    title: string;
    description: string;
}

export const Head: FC<HeadPropsType> = (
    { title, description }
) => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get(RouteParams.Page) as RoutePages;
    const url = `${SITE_URL}${page ? `?p=${page}`: '/'}`;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta itemProp="name" content={title}/>
            <meta itemProp="description" content={description}/>
            <meta itemProp="image" content={image}/>
            <meta property="og:url" content={url}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={image}/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={image}/>
        </Helmet>
    );
}