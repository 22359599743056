import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { SVG } from "../../SVG";

export const BothCat: FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <SVG {...props} height='70px' width='93px' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 93 70" xmlSpace="preserve">
            <g>
                <defs>
                    <rect id="SVGID_B1_" x="8" y="2.6" width="84" height="67"/>
                </defs>
                <clipPath id="SVGID_B2_">
                    <use xlinkHref="#SVGID_B1_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st0}>
                    <path className={classes.st1}
                          d="M27.5,45l-2.4-11.4l-2.9,1L19,36.6l0.1,0.8l1.5,4.6l0.4,1.2l-2.3,0.6l-1.1,0.7l3.9,7.2l1.3-0.8L27.5,45z"/>
                    <path className={classes.st2} d="M58.6,13.2c-0.5-0.9-0.6-0.5,0.4-2.7C59.4,9.7,60.7,5.6,60,5c-1.9-0.6-5.8,1.4-6.6,1.2
			c-8.2-5.7-17.5-3.6-20.6-1c-1.1-0.7-3.3-1.9-4.6-1.8c-1.4,2.4-0.4,5.5-0.7,6.9c-3.9,5.3-4.1,17.3-4.1,23.9
			c0.1,6.8,0.1,14.4,6.2,18.4c-0.2,0.4-0.5,0.7-0.5,0.8c-0.3,0.7,0.6,1.3,0.9,0.7c0.1-0.2,0.4-0.7,0.8-0.8c0.1,0.1,0.2,0.1,0.4,0.2
			c-0.1,0.3-0.3,0.6-0.4,0.9c-0.2,0.9,0.7,0.9,0.8,0.7c0.2-0.4,0.5-0.7,0.7-1c0.1,0.1,0.3,0.1,0.5,0.2c0,0.2-0.1,0.5-0.2,0.9
			c-0.2,0.7,0.4,1.2,0.9,0.5c0.2-0.3,0.4-0.6,0.5-0.9c0.2,0.1,0.4,0.1,0.6,0.2c0,0.4-0.1,0.8-0.2,1c-0.1,0.4,0.5,0.7,0.9,0.5
			c0,0,0.6-0.9,0.6-0.9c2.3,0.5,5.8,0.8,9.6,0.6c0,0.4,0.1,0.7,0.2,0.8c0.2,0.7,0.8,0.6,1-0.1c0-0.2,0-0.5,0-0.8
			c0.3,0,0.5,0,0.8-0.1c0.1,0.4,0.1,0.8,0.1,0.9c0,0.5,1.1,0.7,1.3-0.1c0-0.2-0.1-0.6-0.1-1c0.2,0,0.5-0.1,0.7-0.1
			c0.2,0.4,0.2,0.8,0.2,1c0.1,0.4,1,0.7,1.1-0.1l0.1-1.1c0.1,0,0.2,0,0.4-0.1c0.1,0.3,0.2,0.6,0.3,1c0.1,0.5,1.2,1,1.1-0.2
			c0-0.3-0.1-0.6-0.2-1c0.5-0.1,1.4,0,2.1-0.3c0.6,4.2-5.6,4.8-8.6,5c-3.9,0.3-7.5-1.7-11.8-1.8c-11-0.3-12.9,9.6-0.8,10.9
			c2.4,0.3,7.5,0.8,12.6,0.2C58,67.9,70.4,55.8,59.4,48.8c1.6-2.8-0.9-5.7,0.9-9.8c0.1-0.7-0.1-0.5-0.3-0.7
			C61.4,33.3,61.3,18,58.6,13.2z"/>
                    <path className={classes.st3} d="M62.5,24c1.2,2.4-0.7,10.1-3.7,13.1c-0.5,0.4-0.3-0.2-1.1-0.1c-0.7,0.1-6.6,3-8,3.7c-1.9,1-1.3,0.9-2,1.3
			c-1.3,0.2-6.9-1.2-7.6-1.9c0.5-0.5,2.1-1.2,2.8-1.6c0.4-0.2,0.6-0.5,0.9-0.6c0.8,0,5.1,0.2,7.1-0.2c0.1-1.1,0-2.8,0.1-4.2
			C60.4,28.4,62.1,26.2,62.5,24z M22.9,23l1-0.5l-0.1,1.4c0.3,0.3,0.6,0.5,0.9,0.8c2.7,2.2,7.1,3.2,10.2,3.2c0-0.9,0.8-0.9,2-0.9
			c2.7-0.2,6.2-0.2,8.8-0.2c0.3-0.6-0.4-0.9,1.1-1c1.4,0,0.8,0.6,1.1,1.2c1.7,0.1,3.1-0.2,3.1,0.5c1.7-0.1,4.9-1.9,7.2-4.2
			c2.1-2.1,3.5-5.7,1.4-8c1.5-0.1,2,0.5,2.5,1.6c1.9,1.2,1.6,4.6,1,5.9c2.5,6.4-1.9,14-3.1,15.5c-0.8,0.2-8.5,4.1-9.3,4.7v6.1
			c-0.5,0-2.1-0.4-2.6-0.6c-0.3-0.7-0.1-2.9-0.3-4.9c-2.4-0.2-7.6-1.9-8.9-2.8c-0.2,0-4,2.6-4.1,2.7c0.4,0.9,1.5,2.3,0.5,3.4
			c-0.3,0.3-1.5,1.6-2.3,2.1c-0.3-0.7-1.1-2.3-1.5-3.1c-0.5-0.9-1.3-2-1.6-2.9c1.1-0.8,2-2.1,2.8-2.9l1,1.9c1.1-0.1,2.7-1.2,3.4-2
			c-2.3,0-13.9-7.3-14.6-15.2c-2.6,1.7-1.3,8.8-0.9,9.6l1.3-0.8c1.4,0.7,9.1,5,9.2,5.9c0,0.4-2.2,2.5-2.6,2.9
			c-2.1-0.9-8.7-5.1-10.5-6c0.5-0.5,1.1-0.8,1.4-1.1c-0.3-2.8-1.7-7.9,1.4-11.6c-1.1-1.4-2.8-6.9,3.1-8C24.1,16.5,19.8,19.1,22.9,23
			z M34.8,29.4c0.1,1.2-0.2,6.6,0.2,8.3c1.6,0.3,4.5,0.2,5.9,0.2c-0.7,0.6-1,1.1-2.7,1.5c-6.1-0.9-15.2-10.3-14.6-14.3
			C26.1,27.4,28.6,28.7,34.8,29.4z M51.1,29.5c2.8-1.1,9-4.3,10.9-10.1c1.2,7.4-8.6,11.1-11,12.3L51.1,29.5z"/>
                    <path className={classes.st3} d="M66.3,52.6l0.6,1.1c5.9,2.7,10.7,4.3,16.5,6.6c4.8,2,9.2-10.4,8.5-13.9c0-1.3,0-1.8-1.1-2.2
			c-3.4-1-13.8-2.2-17.9-2.9c-1.1,0-1.4,0.3-2.1,0.8C66.7,44.8,66.4,52.6,66.3,52.6z"/>
                    <path className={classes.st3} d="M15.2,57.4c0.8-0.3,6.8-4.6,6.9-4.7c1.2-1.3-2-8.5-4.8-9.2c-0.5-0.1-0.9-0.1-1.2,0c-2.4,0.8-5.6,1.7-7.7,2.8
			C6.3,47.6,12.6,58.2,15.2,57.4z"/>
                    <path className={classes.st3} d="M60.2,49.1c0-0.6,0.6-4.1,0.7-4.4l1.7-2.2c0.4-0.4,0.3-0.1,0.7-0.6L60,41.4l0.3-2.5c-2.8,1.4-5.8,3-8.5,4.4
			c-0.2,0.1-0.4,0.3-0.4,0.5c0,0.8-0.1,2.3,0.1,5.4c1.7-0.8,2.8-1.5,4-1.9C56.8,47.8,58.6,48.7,60.2,49.1z"/>
                    <path className={classes.st3} d="M60.8,49.5c-0.1,0.3,0.5,0.6,0.9,0.8c1.3,0.7,3.1,1.7,4.6,2.2c0-2.4,0.2-4.3,1.2-6.2c0.3-0.6,0.9-1.4,1.4-1.9
			c0.5-0.6,1.3-1.5,1.8-2.2c-2.6-0.4-5.9-0.8-6.7-0.6l-0.5,0.3C61.9,42.9,60.5,45.4,60.8,49.5z"/>
                    <path className={classes.st4}
                          d="M91.2,46.9c-0.1-1.6-0.8-2.3-2.1-1.1c-2.1,2-4.7,8-4.8,11c0,2,0.7,2.8,1.5,2.3C88.7,57.4,91.4,50,91.2,46.9z"
                    />
                    <path className={classes.st2} d="M42.1,36.9c3.2,0,5.5-0.9,5.3-4.9c-0.1-2.3-1.5-4.2-5.2-4.1c-4.1,0.4-4.1,2.9-4.1,4.9
			C38.1,34.5,39.6,36.9,42.1,36.9z"/>
                    <path className={classes.st3} d="M18.8,43.8c1.5,0.7,4,4.4,4.1,7.1c1-0.5,3.7-2.1,4.5-2.6c0.4-2.1-1.4-6.2-4.2-6.5
			C22.1,41.7,19.8,42.9,18.8,43.8z"/>
                    <path className={classes.st5} d="M46.7,23.6c-1.5,0-3.7-1-3.5-2.9c0.5-0.7,3.5-2.6-1.1-2.6c-1.8,0-2.1,1.2-0.1,2.4c0.1,2.6-1.1,3.1-2.2,3.3
			c-1,0.2-0.9,0.7-0.7,0.9c0.2,0.2,0.8,0.1,1.3-0.1c0.7-0.3,1.9-0.7,2.5-1.9c1.1,1.5,4.9,2.9,6.8,0.6c0.2-0.3,0.6-0.9,0.7-1.5
			c0.2-1.1-1-1.3-1.3-0.3C48.8,22.3,48.6,23.6,46.7,23.6z"/>
                    <path className={classes.st3} d="M23.4,41.2c2.1,0.4,4.8,3.5,4.9,6.9c1.1,0.6,1.4,0.7,3.8,1.5c-0.3-0.9-2.5-5.1-3.1-6.1
			c-0.5-0.7-9.1-6.1-9.9-6.3c0.2,0.9,1.1,3.8,1.5,4.8C21.4,41.5,22.3,41,23.4,41.2z"/>
                    <path className={classes.st1} d="M66.9,53.7c-0.5-1.1,0-5.4,1.5-7.9c1-1.7,2.6-4.1,4.5-4.4c-2-0.4-4.1,1.8-5.2,4.1
			C66.5,47.6,65.2,52.6,66.9,53.7z"/>
                    <path className={classes.st1}
                          d="M60.2,49.1c0.1,0.2,0.3,0.4,0.6,0.4c-0.2-5.4,3.1-7.8,3.1-7.9l-0.6,0.3C61.4,42.9,59.9,45,60.2,49.1z"/>
                    <path className={classes.st1} d="M60,41.4c-3.4-0.9-6.3,3.9-4.6,6.4C55,44.8,56.6,41.8,60,41.4z"/>
                    <path className={classes.st5}
                          d="M29.7,39.8c0.1-0.8-0.2-1.2-0.7-1.4c-0.8-0.2-1.1,0.1-1.4,0.9C27.2,41.1,29.5,41.2,29.7,39.8z"/>
                    <path className={classes.st5}
                          d="M26,38.6c0.4-0.5,0.3-1.2-0.6-1.1c-0.6,0.1-0.8,0.4-0.6,1C25,39.2,25.6,39,26,38.6z"/>
                    <path className={classes.st5}
                          d="M23.5,36.6c0.4,0,0.6-0.1,0.7-0.5c0.1-0.7-0.8-0.7-1.1-0.5C22.6,35.9,22.7,36.5,23.5,36.6z"/>
                    <path className={classes.st5} d="M39.2,32.9c0.5,1.7,0.9,2.7,2.3,3c0.6,0.1,1.6,0.3,2.5-0.1c1.7-0.6,2.9-2.1,2-4.8c-1-2.2-3.1-2.5-4.6-2.1
			C40.2,29.3,38.7,30.9,39.2,32.9z"/>
                    <path className={classes.st2}
                          d="M9,47c-1.3-0.2,0,3.3,2,6.1c0.6,0.9,1.4,1.7,2.1,2.3c2.6,2.3,2.3-0.1,1.4-2.1C13.6,51.5,11.3,47.4,9,47z"/>
                    <path className={classes.st2} d="M40.8,31.5c1.4,0.3,2.1-1,1.3-1.5C41.3,29.5,40.3,30.5,40.8,31.5z"/>
                    <path className={classes.st6} d="M37.6,15.5c0,1.6-1.3,2.9-2.8,2.9c-1.6,0-2.8-1.3-2.8-2.9c0-1.6,1.3-2.9,2.8-2.9
			C36.3,12.6,37.6,13.9,37.6,15.5z"/>
                    <path className={classes.st7}
                          d="M36.7,15.6c0,1-0.8,1.9-1.8,1.9s-1.8-0.8-1.8-1.9c0-1,0.8-1.9,1.8-1.9C35.9,13.8,36.7,14.6,36.7,15.6z"/>
                    <path className={classes.st8} d="M36.2,15.4c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4C36,14.9,36.2,15.1,36.2,15.4
			z"/>
                    <path className={classes.st6} d="M52.3,15.5c0,1.6-1.3,2.9-2.8,2.9c-1.6,0-2.8-1.3-2.8-2.9c0-1.6,1.3-2.9,2.8-2.9C51,12.6,52.3,13.9,52.3,15.5
			z"/>
                    <path className={classes.st7}
                          d="M51.4,15.6c0,1-0.8,1.9-1.8,1.9s-1.8-0.8-1.8-1.9c0-1,0.8-1.9,1.8-1.9S51.4,14.6,51.4,15.6z"/>
                    <path className={classes.st8} d="M49.7,15.4c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
			C49.5,14.9,49.7,15.1,49.7,15.4z"/>
                    <g>
                        <defs>

                            <rect id="SVGID_B3_" x="70.1" y="16.8"
                                  transform="matrix(0.2588 -0.9659 0.9659 0.2588 33.6487 96.3615)" width="19"
                                  height="19"/>
                        </defs>
                        <clipPath id="SVGID_B4_">
                            <use xlinkHref="#SVGID_B3_" style={{overflow: 'visible'}}/>
                        </clipPath>
                        <g className={classes.st9}>
                            <path className={classes.st10} d="M86.2,31.5l0.2-0.7l1.1-4.1l1.1-4.1c0.1-0.4-0.1-0.8-0.5-0.9l-13.6-3.6c-0.4-0.1-0.8,0.1-0.9,0.5l-1.1,4.1
					l-1.1,4.1l-0.2,0.7L69,29.1c-0.2,0.6,0.2,1.1,0.7,1.3l16.3,4.4c0.6,0.2,1.1-0.2,1.3-0.7L86.2,31.5z M79.4,32.6l-2.7-0.7
					c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.2-0.1l2.7,0.7c0.1,0,0.1,0.1,0.1,0.2C79.6,32.5,79.5,32.6,79.4,32.6z M75.4,30.8
					l0.7-0.5l4.6,1.2l0.4,0.8L75.4,30.8z M85.6,30c-0.1,0.3-0.4,0.5-0.8,0.4L73,27.3c-0.3-0.1-0.5-0.4-0.4-0.8l1.9-7.1
					c0.1-0.3,0.4-0.5,0.8-0.4l11.9,3.2c0.3,0.1,0.5,0.4,0.4,0.8C87.5,22.9,85.6,30,85.6,30z"/>
                        </g>
                    </g>
                    <g>
                        <defs>
                            <rect id="SVGID_B5_" x="78.4" y="6" transform="matrix(0.5 -0.866 0.866 0.5 31.6144 79.7045)"
                                  width="13" height="13"/>
                        </defs>
                        <clipPath id="SVGID_B6_">
                            <use xlinkHref="#SVGID_B5_" style={{overflow: 'visible'}}/>
                        </clipPath>
                        <g className={classes.st11}>
                            <path className={classes.st10} d="M88.3,17.1l0.2-0.4l1.4-2.5l1.4-2.5c0.1-0.2,0.1-0.5-0.2-0.7l-8.3-4.8c-0.2-0.1-0.5-0.1-0.7,0.2l-1.4,2.5
					l-1.4,2.5l-0.2,0.4l-1.8,0.6c-0.2,0.3-0.1,0.8,0.3,1l10,5.8c0.3,0.2,0.8,0.1,1-0.3L88.3,17.1z M83.6,16.6l-1.7-1
					c-0.1,0-0.1-0.1,0-0.2c0-0.1,0.1-0.1,0.2,0l1.7,1c0.1,0,0.1,0.1,0,0.2C83.7,16.6,83.6,16.6,83.6,16.6z M81.3,14.7l0.5-0.2
					l2.8,1.6l0.1,0.6L81.3,14.7z M88.1,16c-0.1,0.2-0.4,0.3-0.6,0.2L80.3,12c-0.2-0.1-0.3-0.4-0.2-0.6l2.5-4.3
					c0.1-0.2,0.4-0.3,0.6-0.2l7.3,4.2c0.2,0.1,0.3,0.4,0.2,0.6C90.6,11.7,88.1,16,88.1,16z"/>
                        </g>
                    </g>
                    <g>
                        <defs>

                            <rect id="SVGID_B7_" x="65.9" y="4.6"
                                  transform="matrix(0.7071 -0.7071 0.7071 0.7071 14.1043 52.7253)" width="9.5"
                                  height="9.5"/>
                        </defs>
                        <clipPath id="SVGID_B8_">
                            <use xlinkHref="#SVGID_B7_" style={{overflow: 'visible'}}/>
                        </clipPath>
                        <g className={classes.st12}>
                            <path className={classes.st10} d="M72.3,13.3l0.2-0.2l1.5-1.5l1.5-1.5c0.1-0.1,0.1-0.4,0-0.5l-5-5c-0.1-0.1-0.4-0.1-0.5,0L68.5,6L67,7.5
					l-0.2,0.2l-1.4,0.1c-0.2,0.2-0.2,0.5,0,0.7l6,6c0.2,0.2,0.5,0.2,0.7,0L72.3,13.3z M69,12l-1-1c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0
					l1,1c0,0,0,0.1,0,0.1C69.1,12,69.1,12,69,12z M67.7,10.2l0.4,0l1.7,1.7l0,0.4L67.7,10.2z M72.3,12.5c-0.1,0.1-0.3,0.1-0.4,0
					l-4.3-4.3c-0.1-0.1-0.1-0.3,0-0.4l2.6-2.6c0.1-0.1,0.3-0.1,0.4,0l4.3,4.3c0.1,0.1,0.1,0.3,0,0.4C74.9,9.9,72.3,12.5,72.3,12.5z"
                            />
                        </g>
                    </g>
                    <g>
                        <defs>

                            <rect id="SVGID_B9_" x="8.9" y="16.5"
                                  transform="matrix(0.2588 -0.9659 0.9659 0.2588 -10.2886 27.7576)" width="8.1"
                                  height="8.1"/>
                        </defs>
                        <clipPath id="SVGID_B10_">
                            <use xlinkHref="#SVGID_B9_" style={{overflow: 'visible'}}/>
                        </clipPath>
                        <g className={classes.st13}>
                            <path className={classes.st10} d="M15.2,23c-0.9,0.6-2,0.8-3.1,0.5c-1.1-0.3-1.9-1-2.4-2c-0.7,0.3-1.2,0.7-1.3,1.3c-0.3,1.3,1.5,0.9,3.5,1.5
					c2,0.5,3.4,1.7,3.8,0.5C15.9,24.2,15.7,23.6,15.2,23z"/>
                            <path className={classes.st10} d="M12.3,23.1c1.8,0.5,3.6-0.6,4.1-2.3c0.5-1.8-0.6-3.6-2.3-4.1c-1.8-0.5-3.6,0.6-4.1,2.3
					C9.5,20.8,10.5,22.6,12.3,23.1z M13.6,18.3c0.9,0.2,1.4,1.1,1.2,2c-0.2,0.9-1.1,1.4-2,1.2c-0.9-0.2-1.4-1.1-1.2-2
					C11.8,18.6,12.7,18,13.6,18.3z"/>
                            <path className={classes.st10} d="M12.8,21c0.6,0.2,1.3-0.2,1.5-0.9c0.2-0.6-0.2-1.3-0.9-1.5c-0.6-0.2-1.3,0.2-1.5,0.9
					C11.8,20.2,12.2,20.9,12.8,21z M13.6,19.4c0.2,0,0.3,0.2,0.2,0.4c0,0.2-0.2,0.3-0.4,0.2c-0.2,0-0.3-0.2-0.2-0.4
					S13.4,19.3,13.6,19.4z"/>
                        </g>
                    </g>
                    <g>
                        <defs>

                            <rect id="SVGID_B11_" x="11" y="31.6"
                                  transform="matrix(0.2793 -0.9602 0.9602 0.2793 -23.0872 37.8121)" width="5.4"
                                  height="5.4"/>
                        </defs>
                        <clipPath id="SVGID_B12_">
                            <use xlinkHref="#SVGID_B11_" style={{overflow: 'visible'}}/>
                        </clipPath>
                        <g className={classes.st14}>
                            <path className={classes.st10} d="M15.1,35.9c-0.6,0.4-1.3,0.5-2,0.3c-0.7-0.2-1.3-0.7-1.6-1.3c-0.4,0.2-0.8,0.4-0.9,0.8
					c-0.2,0.8,1,0.6,2.3,1c1.3,0.4,2.3,1.2,2.5,0.4C15.5,36.7,15.4,36.3,15.1,35.9z"/>
                            <path className={classes.st10} d="M13.2,35.9c1.2,0.3,2.4-0.3,2.7-1.5c0.3-1.2-0.3-2.4-1.5-2.7c-1.2-0.3-2.4,0.3-2.7,1.5
					C11.3,34.4,12,35.6,13.2,35.9z M14.1,32.8c0.6,0.2,0.9,0.8,0.7,1.4c-0.2,0.6-0.8,0.9-1.4,0.7c-0.6-0.2-0.9-0.8-0.7-1.4
					C12.9,32.9,13.5,32.6,14.1,32.8z"/>
                            <path className={classes.st10} d="M13.6,34.6c0.4,0.1,0.9-0.1,1-0.5c0.1-0.4-0.1-0.9-0.5-1c-0.4-0.1-0.9,0.1-1,0.5
					C12.9,34,13.1,34.5,13.6,34.6z M14.1,33.5c0.1,0,0.2,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.1c-0.1,0-0.2-0.1-0.1-0.2
					C13.9,33.5,14,33.5,14.1,33.5z"/>
                        </g>
                    </g>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_B13_" x="1" y="25.6" transform="matrix(0.866 -0.5 0.5 0.866 -13.6547 5.6127)"
                          width="5.4" height="5.4"/>
                </defs>
                <clipPath id="SVGID_B14_">
                    <use xlinkHref="#SVGID_B13_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st15}>
                    <path className={classes.st10} d="M5.8,28.3C5.7,29,5.3,29.7,4.7,30c-0.6,0.4-1.4,0.4-2.1,0.2c-0.2,0.4-0.2,0.9,0,1.2c0.4,0.7,1.1-0.3,2.3-0.9
			c1.2-0.7,2.4-0.8,2-1.5C6.7,28.6,6.3,28.4,5.8,28.3z"/>
                    <path className={classes.st10} d="M4.5,29.8c1.1-0.6,1.4-2,0.8-3c-0.6-1.1-2-1.4-3-0.8c-1.1,0.6-1.4,2-0.8,3C2.1,30,3.5,30.4,4.5,29.8z
			 M2.9,26.9c0.5-0.3,1.2-0.1,1.5,0.4c0.3,0.5,0.1,1.2-0.4,1.5c-0.5,0.3-1.2,0.1-1.5-0.4C2.1,27.9,2.3,27.2,2.9,26.9z"/>
                    <path className={classes.st10} d="M3.8,28.6c0.4-0.2,0.5-0.7,0.3-1.1C3.9,27.1,3.4,27,3,27.2c-0.4,0.2-0.5,0.7-0.3,1.1
			C2.9,28.6,3.4,28.8,3.8,28.6z M3.4,27.4c0.1-0.1,0.2,0,0.3,0.1c0.1,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0-0.3-0.1
			C3.2,27.6,3.3,27.5,3.4,27.4z"/>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_B15_" x="2.2" y="1.8" transform="matrix(0.866 -0.5 0.5 0.866 -2.8313 5.1674)"
                          width="12.1" height="12.1"/>
                </defs>
                <clipPath id="SVGID_B16_">
                    <use xlinkHref="#SVGID_B15_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st16}>
                    <path className={classes.st10} d="M13.2,8c-0.3,1.5-1.2,2.9-2.7,3.8c-1.5,0.8-3.1,1-4.6,0.4c-0.4,1-0.5,1.9,0,2.7c1,1.7,2.6-0.6,5.3-2.1
			c2.7-1.6,5.4-1.8,4.5-3.5C15.1,8.5,14.2,8.1,13.2,8z"/>
                    <path className={classes.st10} d="M10.1,11.2c2.4-1.4,3.2-4.4,1.8-6.8c-1.4-2.4-4.4-3.2-6.8-1.8C2.8,4,2,7,3.4,9.4
			C4.8,11.8,7.8,12.6,10.1,11.2z M6.4,4.8c1.2-0.7,2.7-0.3,3.4,0.9c0.7,1.2,0.3,2.7-0.9,3.4C7.7,9.7,6.2,9.3,5.5,8.2
			C4.9,7,5.3,5.5,6.4,4.8z"/>
                    <path className={classes.st10} d="M8.6,8.5C9.4,8,9.7,6.9,9.2,6C8.7,5.2,7.6,4.9,6.8,5.4C5.9,5.9,5.6,7,6.1,7.8S7.7,9,8.6,8.5z M7.6,5.9
			c0.2-0.1,0.5-0.1,0.6,0.2c0.1,0.2,0.1,0.5-0.2,0.6C7.9,6.8,7.6,6.7,7.5,6.5C7.3,6.3,7.4,6,7.6,5.9z"/>
                </g>
            </g>
        </SVG>
    );
}