import { FC } from 'react';
import classes from './styles/index.module.scss';
import { FlexBox } from "../../../../components/FlexBox";
import { LogoIcon } from "../../icons/LogoIcon";
import classNames from "classnames";
import { Title } from "../Topography/Title";
import { Span } from "../Topography/Span";
import { useNavigate } from "react-router-dom";
import { BROWSER_TYPE, RouteUrls } from "../../const";

type LogoPropsType = {
    withTitle?: boolean;
    colorType?: 'light' | 'dark' | 'colored';
    isMain?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Logo: FC<LogoPropsType> = (
    { withTitle = true, colorType = 'colored', isMain }
) => {
    const iconClasses = classNames(classes[`icon_${colorType}`]);
    const containerClasses = classNames(classes[`container_${colorType}`]);
    const navigate = useNavigate();

    const goToMain = () => {
        navigate(RouteUrls.Home);
    };

    return (
        <FlexBox gap='small' className={containerClasses} align='middle' onClick={goToMain} style={{ cursor: 'pointer'}}>
            <LogoIcon className={iconClasses}/>
            {withTitle && (
                <FlexBox gap='tiny' column justify='space-between'>
                    <Title level={isMain ? 1 : 6} size='middle' weight='bold'>
                        Screeny
                    </Title>
                    <Span size='tiny'>
                        {BROWSER_TYPE} extension
                    </Span>
                </FlexBox>
            )}
        </FlexBox>
    );
}