import { notification } from "antd";
import { NotificationInstance, NotificationPlacement } from "antd/lib/notification/interface";

export const useNotification = (type: Exclude<keyof NotificationInstance, 'destroy'> = 'open', placement:  NotificationPlacement = 'topRight') => {
    const [api, contextHolder] = notification.useNotification();
    const notificationFunction = api[type];

    return {
        contextHolder,
        openNotification: ({ message, description } : { message: string, description: string }) => {
            notificationFunction({
                message,
                description,
                placement
            });
        }
    };
}

export default useNotification;