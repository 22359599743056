import React, { FC } from 'react';
import { SVG } from "../../../../icons/SVG";

export const Error404Image: FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <SVG {...props} viewBox="0 0 500 500" width='350px'>
        <g id="freepik--background-complete--inject-7">
            <path
                d="M93.18,309.07a10.09,10.09,0,0,1,2.23-5.55,9.59,9.59,0,0,1,4.8-3.35,7.69,7.69,0,0,1,8.71,3.46,7.72,7.72,0,0,1,8.1-4.16,9.6,9.6,0,0,1,5.16,2.77,9.93,9.93,0,0,1,2.86,5.24A12.07,12.07,0,0,1,124,315a17.24,17.24,0,0,1-5.56,5.87c-3.72,2.55-8.61,5.75-8.61,5.75s-4.48-2.95-8.46-5a17.07,17.07,0,0,1-6.22-5.17A12.13,12.13,0,0,1,93.18,309.07Z"
                style={{fill:'#ebebeb'}}/>
            <path
                d="M371.89,198.62a10,10,0,0,1,2.23-5.55,9.52,9.52,0,0,1,4.79-3.35,7.69,7.69,0,0,1,8.71,3.46,7.72,7.72,0,0,1,8.1-4.16,9.6,9.6,0,0,1,5.16,2.77,10,10,0,0,1,2.87,5.24,12.14,12.14,0,0,1-1.07,7.53,17.15,17.15,0,0,1-5.56,5.87c-3.71,2.55-8.61,5.75-8.61,5.75s-4.47-3-8.46-5a17.07,17.07,0,0,1-6.22-5.17A12.12,12.12,0,0,1,371.89,198.62Z"
                style={{fill:'#ebebeb'}}/>
            <path
                d="M120.25,284.24a6.35,6.35,0,0,1,1.43-3.56,6.16,6.16,0,0,1,3.08-2.16,5.05,5.05,0,0,1,3.91.53,5.15,5.15,0,0,1,1.69,1.69,4.94,4.94,0,0,1,5.2-2.66,6.05,6.05,0,0,1,3.31,1.77,6.39,6.39,0,0,1,1.84,3.37,7.72,7.72,0,0,1-.68,4.83,11,11,0,0,1-3.57,3.77c-2.39,1.64-5.53,3.7-5.53,3.7s-2.88-1.9-5.44-3.24a11,11,0,0,1-4-3.32A7.78,7.78,0,0,1,120.25,284.24Z"
                style={{fill:'#f5f5f5'}}/>
            <path
                d="M354.78,223.1a6.45,6.45,0,0,1,1.43-3.56,6.16,6.16,0,0,1,3.08-2.15,5,5,0,0,1,5.6,2.22,5,5,0,0,1,1.38-1.68,5,5,0,0,1,3.82-1,6.13,6.13,0,0,1,3.31,1.78,6.36,6.36,0,0,1,1.84,3.37,7.73,7.73,0,0,1-.68,4.83,10.91,10.91,0,0,1-3.57,3.77c-2.39,1.63-5.53,3.69-5.53,3.69s-2.87-1.89-5.44-3.24a10.9,10.9,0,0,1-4-3.32A7.76,7.76,0,0,1,354.78,223.1Z"
                style={{fill:'#f5f5f5'}}/>
            <path
                d="M405.33,273.65a6.35,6.35,0,0,1,1.43-3.56,6.16,6.16,0,0,1,3.08-2.16,5.05,5.05,0,0,1,3.91.53,5.15,5.15,0,0,1,1.69,1.69,4.92,4.92,0,0,1,5.2-2.66,6.19,6.19,0,0,1,3.31,1.77,6.39,6.39,0,0,1,1.84,3.37,7.79,7.79,0,0,1-.68,4.83,10.94,10.94,0,0,1-3.58,3.77c-2.38,1.64-5.53,3.7-5.53,3.7s-2.87-1.9-5.43-3.24a11,11,0,0,1-4-3.32A7.78,7.78,0,0,1,405.33,273.65Z"
                style={{fill:'#ebebeb'}}/>
            <path
                d="M126.9,325.68a4.17,4.17,0,0,1,.95-2.34,4.06,4.06,0,0,1,2-1.43,3.33,3.33,0,0,1,2.58.35,3.49,3.49,0,0,1,1.11,1.12,3.37,3.37,0,0,1,.91-1.11,3.3,3.3,0,0,1,2.52-.65,4,4,0,0,1,2.18,1.17A4.26,4.26,0,0,1,140.4,325a5.15,5.15,0,0,1-.45,3.19,7.21,7.21,0,0,1-2.36,2.49c-1.57,1.08-3.65,2.43-3.65,2.43s-1.89-1.25-3.58-2.13a7.36,7.36,0,0,1-2.63-2.19A5.12,5.12,0,0,1,126.9,325.68Z"
                style={{fill:'#f5f5f5'}}/>
            <path
                d="M72.79,83.72s-4.61,4,5.4,13.21a157.3,157.3,0,0,0,16.1,13.39s2,2.26-.06,3.49S87,116.06,87,119.15s3,5.11,8.07,5.91,16,3,16.93,3.16,2.22,1.13.94,2.2-11.87,6.28-9.09,11.15,17.12,4.81,18.69,4.84,2.11.48,2.28,1.28-3.56,2.21-6.51,3.81-6.09,4-4.26,6.67,11.62,6.12,30.47,1.19l8.59-2.85,2-8.88c3.06-19.24-1.32-28.64-4.17-30.2s-4.91,1.79-6.22,4.88-2.35,6.94-3.16,6.86-1.32-.59-1.5-2.15-1.51-15.84-6.64-18.12-9.27,8.76-10.21,10.14-2,.22-2.28-.72-3.51-11.63-4.8-16.55-3.48-7.74-6.67-7.44-3.58,5.55-4.61,7.73-3.47.41-3.47.41A156.36,156.36,0,0,0,86.44,87.75c-10.17-9-13.74-3.94-13.74-3.94"
                style={{fill:'#ebebeb'}}/>
            <path
                d="M154.26,158.34c-7.44-6.68-20.47-18.77-31.71-29.09s-21.42-19.66-28.83-26.37l-8.78-7.94c-1-.94-1.84-1.66-2.39-2.17l-.62-.56-.2-.2a2.08,2.08,0,0,1,.22.17c.15.14.37.32.64.55L85,94.85c2.1,1.85,5.12,4.54,8.84,7.88,7.45,6.68,17.65,16,28.89,26.31S147,151.48,154.4,158.19"
                style={{fill:'#e0e0e0'}}/>
            <path
                d="M94.29,118A3,3,0,0,1,95,118l1.78,0c1.5,0,3.58,0,5.87.14l5.86.26,1.78.07a3.52,3.52,0,0,1,.65,0,2.84,2.84,0,0,1-.65.06c-.43,0-1,0-1.79,0-1.5,0-3.57-.09-5.86-.18l-5.87-.23L95,118.06A2.73,2.73,0,0,1,94.29,118Z"
                style={{fill:'#e0e0e0'}}/>
            <path d="M111.27,117.7c-.08,0-.17-4.29-.2-9.57s0-9.58.09-9.58.16,4.29.19,9.57S111.34,117.7,111.27,117.7Z"
                  style={{fill:'#e0e0e0'}}/>
            <path
                d="M127.81,134.32a3.49,3.49,0,0,1,.15-.65c.14-.46.3-1,.5-1.74.42-1.48,1-3.52,1.53-5.79s1-4.34,1.27-5.85c.15-.7.26-1.3.36-1.78a2.76,2.76,0,0,1,.16-.65,3,3,0,0,1-.05.67c-.06.43-.14,1-.27,1.8-.25,1.51-.64,3.6-1.19,5.88s-1.15,4.31-1.62,5.78c-.24.73-.43,1.31-.58,1.72A2.74,2.74,0,0,1,127.81,134.32Z"
                style={{fill:'#e0e0e0'}}/>
            <path
                d="M112.52,138a2.22,2.22,0,0,1,.58-.21l1.62-.45c1.37-.38,3.26-.87,5.36-1.4s4-1,5.38-1.32l1.63-.37a3.8,3.8,0,0,1,.61-.1,2.51,2.51,0,0,1-.58.21l-1.61.45c-1.37.38-3.27.87-5.36,1.4s-4,1-5.38,1.32l-1.64.37A3.63,3.63,0,0,1,112.52,138Z"
                style={{fill:'#e0e0e0'}}/>
            <path
                d="M125.26,154.52a3.93,3.93,0,0,1,.75-.25l2.07-.59c1.75-.49,4.17-1.14,6.85-1.85s5.11-1.33,6.88-1.77l2.09-.5a4.65,4.65,0,0,1,.77-.15,4,4,0,0,1-.74.26l-2.07.59c-1.75.48-4.18,1.14-6.86,1.84s-5.11,1.33-6.87,1.77l-2.09.5A3.62,3.62,0,0,1,125.26,154.52Z"
                style={{fill:'#e0e0e0'}}/>
            <path
                d="M144.18,149.4a4.51,4.51,0,0,1,.17-.83c.14-.57.32-1.33.55-2.23.46-1.89,1.09-4.5,1.7-7.4s1.11-5.55,1.45-7.46c.17-.92.31-1.68.41-2.27a5.48,5.48,0,0,1,.18-.83,5.22,5.22,0,0,1-.07.85c-.07.54-.17,1.32-.32,2.28-.29,1.93-.75,4.58-1.37,7.48s-1.28,5.52-1.79,7.4c-.26.94-.48,1.69-.64,2.21A6.62,6.62,0,0,1,144.18,149.4Z"
                style={{fill:'#e0e0e0'}}/>
            <path
                d="M355.28,139.38C342,127.75,340.7,108.7,343.08,92c.4-2.8.41-5.79,2-8.11s4.89-3.66,7.28-2.21c2,1.21,2.62,3.73,3.57,5.86a16.45,16.45,0,0,0,5.9,7c1.69,1.12,3.84,1.92,5.71,1.14,2.56-1.07,3.2-4.38,3.49-7.18q.79-7.8,1.58-15.61a25.83,25.83,0,0,1,1.74-8.19c1.15-2.56,3.33-4.81,6.07-5.27s5.85,1.43,6.11,4.22a24,24,0,0,1-.29,3.48,2.94,2.94,0,0,0,1.42,3c1.08.45,2.28-.22,3.2-1,3.18-2.51,5.53-5.93,7.75-9.34S403,52.83,406,50s6.82-4.83,10.84-4.63,8,3.13,8.6,7.16-2.14,7.79-5,10.7a62.92,62.92,0,0,1-14.3,11,5.14,5.14,0,0,0-2.42,2.1c-.81,1.79.76,3.83,2.53,4.64,2,.91,4.28.94,6.43,1.43s4.4,1.71,5,3.85c.84,3-1.87,5.71-4.43,7.41a53.52,53.52,0,0,1-16.77,7.42c-2.13.53-4.36,1-6.17,2.22S387.2,107,388,109s3.29,3,5.48,2.82,4.25-1.13,6.38-1.68c4-1,8.91-.24,11,3.34a8.87,8.87,0,0,1,.1,7.91,20.48,20.48,0,0,1-5,6.44,54,54,0,0,1-24.65,13.63c-9.22,2.12-17.55,2.07-26.05-2.11"
                style={{fill:'#f5f5f5'}}/>
            <path
                d="M360.76,129.31a253.21,253.21,0,0,1,12.82-25.85c1.26-2.23,2.49-4.43,3.71-6.6s2.44-4.31,3.75-6.34a128,128,0,0,1,8.23-11.21c2.79-3.44,5.56-6.6,8.17-9.54s5.12-5.62,7.5-8a100,100,0,0,1,12-10.25c1.51-1.1,2.71-1.91,3.53-2.45l.94-.61a1.74,1.74,0,0,1,.33-.19,2.44,2.44,0,0,1-.3.24l-.91.65c-.8.56-2,1.4-3.47,2.52a106.39,106.39,0,0,0-11.9,10.35c-2.35,2.37-4.83,5.06-7.43,8s-5.35,6.12-8.12,9.56a123.14,123.14,0,0,0-8.17,11.18c-1.31,2-2.51,4.14-3.73,6.31l-3.71,6.6a257.86,257.86,0,0,0-12.83,25.78"
                style={{fill:'#e0e0e0'}}/>
            <path
                d="M381.19,90.52a6.6,6.6,0,0,1-.14-1.22c-.07-.79-.12-1.93-.17-3.34-.09-2.82-.09-6.72-.07-11s0-8.19-.17-11c-.07-1.41-.13-2.55-.19-3.33a5.07,5.07,0,0,1,0-1.23,6.42,6.42,0,0,1,.22,1.21c.12.78.24,1.92.35,3.32.23,2.82.34,6.72.32,11s-.05,8.08-.07,11v3.34A5.05,5.05,0,0,1,381.19,90.52Z"
                style={{fill:'#e0e0e0'}}/>
            <path
                d="M417.79,87.68a8.67,8.67,0,0,1-1.45,0c-.93,0-2.28,0-3.94,0-3.32,0-7.91.16-13,.56s-9.62,1-12.91,1.48c-1.65.24-3,.47-3.89.62a8.64,8.64,0,0,1-1.44.2,7.13,7.13,0,0,1,1.4-.4c.91-.21,2.23-.5,3.87-.79,3.29-.59,7.86-1.22,12.93-1.63a128.71,128.71,0,0,1,13-.41c1.66,0,3,.12,3.94.2A7.53,7.53,0,0,1,417.79,87.68Z"
                style={{fill:'#e0e0e0'}}/>
            <path
                d="M361.27,128.69a1.77,1.77,0,0,1-.2-.46l-.47-1.36c-.41-1.19-1-2.92-1.59-5.07-1.29-4.3-2.88-10.29-4.55-16.92s-3.2-12.63-4.4-16.95l-1.43-5.11c-.15-.55-.28-1-.38-1.39a1.81,1.81,0,0,1-.1-.5,3.32,3.32,0,0,1,.2.47c.12.37.28.82.47,1.36.41,1.19.95,2.92,1.59,5.07,1.29,4.29,2.88,10.28,4.55,16.92s3.2,12.63,4.4,17l1.43,5.11c.15.55.28,1,.38,1.39A1.85,1.85,0,0,1,361.27,128.69Z"
                style={{fill:'#e0e0e0'}}/>
            <path
                d="M410.59,118.47a1.44,1.44,0,0,1-.49.14l-1.43.32-5.29,1.1c-4.46.94-10.6,2.29-17.39,3.81s-13,2.81-17.44,3.65c-2.24.43-4.06.75-5.32,1l-1.45.21a1.52,1.52,0,0,1-.51,0,3.24,3.24,0,0,1,.49-.14l1.44-.31,5.28-1.11c4.46-.93,10.61-2.29,17.4-3.8s12.95-2.82,17.43-3.66c2.24-.42,4.06-.74,5.32-.94l1.45-.22A2,2,0,0,1,410.59,118.47Z"
                style={{fill:'#e0e0e0'}}/>
        </g>
        <g id="freepik--Floor--inject-7">
            <path
                d="M473.37,465.82c0,.15-100,.27-223.36.27S26.63,466,26.63,465.82s100-.26,223.38-.26S473.37,465.68,473.37,465.82Z"
                style={{fill:'#263238'}}/>
        </g>
        <g id="freepik--Character--inject-7">
            <path
                d="M315.7,223.46q.82-13.89,1.66-27.78c.07-1.12.24-2.41,1.2-3a3.38,3.38,0,0,1,2.9.2c16.42,6.61,28.47,24.93,29.91,42.56"
                style={{fill:'#FF725E'}}/>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M321.28,202.28a29.81,29.81,0,0,1,13.4,22.87,4.54,4.54,0,0,1-.66,3.19,3.31,3.31,0,0,1-2.45,1.07,9.7,9.7,0,0,1-2.72-.42c-2.21-.57-4.54-1.19-6.18-2.79a8.85,8.85,0,0,1-2.41-5.86,26.94,26.94,0,0,1,.66-6.44l2.8,4.78-3-15.48a1,1,0,0,1,.08-.83c.17-.22.65-.14.62.14"
                    style={{fill:'#fff'}}/>
            </g>
            <path d="M220.51,243.18a59.72,59.72,0,0,1-14-36.21l9.15,4.31-2.29-2.79A51.4,51.4,0,0,1,247.9,226"
                  style={{fill:'#FF725E'}}/>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M225.58,239.14a32.47,32.47,0,0,1-13.13-22.24c-.07-.6,0-1.39.56-1.59a1.42,1.42,0,0,1,1.18.33l16.51,10.79a7.49,7.49,0,0,1-1.3-3.47l7,5.13a2.31,2.31,0,0,1,1.1,1.39,2.06,2.06,0,0,1-.85,1.69,9.08,9.08,0,0,1-8.75,1.94l3,2.61a7.89,7.89,0,0,1-6.1,1.05,2.52,2.52,0,0,0,1.08,2.61"
                    style={{fill:'#fff'}}/>
            </g>
            <path
                d="M407,330.45s12.8,13.23,18.62,12.13c0,0-20.22-21.51-28.72-41.36a183.67,183.67,0,0,0-14.46-27c3.83-6.6,7.24-14.07,8.8-21.62a79.64,79.64,0,0,1-14.59,13.2c-.44-.62-.89-1.23-1.35-1.85a27.18,27.18,0,0,0,8.56-15.6l-20.21-.87a24.89,24.89,0,0,0,1.71-2.85,135,135,0,0,0-33-20.91c-23.25-13.23-75-17.6-100.2,3.91-22.57,13.16-28.62,30-46.4,34.17l-10.54-1.15s-4.1,22,9.37,31.83l-10-2.32s1.84,8.76,11.68,18.42l-6.14-.75s2.66,13.78,11.16,17.09c0,0-13.28,1.66-17-3.31,0,0-4.42,20.6,15.69,28.37,0,0-60.26,7.06-43.32,80.83,4,17.23,27.75,29.63,55.1,35.62l197-.77c12-3.92,26.32-43.68,26.85-59.67s-7.43-46.88-7.43-46.88l7.46,9.93Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M181.84,361.19c-11.31-.68-22.42,8.11-24.35,19.28a56.34,56.34,0,0,1,24.53-9,23.15,23.15,0,0,0-5.83,25.62,30.72,30.72,0,0,1,16.72-17.49,23.8,23.8,0,0,0-4.16,23"
                style={{fill:'#FF725E'}}/>
            <path
                d="M188.75,402.62a2.11,2.11,0,0,1-.16-.41c-.09-.27-.25-.67-.41-1.2a23.49,23.49,0,0,1-.82-4.82,24.2,24.2,0,0,1,.77-7.66,23.41,23.41,0,0,1,4.63-9l.23.31a30.71,30.71,0,0,0-16.57,17.39l-.22.6-.24-.59a23.5,23.5,0,0,1,.73-19,22.7,22.7,0,0,1,5.16-6.87l.2.45a56.61,56.61,0,0,0-24.44,8.9l-.44.29.1-.52a22,22,0,0,1,2.5-6.87,22.9,22.9,0,0,1,4-5.25,24.67,24.67,0,0,1,8.94-5.78,22.55,22.55,0,0,1,6.71-1.4c.8,0,1.41,0,1.81,0l.47,0,.15,0-.15,0h-.47c-.41,0-1,0-1.8,0a23.15,23.15,0,0,0-6.63,1.51,24.49,24.49,0,0,0-8.77,5.79,22.65,22.65,0,0,0-3.89,5.19,21.85,21.85,0,0,0-2.42,6.75l-.35-.23a56.77,56.77,0,0,1,24.63-9l.81-.09-.61.54a22.23,22.23,0,0,0-5,6.71,23,23,0,0,0-.74,18.62H176a31.09,31.09,0,0,1,7.24-11.13,30.64,30.64,0,0,1,9.64-6.45l.76-.32-.53.63a23.26,23.26,0,0,0-4.63,8.84,24.5,24.5,0,0,0-.85,7.57,24,24,0,0,0,.72,4.79c.13.54.27.95.34,1.22A2.5,2.5,0,0,1,188.75,402.62Z"
                style={{fill:'#263238'}}/>
            <path d="M388.33,392.22a27.15,27.15,0,0,1,30.48,15.65" style={{fill:'#FF725E'}}/>
            <path
                d="M418.81,407.87c-.1.05-.87-1.9-2.82-4.61a27.47,27.47,0,0,0-22.27-11.43c-3.34,0-5.38.5-5.39.39a6.88,6.88,0,0,1,1.41-.35,23.24,23.24,0,0,1,4-.4,26.64,26.64,0,0,1,22.58,11.58,24.12,24.12,0,0,1,2,3.46A6.35,6.35,0,0,1,418.81,407.87Z"
                style={{fill:'#263238'}}/>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M318.87,408.84C311.61,410,302.37,415.49,299,422c-.72,1.39-.06,1.58,0,3.14a4.2,4.2,0,0,0,2.36,3.77,2.76,2.76,0,0,0,3.69-1.79,21.56,21.56,0,0,0,3.26,15.3,11.6,11.6,0,0,0,4.72-5,28.66,28.66,0,0,0,8,21.29l-3.54-13.81c-1.27-5-2.5-9.25-2.16-14.38.36-5.48,3.62-10.1,6.15-14.64-3.48,1.51-7.51,8.35-10.94,12.52a24,24,0,0,1,8.09-19.36'"/>
            </g>
            <path
                d="M317.51,445.06a3,3,0,0,1-.28-.59c-.15-.4-.44-1-.68-1.77a27.18,27.18,0,0,1-1.35-6.94,29.52,29.52,0,0,1,1.25-10.65,28.67,28.67,0,0,1,6.81-11.67l.35.36a105.39,105.39,0,0,0-8.31,8.33,38.36,38.36,0,0,0-4.45,6.24l-.39.69-.09-.78a21.69,21.69,0,0,1,2.16-12,21.46,21.46,0,0,1,6.64-7.93l.19.34A16.11,16.11,0,0,0,314,412a16.87,16.87,0,0,0-3,3.7,18.71,18.71,0,0,0-1.34,2.73,8.93,8.93,0,0,1-.38,1,1,1,0,0,1,0-.26c.05-.18.11-.44.22-.77a15.58,15.58,0,0,1,1.27-2.81,16.86,16.86,0,0,1,3-3.82,16.43,16.43,0,0,1,5.44-3.43l1.34-.51-1.16.85A21.25,21.25,0,0,0,313,416.5a21.47,21.47,0,0,0-2.07,11.72l-.48-.1a39.56,39.56,0,0,1,4.5-6.33,103,103,0,0,1,8.37-8.36l.35.36a28.58,28.58,0,0,0-6.76,11.44,29.65,29.65,0,0,0-1.34,10.51,29,29,0,0,0,1.19,6.91c.22.79.48,1.37.6,1.78A5.09,5.09,0,0,1,317.51,445.06Z"
                style={{fill:'#263238'}}/>
            <path
                d="M198.86,444a28.71,28.71,0,0,0,14.81,8.74,1.93,1.93,0,0,0,1.89-.26c.58-.64.1-1.65-.38-2.36l-6.35-9.38a33.72,33.72,0,0,0,15.57,6.84c.95.15,2.12.15,2.6-.67a2.47,2.47,0,0,0-.23-2.21c-1-2.14-2-4.25-3.18-6.31"
                style={{fill:'#FF725E'}}/>
            <path
                d="M223.59,438.35a2.56,2.56,0,0,1,.31.5l.8,1.5c.36.67.77,1.47,1.25,2.38q.38.7.78,1.5a4.77,4.77,0,0,1,.63,1.83,1.6,1.6,0,0,1-.32,1.08,1.79,1.79,0,0,1-1,.6,5.87,5.87,0,0,1-2.32-.11,32.49,32.49,0,0,1-4.87-1.23,33.6,33.6,0,0,1-10.17-5.49l.38-.35,1.1,1.63,3.54,5.24,1.69,2.49a4.33,4.33,0,0,1,.65,1.41,1.36,1.36,0,0,1-.57,1.5,2.15,2.15,0,0,1-1.52.16c-.47-.08-.89-.22-1.33-.32-.87-.25-1.7-.51-2.48-.81a28.13,28.13,0,0,1-4.22-2,27.55,27.55,0,0,1-5.39-4.05c-.57-.56-1-1-1.26-1.33a3.1,3.1,0,0,1-.4-.51,2.94,2.94,0,0,1,.48.43c.29.3.73.73,1.32,1.26a29.83,29.83,0,0,0,5.43,3.88,28.72,28.72,0,0,0,4.19,1.92c.78.29,1.6.54,2.45.78s1.8.57,2.48.19a.87.87,0,0,0,.34-1,3.61,3.61,0,0,0-.59-1.22c-.55-.81-1.11-1.64-1.69-2.48q-1.72-2.55-3.55-5.24l-1.1-1.63-1-1.4L209,440.5a33.77,33.77,0,0,0,14.8,6.72,5.56,5.56,0,0,0,2.16.14,1.21,1.21,0,0,0,1.05-1.28,4.53,4.53,0,0,0-.56-1.72c-.26-.53-.51-1-.74-1.51l-1.17-2.41-.72-1.54A2.2,2.2,0,0,1,223.59,438.35Z"
                style={{fill:'#263238'}}/>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M223.53,381.78a22.63,22.63,0,0,0,9.32,8.69,10,10,0,0,0-2.44-5.54,42.6,42.6,0,0,0,14.69,9.14,16.09,16.09,0,0,1-22.56-13.14'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M196.77,316.61c7.94,15.27,33.62,22.81,53.76,23.14,6.22.09,15,4.69,21.16,4.09,13.83-1.33,25.06-8.78,38.92-10,8.63-.73,17.31-1,25.84-2.45s8.14-2.46,14.92-7.85,14.11-10.73,14-19.39c-12.74,15.6-25.33,20.43-44.94,25s-40,4.47-60.11,3.54c-22.13-1-45.36-3.44-63.55-16.09'"/>
            </g>
            <path
                d="M265.09,199.49c.09,0,.17,1.13.47,2.92a34.18,34.18,0,0,0,1.9,6.81,34.72,34.72,0,0,0,3.31,6.26,28.57,28.57,0,0,1,1.67,2.44s-.23-.17-.58-.55a17.38,17.38,0,0,1-1.39-1.68,28.52,28.52,0,0,1-3.49-6.27,27.66,27.66,0,0,1-1.78-7,19.28,19.28,0,0,1-.16-2.17A2.65,2.65,0,0,1,265.09,199.49Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M271.62,197c.09,0-.31,1-.7,2.67a22,22,0,0,0-.49,6.58,21.68,21.68,0,0,0,1.53,6.41c.64,1.58,1.19,2.49,1.11,2.53s-.19-.19-.46-.59a14.2,14.2,0,0,1-1-1.79,19.11,19.11,0,0,1-1.05-13.23,12.72,12.72,0,0,1,.69-1.92C271.46,197.18,271.59,196.94,271.62,197Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M149.23,363.2a2.59,2.59,0,0,1,.4-.39c.27-.24.66-.62,1.21-1a40,40,0,0,1,4.86-3.31,57.75,57.75,0,0,1,18.17-6.58A120,120,0,0,1,193.15,350c2.48-.06,4.48-.08,5.86-.08h1.6a3.3,3.3,0,0,1,.56,0,2.37,2.37,0,0,1-.56.07l-1.59.08-5.86.25a134.18,134.18,0,0,0-19.19,2,60.8,60.8,0,0,0-18.09,6.38,49.24,49.24,0,0,0-4.91,3.16C149.86,362.75,149.26,363.23,149.23,363.2Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M292.58,201.34c0,.09-1.71-.48-4.49-1.16a20.39,20.39,0,0,0-4.94-.59,11.16,11.16,0,0,0-5.73,1.63,8.06,8.06,0,0,0-2.25,2.09,7.2,7.2,0,0,0-1.13,2.62,11.41,11.41,0,0,0,.21,4.9c.69,2.76,1.43,4.4,1.33,4.44a5.35,5.35,0,0,1-.55-1.13,24.73,24.73,0,0,1-1.14-3.22,11.41,11.41,0,0,1-.32-5.08,7.52,7.52,0,0,1,1.18-2.82,8.57,8.57,0,0,1,2.39-2.25,11.47,11.47,0,0,1,6-1.66,19,19,0,0,1,5,.72c1.39.37,2.49.75,3.25,1A6.44,6.44,0,0,1,292.58,201.34Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M442.61,451.94c-1.08-7.18-4.6-14.25-10.61-18.34s-14.66-4.57-20.38-.08l-23.74,32.1,50.54.21C441.24,463.22,443.18,455.74,442.61,451.94Z"
                style={{fill:'#FF725E'}}/>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M409,465.16c4.27-1.78,7.42-5.61,9.36-9.81s2.79-8.8,3.64-13.35c.7-3.77,1.36-7.84-.28-11.32a2.26,2.26,0,0,0-2,1,6.93,6.93,0,0,0-.95,2.17c-3.91,12.34-9.94,23-19.79,31.35'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M258.88,214.41c5,14.41,5.63,30,5.09,45.19-.27,7.58-.84,15.24-3.21,22.45s-6.72,14-13.18,18c-6.1,3.77-13.64,4.76-20.76,3.82s-13.88-3.67-20.29-6.89c-7.32-3.68-14.37-8.08-20.05-14s-10.34-14.19-11.28-22.32l10.54,1.15s8.11-.86,20.72-13.06c13.78-13.32,16.29-14.95,26.61-21.89A106.59,106.59,0,0,1,258.88,214.41Z"
                    style={{fill:'#fff'}}/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M302.44,303.23A10.49,10.49,0,0,1,315,304.94a13.84,13.84,0,0,1,14-5.1,31.67,31.67,0,0,0-8.62,5.09c-2.47,2.27-4.25,5.53-3.94,8.87a47.32,47.32,0,0,0-10.55-8.51c-1.62-1-3.75-1.81-5.27-.69'"/>
            </g>
            <path d="M336.88,415.89a32.67,32.67,0,0,0-11.61,37.82" style={{fill:'#FF725E'}}/>
            <path
                d="M325.27,453.71a1.49,1.49,0,0,1-.19-.39c-.1-.27-.27-.65-.44-1.17a28.65,28.65,0,0,1-1.11-4.43,32.11,32.11,0,0,1,8.55-27.86,27.66,27.66,0,0,1,3.41-3c.42-.33.78-.56,1-.72s.37-.23.38-.21a15.58,15.58,0,0,1-1.27,1.09,35.82,35.82,0,0,0-3.26,3.13,33,33,0,0,0-8.46,27.56,35.52,35.52,0,0,0,.95,4.42A12.14,12.14,0,0,1,325.27,453.71Z"
                style={{fill:'#263238'}}/>
            <path
                d="M356.77,381.27c4.09-3.14,9.37-6.44,14-4.23a9.35,9.35,0,0,1,4.77,6.94,23.58,23.58,0,0,1-.54,8.68,105,105,0,0,1-21.27,45.15c-3.85,4.76-9.33,9.58-15.31,8.27-5.58-1.23-8.75-7.61-8.42-13.32s3.21-10.9,5.88-16c6.34-12,12.5-25.16,20.86-35.53"
                style={{fill:'#FF725E'}}/>
            <path
                d="M356.77,381.27a4.13,4.13,0,0,1-.26.38l-.8,1.07c-.71.94-1.74,2.35-3,4.2a172,172,0,0,0-9.56,16.36l-6,11.64c-2.12,4.2-4.79,8.54-6.18,13.62a17.42,17.42,0,0,0-.41,7.94,13.23,13.23,0,0,0,3.89,7.2,9,9,0,0,0,3.67,2,9.47,9.47,0,0,0,4.27.11,16.67,16.67,0,0,0,7.64-4.37,49.8,49.8,0,0,0,5.87-6.81c1.8-2.36,3.42-4.8,5-7.22a106.2,106.2,0,0,0,12.57-28.86q.9-3.4,1.54-6.62a25.86,25.86,0,0,0,.61-6.2c-.12-3.93-2.18-7.5-5.11-8.69a7.75,7.75,0,0,0-4.27-.51,13.87,13.87,0,0,0-3.52,1.09,29.16,29.16,0,0,0-4.43,2.6l-1.1.78c-.24.17-.38.25-.38.25l.35-.29c.24-.2.59-.47,1.07-.82a26.64,26.64,0,0,1,4.4-2.7,13.25,13.25,0,0,1,3.57-1.15,7.76,7.76,0,0,1,8.21,3.9,11.87,11.87,0,0,1,1.57,5.52,25.8,25.8,0,0,1-.57,6.29c-.43,2.16-.93,4.38-1.52,6.66a106.13,106.13,0,0,1-12.55,29c-1.56,2.44-3.19,4.88-5,7.26a50.51,50.51,0,0,1-5.93,6.89,17.1,17.1,0,0,1-7.89,4.49,9.91,9.91,0,0,1-4.5-.13,9.45,9.45,0,0,1-3.88-2.15,13.64,13.64,0,0,1-4-7.48,17.78,17.78,0,0,1,.43-8.16c1.43-5.18,4.13-9.52,6.25-13.7s4.22-8.06,6.08-11.61a160.73,160.73,0,0,1,9.72-16.3c1.28-1.84,2.34-3.23,3.07-4.15l.85-1A4.47,4.47,0,0,1,356.77,381.27Z"
                style={{fill:'#263238'}}/>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M357.27,395.53l0,.05c1-2.82,3.13-3.63,5.42-4.46s5.06-.74,6.9.86c2.12,1.84,2.39,5.08,1.82,7.83a5.38,5.38,0,0,1-1.15,2.65,2.3,2.3,0,0,1-2.65.61c-1-.55-1.31-2-2.37-2.44s-2.46.55-3.74.87a3.87,3.87,0,0,1-4.31-2.32A4.72,4.72,0,0,1,357.27,395.53Z'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M361.63,381.45a2.67,2.67,0,0,1,1.57,2.71,10.51,10.51,0,0,1-.81,3.65c-.35,1-.94,2.11-2,2.15a1.74,1.74,0,0,1-1.46-.87,3.83,3.83,0,0,1-.47-1.7,9,9,0,0,1,.62-4,3.51,3.51,0,0,1,1.18-1.67,1.6,1.6,0,0,1,1.93,0'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M373.61,383.91a2.67,2.67,0,0,1,1.57,2.71,10.56,10.56,0,0,1-.81,3.65c-.35,1-.95,2.11-2,2.15a1.73,1.73,0,0,1-1.46-.87,3.58,3.58,0,0,1-.47-1.7,9.15,9.15,0,0,1,.61-4,3.53,3.53,0,0,1,1.19-1.67,1.59,1.59,0,0,1,1.93,0'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M368.55,379.33a2.71,2.71,0,0,1,1.57,2.72,10.69,10.69,0,0,1-.81,3.64c-.36,1-.95,2.12-2,2.15a1.73,1.73,0,0,1-1.46-.87,3.65,3.65,0,0,1-.46-1.69,8.85,8.85,0,0,1,.61-4,3.44,3.44,0,0,1,1.19-1.67,1.59,1.59,0,0,1,1.92,0'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M364.67,420.21a65,65,0,0,1-4.89,17.55,38.5,38.5,0,0,1-11,14.42c-2.2,1.72-4.78,3.2-7.57,3.27-3.95.1-7.55-2.73-9.33-6.26s-2-7.63-1.82-11.57c1.91,3.19,4.06,6.59,7.52,8a11.19,11.19,0,0,0,9.29-1,25.23,25.23,0,0,0,7.11-6.44,71.55,71.55,0,0,0,10.46-17.26'"/>
            </g>
            <path
                d="M140.7,382.07c-4.92-1.51-11-2.76-14.62.94a9.37,9.37,0,0,0-2,8.18,23.55,23.55,0,0,0,3.54,7.95A105.23,105.23,0,0,0,163.34,434c5.27,3.1,12.09,5.7,17.23,2.37,4.81-3.1,5.53-10.19,3.23-15.42S177,411.83,172.69,408c-10.14-9-20.52-19.18-32-26"
                style={{fill:'#FF725E'}}/>
            <path
                d="M140.7,382.07s.14.06.41.21l1.15.68c1,.61,2.49,1.54,4.34,2.81a161.25,161.25,0,0,1,14.81,11.87l9.77,8.74c3.45,3.17,7.5,6.29,10.65,10.64a17.78,17.78,0,0,1,3.27,7.49,13.78,13.78,0,0,1-1.15,8.42,9.62,9.62,0,0,1-2.88,3.37,9.85,9.85,0,0,1-4.18,1.7,17.05,17.05,0,0,1-9-1.44,51.42,51.42,0,0,1-8-4.37c-2.52-1.6-4.91-3.32-7.22-5.05A106.59,106.59,0,0,1,140.37,416a105.28,105.28,0,0,1-9.56-11.64c-1.35-1.93-2.6-3.84-3.75-5.71a25.85,25.85,0,0,1-2.75-5.69,11.87,11.87,0,0,1-.46-5.72,7.76,7.76,0,0,1,6.32-6.53,13.92,13.92,0,0,1,3.75-.18,27.31,27.31,0,0,1,5.07,1c.57.16,1,.29,1.28.39l.43.16-.44-.11-1.3-.34a30,30,0,0,0-5.06-.89,13.89,13.89,0,0,0-3.68.22,7.73,7.73,0,0,0-3.82,2c-2.33,2.15-3,6.21-1.74,9.93a25.79,25.79,0,0,0,2.74,5.59q1.74,2.79,3.76,5.67a106.26,106.26,0,0,0,21.89,22.62c2.3,1.73,4.68,3.44,7.19,5a49.72,49.72,0,0,0,7.89,4.32,16.54,16.54,0,0,0,8.68,1.42,9.39,9.39,0,0,0,4-1.61,8.91,8.91,0,0,0,2.72-3.19,13.23,13.23,0,0,0,1.12-8.1,17.22,17.22,0,0,0-3.17-7.29c-3.08-4.28-7.1-7.41-10.55-10.6l-9.72-8.79a171,171,0,0,0-14.69-12c-1.82-1.3-3.28-2.25-4.27-2.89l-1.13-.72Z"
                style={{fill:'#263238'}}/>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M145.24,395.59l0,0c-1.93-2.29-4.2-2.31-6.64-2.28s-5,1.09-6.16,3.22c-1.34,2.48-.46,5.6,1,8a5.2,5.2,0,0,0,2,2.07,2.27,2.27,0,0,0,2.69-.35c.75-.87.54-2.32,1.36-3.12s2.51-.35,3.81-.49a3.88,3.88,0,0,0,3.23-3.68A4.78,4.78,0,0,0,145.24,395.59Z'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M136.21,383.93a2.69,2.69,0,0,0-.52,3.1,10.56,10.56,0,0,0,2,3.13c.67.79,1.63,1.65,2.61,1.32a1.73,1.73,0,0,0,1.06-1.33,3.73,3.73,0,0,0-.15-1.75,9.12,9.12,0,0,0-2-3.54,3.45,3.45,0,0,0-1.7-1.15,1.6,1.6,0,0,0-1.79.71'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M125.86,390.44a2.68,2.68,0,0,0-.52,3.09,10.53,10.53,0,0,0,2,3.13c.68.79,1.63,1.65,2.62,1.32a1.75,1.75,0,0,0,1.06-1.33,3.73,3.73,0,0,0-.16-1.75,9,9,0,0,0-2-3.54,3.45,3.45,0,0,0-1.7-1.15,1.6,1.6,0,0,0-1.79.71'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M129,384.38a2.68,2.68,0,0,0-.52,3.09,10.53,10.53,0,0,0,2,3.13c.68.79,1.63,1.65,2.62,1.32a1.74,1.74,0,0,0,1.06-1.33,3.73,3.73,0,0,0-.16-1.75,9,9,0,0,0-2-3.54,3.45,3.45,0,0,0-1.7-1.15,1.6,1.6,0,0,0-1.79.71'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M147,421.3A65,65,0,0,0,157.69,436,38.33,38.33,0,0,0,173,445.68c2.66.85,5.59,1.33,8.23.42,3.74-1.29,6.11-5.2,6.54-9.13s-.77-7.86-2.35-11.48c-.67,3.66-1.49,7.6-4.25,10.1a11.11,11.11,0,0,1-9,2.34,25.25,25.25,0,0,1-8.92-3.53,72,72,0,0,1-15.84-12.5'"/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M143.28,415.87c-.29.4-.58.81-.86,1.22A17.34,17.34,0,1,1,171,436.67a88.61,88.61,0,0,1-29.9-20.15"
                    style={{fill:'#fff'}}/>
            </g>
            <path
                d="M425.6,406a3.07,3.07,0,0,1-.05.67c-.07.5-.16,1.13-.26,1.92-.23,1.67-.62,4.07-1.17,7A132.09,132.09,0,0,1,418,438.3,80.42,80.42,0,0,1,412.37,450c-.51.86-1.06,1.66-1.56,2.46l-.76,1.17-.81,1.09-1.54,2.05c-.53.63-1.06,1.22-1.56,1.78s-.95,1.13-1.45,1.59l-1.38,1.33-1.21,1.15-1.1.87-1.53,1.2a2.56,2.56,0,0,1-.56.38,4.47,4.47,0,0,1,.49-.47l1.47-1.26,1.06-.9,1.17-1.18,1.35-1.34c.49-.47.92-1.05,1.41-1.61l1.52-1.79,1.51-2.06.79-1.08c.25-.38.49-.77.74-1.17.5-.8,1-1.6,1.54-2.45a84.63,84.63,0,0,0,5.51-11.64,143.08,143.08,0,0,0,6.29-22.56c.59-2.94,1-5.33,1.33-7,.14-.78.26-1.41.35-1.9A4.5,4.5,0,0,1,425.6,406Z"
                style={{fill:'#263238'}}/>
            <path
                d="M195.54,345.34a2.47,2.47,0,0,1,.46-.48l1.39-1.3c.61-.57,1.36-1.25,2.26-2s1.9-1.66,3.06-2.58c2.29-1.89,5.1-4,8.31-6.3s6.84-4.59,10.76-6.87,7.77-4.24,11.31-5.92,6.82-3,9.6-4.07c1.38-.55,2.65-1,3.76-1.38s2.08-.71,2.87-.95l1.83-.56a3,3,0,0,1,.64-.16,4.11,4.11,0,0,1-.61.26l-1.8.65c-.78.27-1.73.61-2.83,1s-2.36.87-3.73,1.44c-2.75,1.09-6,2.5-9.52,4.17S226,324,222,326.24s-7.52,4.6-10.75,6.82-6,4.34-8.35,6.19c-1.17.9-2.19,1.77-3.1,2.52s-1.69,1.39-2.31,1.94l-1.46,1.23A2.72,2.72,0,0,1,195.54,345.34Z"
                style={{fill:'#263238'}}/>
            <path
                d="M167.39,328.64s.06-.05.18-.14l.56-.38,2.2-1.4c.95-.61,2.12-1.34,3.52-2.13s3-1.75,4.76-2.67l2.8-1.49c1-.51,2-1,3.09-1.52,2.12-1.07,4.46-2.07,6.9-3.16,4.93-2.08,10.43-4.16,16.33-6s11.63-3.17,16.88-4.21c2.63-.47,5.13-.95,7.49-1.26,1.17-.16,2.31-.35,3.4-.47l3.16-.35c2-.24,3.83-.34,5.43-.46s3-.19,4.12-.22l2.6-.07h.67a.64.64,0,0,1,.24,0l-.23,0-.68.06-2.59.17c-1.13.06-2.51.14-4.1.3s-3.42.27-5.42.54l-3.15.38c-1.09.13-2.22.33-3.39.5-2.35.32-4.84.82-7.46,1.3-5.22,1.07-10.93,2.45-16.82,4.24s-11.37,3.88-16.29,5.93c-2.44,1.07-4.78,2.06-6.9,3.11-1.07.52-2.12,1-3.1,1.49l-2.81,1.46c-1.8.9-3.38,1.82-4.78,2.59s-2.6,1.47-3.57,2.05l-2.24,1.32-.59.33C167.47,328.62,167.39,328.65,167.39,328.64Z"
                style={{fill:'#263238'}}/>
            <path d="M286.56,295.68c.06.15-2.52,1.24-5.37,3.15s-4.86,3.86-5,3.74a22.49,22.49,0,0,1,10.34-6.89Z"
                  style={{fill:'#263238'}}/>
            <path
                d="M303.48,301.11c0,.1-1.48-.57-3.93-1.19a30.15,30.15,0,0,0-9.79-.9,22.57,22.57,0,0,0-9.44,2.51,9.58,9.58,0,0,0-2.94,2.76,2.85,2.85,0,0,1,.51-1,8.06,8.06,0,0,1,2.24-2.08c2.18-1.43,5.69-2.42,9.59-2.72a28.1,28.1,0,0,1,9.93,1.06,23.24,23.24,0,0,1,2.85,1A5.21,5.21,0,0,1,303.48,301.11Z"
                style={{fill:'#263238'}}/>
            <path
                d="M270.62,309.45l1.17,2.8a8.09,8.09,0,0,1,.7,2.29,2.82,2.82,0,0,1-.66,2.24,2.7,2.7,0,0,1-3.26.17,5.57,5.57,0,0,1-2-2.81c-.42-1.1-.7-2.26-1.21-3.32a5.23,5.23,0,0,0,1.63-4.75S268,309.27,270.62,309.45Z"
                style={{fill:'#f5f5f5'}}/>
            <path
                d="M254.29,299.25a14.32,14.32,0,0,1,8.89-7.4c6.38-1.68,13,5,13,5s-7.71-2.27-7.6,1.56,2.38,3.74-.18,6.26-6.95-.13-8-3.1l-2.06-2.9Z"
                style={{fill:'#263238'}}/>
            <path
                d="M280.71,303s0,.13.05.36a5.46,5.46,0,0,1,0,1.05,7.37,7.37,0,0,1-1.5,3.51,7.17,7.17,0,0,1-4.89,2.8,7.57,7.57,0,0,1-6.33-2.63,7.14,7.14,0,0,1-1.57-3.07l.49-.14a6.48,6.48,0,0,1-.83,5.64,6.21,6.21,0,0,1-6.87,2.33,4.43,4.43,0,0,1-.76-.31c-.16-.09-.24-.14-.24-.16s.37.14,1.05.3a6.56,6.56,0,0,0,2.92,0,5.8,5.8,0,0,0,3.53-2.41,6.09,6.09,0,0,0,.72-5.23l.49-.13a6.74,6.74,0,0,0,1.47,2.84,7.16,7.16,0,0,0,5.89,2.5,6.9,6.9,0,0,0,4.65-2.56,7.5,7.5,0,0,0,1.58-3.32C280.69,303.53,280.66,303,280.71,303Z"
                style={{fill:'#263238'}}/>
            <path
                d="M268.35,292.79a9.66,9.66,0,0,1-2,.09,17.4,17.4,0,0,0-4.79.62,9.31,9.31,0,0,0-4.06,2.44c-.83.91-1.13,1.64-1.21,1.61a3.72,3.72,0,0,1,.94-1.85,8.45,8.45,0,0,1,4.18-2.7,14.55,14.55,0,0,1,4.95-.49A6.48,6.48,0,0,1,268.35,292.79Z"
                style={{fill:'#fff'}}/>
            <path
                d="M270.27,317.64a19.76,19.76,0,0,1-2.16-5.2,19.5,19.5,0,0,1-1.08-5.52c.15,0,.68,2.42,1.58,5.37S270.41,317.58,270.27,317.64Z"
                style={{fill:'#263238'}}/>
            <path
                d="M199.07,328.85a2.72,2.72,0,0,1,.4-.35l1.21-.94c1-.81,2.63-1.92,4.62-3.22s4.41-2.76,7.18-4.26,5.89-3,9.25-4.37,6.63-2.49,9.64-3.4,5.77-1.57,8.1-2,4.23-.8,5.55-1l1.52-.19a2.35,2.35,0,0,1,.53,0,1.88,1.88,0,0,1-.52.14l-1.5.28c-1.31.22-3.19.6-5.5,1.12s-5,1.22-8,2.13-6.24,2.06-9.59,3.42-6.44,2.87-9.22,4.32-5.2,2.88-7.21,4.14-3.6,2.32-4.69,3.08l-1.27.85C199.24,328.78,199.08,328.87,199.07,328.85Z"
                style={{fill:'#263238'}}/>
            <circle cx="227.26" cy="279.59" r="22.55" transform="translate(-97.43 435.79) rotate(-76.59)"
                    style={{fill:'#f5f5f5'}}/>
            <path
                d="M249.81,279.6c0,10.88-7.35,19.71-13.57,19.71S225,290.48,225,279.6s5-19.72,11.28-19.72S249.81,268.71,249.81,279.6Z"
                style={{fill:'#263238'}}/>
            <circle cx="241.24" cy="268.45" r="3.86" style={{fill:'#fff'}}/>
            <path
                d="M231.26,293.92a4.41,4.41,0,0,1-.56-.94,20.08,20.08,0,0,1-1.21-2.75,27.61,27.61,0,0,1-1.21-4.3,32.36,32.36,0,0,1-.56-5.44,32,32,0,0,1,.45-5.46,27.72,27.72,0,0,1,1.12-4.32,19.82,19.82,0,0,1,1.15-2.77,4.37,4.37,0,0,1,.54-1c.09,0-.6,1.45-1.34,3.85a32.49,32.49,0,0,0-1,4.28,34.61,34.61,0,0,0-.4,5.37,35.1,35.1,0,0,0,.51,5.36,31.36,31.36,0,0,0,1.09,4.27C230.63,292.49,231.35,293.88,231.26,293.92Z"
                style={{fill:'#fff'}}/>
            <path
                d="M221.53,250.88c-.06.73-.9,1.29-2,1.81s-2.59,1-4.06,1.67-2.76,1.25-3.86,1.75-2,.8-2.65.41-.73-1.48,0-2.93a8.74,8.74,0,0,1,4.53-4,9.31,9.31,0,0,1,5.9-.54C220.8,249.43,221.59,250.18,221.53,250.88Z"
                style={{fill:'#263238'}}/>
            <path
                d="M113.91,443l5.07-7.94c24.14,26.52,103.9,58.06,175.31-13.23,30.67-30.62,28.19-82.36,28.19-82.36l-6.1-19.16h6.1s17.68,32.61-.83,75.31c0,0-19,50.61-77.17,69C152.72,493.6,113.91,443,113.91,443Z"
                style={{fill:'#FF725E'}}/>
            <g style={{opacity: '0.7000000000000001'}}>
                <path
                    d="M113.91,443l5.07-7.94c24.14,26.52,103.9,58.06,175.31-13.23,30.67-30.62,28.19-82.36,28.19-82.36l-6.1-19.16h6.1s17.68,32.61-.83,75.31c0,0-19,50.61-77.17,69C152.72,493.6,113.91,443,113.91,443Z"
                    style={{fill:'#fff'}}/>
            </g>
            <circle cx="81.89" cy="422.05" r="43.35" transform="translate(-274.45 181.52) rotate(-45)"
                    style={{fill:'#FF725E'}}/>
            <g style={{opacity: '0.7000000000000001'}}>
                <circle cx="81.89" cy="422.05" r="43.35" transform="translate(-274.45 181.52) rotate(-45)"
                        style={{fill:'#fff'}}/>
            </g>
            <path
                d="M92.28,379.91a4.29,4.29,0,0,1-.15.82c-.12.58-.3,1.36-.51,2.35-.12.51-.25,1.08-.39,1.69s-.36,1.27-.56,2c-.43,1.42-.84,3.06-1.5,4.81l-1,2.76c-.34,1-.77,1.93-1.18,2.95-.78,2.05-1.82,4.16-2.87,6.39a111.75,111.75,0,0,1-8.22,13.79,111.83,111.83,0,0,1-10.29,12.33c-1.77,1.73-3.41,3.4-5.09,4.82-.82.72-1.6,1.45-2.39,2.09l-2.29,1.83c-1.43,1.2-2.84,2.13-4,3-.61.43-1.16.84-1.69,1.19l-1.47.93-2,1.26a3.93,3.93,0,0,1-.73.41,6.58,6.58,0,0,1,.67-.5l2-1.34,1.44-1c.52-.37,1.06-.79,1.66-1.22,1.18-.89,2.57-1.85,4-3.06l2.26-1.84c.79-.65,1.55-1.38,2.36-2.11,1.66-1.43,3.29-3.11,5-4.84A115,115,0,0,0,75.5,417.16a114.84,114.84,0,0,0,8.22-13.7c1.06-2.22,2.1-4.31,2.9-6.34.42-1,.86-2,1.21-2.93s.68-1.87,1-2.74c.68-1.74,1.12-3.37,1.57-4.77.21-.71.43-1.37.6-2s.3-1.17.43-1.68c.26-1,.46-1.75.61-2.32A4.79,4.79,0,0,1,92.28,379.91Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M102.53,384.33a5,5,0,0,1-.17.87c-.14.61-.33,1.43-.58,2.47-.13.54-.28,1.14-.44,1.79s-.39,1.34-.62,2.09c-.47,1.49-.92,3.23-1.65,5.06L98,399.52c-.37,1-.86,2-1.31,3.1-.87,2.15-2,4.36-3.17,6.69a109.87,109.87,0,0,1-9.1,14.34,112.16,112.16,0,0,1-11.33,12.63c-1.92,1.77-3.74,3.47-5.56,4.91-.9.73-1.74,1.48-2.61,2.11l-2.49,1.85c-1.55,1.21-3.08,2.15-4.38,3s-2.45,1.58-3.4,2.13l-2.2,1.28a6.29,6.29,0,0,1-.78.42,5.85,5.85,0,0,1,.73-.51l2.14-1.36c.94-.58,2.08-1.29,3.35-2.2s2.79-1.86,4.33-3.08L64.67,443c.86-.64,1.69-1.4,2.58-2.13,1.81-1.45,3.6-3.15,5.51-4.92A117.36,117.36,0,0,0,84,423.33a112.4,112.4,0,0,0,9.08-14.24c1.18-2.32,2.32-4.51,3.21-6.65.45-1.06,1-2.06,1.32-3.07s.75-2,1.1-2.88c.75-1.83,1.22-3.54,1.72-5,.24-.75.49-1.44.67-2.08s.33-1.23.47-1.77c.29-1,.51-1.84.68-2.44A7.62,7.62,0,0,1,102.53,384.33Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M112,392.12a5.57,5.57,0,0,1-.22.86c-.18.61-.43,1.42-.74,2.46-.16.53-.35,1.12-.54,1.76s-.48,1.33-.75,2.08c-.56,1.47-1.13,3.18-2,5l-1.23,2.87c-.43,1-.95,2-1.46,3.06-1,2.12-2.22,4.3-3.48,6.61A125.67,125.67,0,0,1,92.07,431a124,124,0,0,1-11.6,12.59c-2,1.77-3.78,3.47-5.63,4.92-.91.73-1.76,1.47-2.64,2.12l-2.51,1.86c-1.58,1.21-3.11,2.16-4.42,3-.66.43-1.27.85-1.85,1.2l-1.59.94-2.22,1.28a6.74,6.74,0,0,1-.79.42,5.61,5.61,0,0,1,.74-.51l2.16-1.36,1.57-1c.57-.36,1.17-.79,1.82-1.23,1.29-.91,2.8-1.87,4.36-3.1.8-.6,1.63-1.22,2.49-1.88s1.7-1.4,2.61-2.14c1.82-1.45,3.64-3.16,5.57-4.92a129.09,129.09,0,0,0,11.52-12.57,128.56,128.56,0,0,0,9.58-14.09c1.27-2.3,2.52-4.45,3.52-6.56.51-1,1-2,1.48-3s.85-1.95,1.25-2.85c.84-1.8,1.43-3.5,2-5,.29-.73.56-1.41.79-2.05s.41-1.22.59-1.75c.35-1,.63-1.82.83-2.42A6,6,0,0,1,112,392.12Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M123.37,433.25a4,4,0,0,1-1-.4c-.6-.28-1.47-.72-2.53-1.27-2.12-1.1-5-2.7-8.15-4.58s-5.89-3.69-7.86-5.05c-1-.68-1.77-1.24-2.31-1.65a4.31,4.31,0,0,1-.8-.66,4,4,0,0,1,.92.5l2.39,1.5c2,1.28,4.81,3,7.93,4.91s6,3.53,8.06,4.71l2.45,1.41A3.67,3.67,0,0,1,123.37,433.25Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M125.2,423.87a25.66,25.66,0,0,1-2.9-1.9c-1.77-1.21-4.23-2.85-7-4.55s-5.38-3.12-7.26-4.16a24.49,24.49,0,0,1-3-1.76,3.3,3.3,0,0,1,.88.34c.55.24,1.33.61,2.29,1.09,1.92,1,4.55,2.35,7.36,4.05s5.24,3.4,7,4.69c.86.65,1.55,1.18,2,1.57S125.23,423.85,125.2,423.87Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M98.49,461.2c-.15,0-.49-7.94-.76-17.75s-.38-17.77-.24-17.77.48,7.94.76,17.75S98.63,461.2,98.49,461.2Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M77.12,466.17a93,93,0,0,1-.26-9.93,92.88,92.88,0,0,1,.26-9.93,96.15,96.15,0,0,1,.26,9.93A96.31,96.31,0,0,1,77.12,466.17Z"
                style={{fill:'#FF725E'}}/>
            <path d="M87.62,465c-.14,0-.22-6.5-.16-14.51s.21-14.52.36-14.52.21,6.5.16,14.52S87.76,465,87.62,465Z"
                  style={{fill:'#FF725E'}}/>
            <path
                d="M65.46,429.79c-.08.12-5.74-3.53-12.63-8.16S40.4,413.15,40.48,413s5.74,3.53,12.64,8.16S65.54,429.67,65.46,429.79Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M57.91,436a2.78,2.78,0,0,1-.86-.28c-.53-.21-1.3-.55-2.23-1a73,73,0,0,1-7-3.91c-2.66-1.68-5-3.35-6.62-4.59a18.4,18.4,0,0,1-2.57-2.12,24.24,24.24,0,0,1,2.79,1.82c1.69,1.17,4,2.78,6.68,4.45s5.12,3.05,6.93,4A28.14,28.14,0,0,1,57.91,436Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M83.93,403.6a9.59,9.59,0,0,1-1.52.58,18.05,18.05,0,0,1-1.84.57c-.72.2-1.54.43-2.46.62a54.41,54.41,0,0,1-6.54,1,70.09,70.09,0,0,1-8.1.25,71.56,71.56,0,0,1-8.07-.75,57.93,57.93,0,0,1-6.47-1.38c-.91-.23-1.72-.49-2.43-.72s-1.33-.44-1.81-.64a9.47,9.47,0,0,1-1.51-.64,10,10,0,0,1,1.57.45c.5.17,1.11.36,1.83.55s1.53.44,2.44.65c1.82.47,4,.87,6.45,1.26a79.09,79.09,0,0,0,8,.7,77,77,0,0,0,8-.21A60.63,60.63,0,0,0,78,405c.93-.16,1.75-.37,2.47-.54s1.35-.33,1.85-.48A8.4,8.4,0,0,1,83.93,403.6Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M88.72,392.38a10.06,10.06,0,0,1-1.47.68c-.48.22-1.08.44-1.78.69s-1.5.54-2.39.8a59.7,59.7,0,0,1-6.39,1.58,71.49,71.49,0,0,1-8,1,68,68,0,0,1-8.06,0,49.21,49.21,0,0,1-6.52-.86c-.93-.16-1.75-.38-2.47-.57a18,18,0,0,1-1.82-.55,10.15,10.15,0,0,1-1.52-.58,8.4,8.4,0,0,1,1.58.39c.49.15,1.11.3,1.83.45s1.54.36,2.46.5a56.88,56.88,0,0,0,6.49.74,74.2,74.2,0,0,0,8-.07,79.76,79.76,0,0,0,7.94-1c2.41-.47,4.58-.94,6.36-1.46.9-.24,1.7-.5,2.41-.73s1.31-.41,1.8-.6A8.62,8.62,0,0,1,88.72,392.38Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M91.68,382.5a6.22,6.22,0,0,1-1.32.55c-.86.33-2.13.76-3.7,1.24a83.39,83.39,0,0,1-12.57,2.83,73,73,0,0,1-12.87.73c-1.65-.07-3-.17-3.9-.28a7.41,7.41,0,0,1-1.42-.22,8,8,0,0,1,1.44,0c.91.05,2.24.09,3.89.11A85.49,85.49,0,0,0,74,386.61a102.8,102.8,0,0,0,12.55-2.67c1.58-.44,2.86-.81,3.74-1.08A8.56,8.56,0,0,1,91.68,382.5Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M109.2,425.15a69.82,69.82,0,0,1-5.55,6.45,70.78,70.78,0,0,1-5.93,6.11,70.65,70.65,0,0,1,5.55-6.46A69.7,69.7,0,0,1,109.2,425.15Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M117.52,430.07a3.25,3.25,0,0,1-.45.84c-.32.53-.82,1.26-1.47,2.15a40.33,40.33,0,0,1-12.79,11.26c-1,.53-1.75.93-2.31,1.18a3.42,3.42,0,0,1-.89.34c0-.07,1.16-.7,3-1.84a51.5,51.5,0,0,0,6.84-5,50.82,50.82,0,0,0,5.84-6.15C116.68,431.13,117.45,430,117.52,430.07Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M120.05,434.51a14.14,14.14,0,0,1-5.61,10,38.22,38.22,0,0,0,3.42-4.67A39.12,39.12,0,0,0,120.05,434.51Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M128.44,443.7a9,9,0,0,1-2.13,4.33,8.87,8.87,0,0,1-3.78,3,37.45,37.45,0,0,0,3.38-3.33A40.83,40.83,0,0,0,128.44,443.7Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M142,452a9,9,0,0,1-1.3,4.91,8.78,8.78,0,0,1-3.43,3.74,27.8,27.8,0,0,0,3-4A28.25,28.25,0,0,0,142,452Z"
                style={{fill:'#FF725E'}}/>
            <path d="M157.53,459.46a7.61,7.61,0,0,1-.49,4.84,7.71,7.71,0,0,1-2.92,3.88,14.26,14.26,0,0,0,3.41-8.72Z"
                  style={{fill:'#FF725E'}}/>
            <path d="M171.87,472.25c-.15-.05.45-2,.59-4.44s-.21-4.45-.06-4.48a12.21,12.21,0,0,1-.53,8.92Z"
                  style={{fill:'#FF725E'}}/>
            <path d="M183.62,473.11c-.14-.06.58-1.8.77-4.07s-.2-4.1,0-4.13a9.59,9.59,0,0,1-.73,8.2Z"
                  style={{fill:'#FF725E'}}/>
            <path
                d="M200.06,473c-.15,0,.09-1.71-.18-3.76s-.91-3.62-.77-3.68a7,7,0,0,1,1.29,3.62A6.89,6.89,0,0,1,200.06,473Z"
                style={{fill:'#FF725E'}}/>
            <path d="M211.42,472c-.16,0-.16-1.61-.7-3.48s-1.35-3.26-1.22-3.34a8.35,8.35,0,0,1,1.92,6.82Z"
                  style={{fill:'#FF725E'}}/>
            <path d="M224.75,469.07c-.16,0-.08-1.57-.67-3.36s-1.54-3-1.41-3.12a6.79,6.79,0,0,1,2.08,6.48Z"
                  style={{fill:'#FF725E'}}/>
            <path d="M241.16,465.73c-.16,0-.18-1.75-.91-3.74s-1.83-3.35-1.71-3.45a8.2,8.2,0,0,1,2.62,7.19Z"
                  style={{fill:'#FF725E'}}/>
            <path d="M255,460.33c-.15,0-.7-1.77-1.88-3.75s-2.49-3.34-2.38-3.45a11.3,11.3,0,0,1,4.26,7.2Z"
                  style={{fill:'#FF725E'}}/>
            <path d="M270.47,452.22c-.15,0-.62-1.88-1.88-3.9s-2.74-3.3-2.64-3.42a10.06,10.06,0,0,1,4.52,7.32Z"
                  style={{fill:'#FF725E'}}/>
            <path
                d="M285.58,441.39a29.08,29.08,0,0,0-2.21-3.35,27.93,27.93,0,0,0-3-2.66,6.47,6.47,0,0,1,3.41,2.32A6.55,6.55,0,0,1,285.58,441.39Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M298.52,430.34c-.13.09-1.33-1.52-3.15-3.16s-3.52-2.71-3.44-2.85a10.37,10.37,0,0,1,3.79,2.46A10.48,10.48,0,0,1,298.52,430.34Z"
                style={{fill:'#FF725E'}}/>
            <path d="M310.11,415.93c-.13.08-1.13-1.54-2.89-3s-3.51-2.2-3.45-2.35a9.53,9.53,0,0,1,6.34,5.36Z"
                  style={{fill:'#FF725E'}}/>
            <path
                d="M320.44,398.78a40.4,40.4,0,0,0-3.65-2.6,40.15,40.15,0,0,0-4.14-1.69,8.22,8.22,0,0,1,4.39,1.24A8.1,8.1,0,0,1,320.44,398.78Z"
                style={{fill:'#FF725E'}}/>
            <path d="M326.72,380.25a15.8,15.8,0,0,0-8.92-2.1,9.33,9.33,0,0,1,8.92,2.1Z" style={{fill:'#FF725E'}}/>
            <path d="M329.51,360.35c-.05.15-1.68-.42-3.77-.64s-3.8,0-3.82-.2a10.16,10.16,0,0,1,7.59.84Z"
                  style={{fill:'#FF725E'}}/>
            <path d="M328.48,340.42a16.91,16.91,0,0,1-2.84.12,17.94,17.94,0,0,1-2.83.14,7.86,7.86,0,0,1,5.67-.26Z"
                  style={{fill:'#FF725E'}}/>
            <path
                d="M324.4,326.73a13.08,13.08,0,0,0-3-.27,13.39,13.39,0,0,0-2.84.89c0-.05.18-.34.66-.66a4.86,4.86,0,0,1,2.12-.75,4.79,4.79,0,0,1,2.23.28C324.15,326.43,324.43,326.67,324.4,326.73Z"
                style={{fill:'#FF725E'}}/>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M256.58,394c4.85,9.21,16.16,13.94,26.5,12.68s19.55-7.69,26-15.89c4.75-6.06,8.27-13.91,6.31-21.35A46.87,46.87,0,0,1,256,392.74'"/>
            </g>
            <path
                d="M317.1,314.75a16.57,16.57,0,0,1,4.28-22.51c2.76-2,6.09-3.07,8.7-5.23a15.92,15.92,0,0,0,5.4-11.12,24,24,0,0,0-2.73-12.25l2.78-1.37.24.3a20.61,20.61,0,0,1-7.34,31,28.2,28.2,0,0,0-5.83,3.59c-3.47,3-2.05,6.41-2.05,6.41"
                style={{fill:'#FF725E'}}/>
            <g style={{opacity: '0.7000000000000001'}}>
                <path
                    d="M317.1,314.75a16.57,16.57,0,0,1,4.28-22.51c2.76-2,6.09-3.07,8.7-5.23a15.92,15.92,0,0,0,5.4-11.12,24,24,0,0,0-2.73-12.25l2.78-1.37.24.3a20.61,20.61,0,0,1-7.34,31,28.2,28.2,0,0,0-5.83,3.59c-3.47,3-2.05,6.41-2.05,6.41"
                    style={{fill:'#fff'}}/>
            </g>
            <path d="M321,303.75a15.92,15.92,0,0,0-3.44-.3,15.53,15.53,0,0,0-3.34.93,5.66,5.66,0,0,1,6.78-.63Z"
                  style={{fill:'#FF725E'}}/>
            <path
                d="M323.62,296.12c-.14.05-.58-.82-1.4-1.64s-1.68-1.28-1.63-1.42,1.09.13,2,1.05S323.75,296.08,323.62,296.12Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M333.43,290.32c-.14.05-.5-.92-1.29-1.87s-1.64-1.51-1.57-1.64,1.12.26,2,1.32S333.57,290.3,333.43,290.32Z"
                style={{fill:'#FF725E'}}/>
            <path d="M340,277.88a13.77,13.77,0,0,1-2.27-.35,13.25,13.25,0,0,1-2.28-.25,4.87,4.87,0,0,1,4.55.6Z"
                  style={{fill:'#FF725E'}}/>
            <path d="M337.77,265.86c0,.15-.85.1-1.78.38s-1.62.73-1.72.62.5-.82,1.57-1.12S337.81,265.73,337.77,265.86Z"
                  style={{fill:'#FF725E'}}/>
            <path
                d="M245.21,339.89c3.54-2.3,8.23-1.54,12.25-.21s8.05,3.17,12.26,2.77c4.59-.43,8.52-3.49,11.55-6.95a39.87,39.87,0,0,0,8.17-14.75c1.66-5.54,2.51-12.05,7.28-15.33s11.39-1.63,15.49,2.25,6.08,9.48,7.38,15c3,12.72,3.05,26.29-1.07,38.7s-12.59,23.53-24.09,29.75-26,7.11-37.7,1.29"
                style={{fill:'#FF725E'}}/>
            <path
                d="M256.73,392.38a5.32,5.32,0,0,1,.54.22l1.56.68c.7.27,1.57.59,2.6,1s2.25.69,3.64,1a41.53,41.53,0,0,0,10.26.91,43.08,43.08,0,0,0,6.4-.74,40.54,40.54,0,0,0,6.95-1.92A44.63,44.63,0,0,0,302.8,385a52.79,52.79,0,0,0,11.69-14.87,59.17,59.17,0,0,0,6.34-19.91,74,74,0,0,0-.48-22.48,63.21,63.21,0,0,0-2.81-11.07,22.37,22.37,0,0,0-5.94-9.17,14,14,0,0,0-9.71-3.5,9.83,9.83,0,0,0-4.81,1.5,10.83,10.83,0,0,0-3.4,3.6c-1.72,2.84-2.46,6.08-3.3,9.14a43.34,43.34,0,0,1-3.16,8.74,39.42,39.42,0,0,1-4.67,7.32,28.27,28.27,0,0,1-5.76,5.57,14.93,14.93,0,0,1-6.73,2.73,16.53,16.53,0,0,1-6.45-.69c-3.89-1.15-7.07-2.63-9.88-3.08a13.37,13.37,0,0,0-6.44.16,9.28,9.28,0,0,0-1.56.65c-.34.19-.52.27-.52.27l.49-.31a8.39,8.39,0,0,1,1.55-.71,13.21,13.21,0,0,1,6.52-.26c2.84.43,6.06,1.89,9.92,3a16.24,16.24,0,0,0,6.33.64,14.58,14.58,0,0,0,6.56-2.7,27.81,27.81,0,0,0,5.66-5.52,39.83,39.83,0,0,0,4.59-7.26,42.77,42.77,0,0,0,3.11-8.67c.83-3.05,1.56-6.33,3.33-9.27a11.19,11.19,0,0,1,3.55-3.76,10.16,10.16,0,0,1,5-1.59,13.51,13.51,0,0,1,5.33.83,15,15,0,0,1,4.75,2.78,22.91,22.91,0,0,1,6.08,9.37,63.38,63.38,0,0,1,2.84,11.18,73.68,73.68,0,0,1,.47,22.63,59.41,59.41,0,0,1-6.42,20.07,53,53,0,0,1-11.83,15,44.86,44.86,0,0,1-14.27,8.5,41.36,41.36,0,0,1-7,1.9,43.58,43.58,0,0,1-6.45.7,41.1,41.1,0,0,1-10.32-1c-1.39-.3-2.59-.7-3.64-1s-1.9-.71-2.59-1l-1.54-.73C256.9,392.48,256.73,392.38,256.73,392.38Z"
                style={{fill:'#263238'}}/>
            <path
                d="M299.77,317.15a4.42,4.42,0,0,1-1.2-1.56,9.5,9.5,0,0,1-1.1-4.64,9.65,9.65,0,0,1,1.23-4.6,4.21,4.21,0,0,1,1.25-1.53c.07.06-.41.64-.92,1.7a10.67,10.67,0,0,0-.13,8.91C299.39,316.5,299.85,317.1,299.77,317.15Z"
                style={{fill:'#263238'}}/>
            <path
                d="M305.84,316.27a5,5,0,0,1-1.06-1.64,12.78,12.78,0,0,1-1.16-4.6,12.55,12.55,0,0,1,.6-4.7,4.88,4.88,0,0,1,.85-1.76,16,16,0,0,0,.77,12.7Z"
                style={{fill:'#263238'}}/>
            <path
                d="M310.7,318a10.32,10.32,0,0,1-.76-4.8,10.5,10.5,0,0,1,1-4.76c.15.05-.42,2.13-.48,4.77S310.85,318,310.7,318Z"
                style={{fill:'#263238'}}/>
            <path
                d="M394.19,330.5c-9.15,5.58-21.32,4.52-30.8-.49s-16.66-13.4-22.65-22.2c-1.7-2.5-3.42-5.15-6.06-6.66-5.19-3-12.36-.1-15.34,5s-2.42,11.64-.12,17.1c0,0,6.1,29.56,37.9,47.94"
                style={{fill:'#FF725E'}}/>
            <path
                d="M357.12,371.22a1,1,0,0,1-.27-.14l-.78-.46c-.7-.43-1.69-1.05-3-1.83-.63-.42-1.39-.82-2.14-1.38l-2.45-1.79c-.87-.67-1.87-1.3-2.81-2.13l-3-2.57A83.34,83.34,0,0,1,329.56,346a76.25,76.25,0,0,1-6-10.38A63.9,63.9,0,0,1,319,323.33l0,.05a23,23,0,0,1-1.85-11.07,15.89,15.89,0,0,1,1.66-5.67,12.92,12.92,0,0,1,12.43-6.86,9.69,9.69,0,0,1,4.11,1.48,13.83,13.83,0,0,1,3.12,2.93c.89,1.1,1.67,2.26,2.43,3.39s1.55,2.24,2.33,3.32c1.58,2.17,3.2,4.24,4.87,6.17a59.87,59.87,0,0,0,10.63,9.82A39.25,39.25,0,0,0,370,332.5a32.18,32.18,0,0,0,18,.56,13.22,13.22,0,0,0,1.46-.45c.45-.16.88-.29,1.26-.45.76-.34,1.42-.6,1.92-.86l1.15-.61a2.61,2.61,0,0,1,.4-.19,2.67,2.67,0,0,1-.37.24l-1.13.65c-.5.28-1.15.55-1.91.91-.39.17-.81.31-1.26.48a12.68,12.68,0,0,1-1.47.48,32,32,0,0,1-18.19-.41,39.21,39.21,0,0,1-11.39-5.61,60,60,0,0,1-10.74-9.86c-1.69-1.94-3.32-4-4.91-6.18-.79-1.09-1.57-2.21-2.34-3.34s-1.54-2.28-2.41-3.35a13.67,13.67,0,0,0-3-2.82,9.1,9.1,0,0,0-3.89-1.39,11.89,11.89,0,0,0-8.08,2.23,13.34,13.34,0,0,0-5.44,9.83,22.63,22.63,0,0,0,1.8,10.82v.05a63.64,63.64,0,0,0,4.5,12.21,76.75,76.75,0,0,0,6,10.34,83.37,83.37,0,0,0,13,14.9l3,2.59c.93.84,1.92,1.47,2.77,2.15l2.43,1.82c.74.57,1.49,1,2.12,1.4l2.93,1.9.75.5A1.35,1.35,0,0,1,357.12,371.22Z"
                style={{fill:'#263238'}}/>
            <path
                d="M327.82,317.89a29.51,29.51,0,0,0-3.17-6.87c-2.34-3.5-5.32-5.34-5.2-5.48a6.93,6.93,0,0,1,1.91,1.19,17.91,17.91,0,0,1,6.06,8.93A6.57,6.57,0,0,1,327.82,317.89Z"
                style={{fill:'#263238'}}/>
            <path
                d="M334,313.88a29.24,29.24,0,0,0-2.16-7.36,29.67,29.67,0,0,0-4.43-6.26,7.31,7.31,0,0,1,1.75,1.48,18,18,0,0,1,3.15,4.56,18.36,18.36,0,0,1,1.62,5.29A6.7,6.7,0,0,1,334,313.88Z"
                style={{fill:'#263238'}}/>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M313.33,372.57c2.49-3.81-7.77-4.71-12.29-4.18a26.87,26.87,0,0,0-23.8,27.09S297,397.59,313.33,372.57Z"
                    style={{fill:'#fff'}}/>
            </g>
            <g style={{opacity: '0.30000000000000004'}}>
                <path
                    d="M330.18,355.56a42.57,42.57,0,0,0,21.9,17,5.67,5.67,0,0,0,3,.42,2.18,2.18,0,0,0,1.86-2.07,77.16,77.16,0,0,1-26.74-24.11,15.13,15.13,0,0,0,1.26,9.06'"/>
            </g>
            <circle cx="300.48" cy="272.13" r="22.55" transform="translate(-104.42 292.18) rotate(-45)"
                    style={{fill:'#f5f5f5'}}/>
            <path
                d="M323,273.08c0,10.89-5.72,20-12.09,20s-11.54-8.83-11.54-19.71,5.17-19.71,11.54-19.71S323,262.2,323,273.08Z"
                style={{fill:'#263238'}}/>
            <path
                d="M305.3,282.43a3,3,0,0,1-.46-.74,16.62,16.62,0,0,1-.93-2.19,21.86,21.86,0,0,1,.41-15.42,14.89,14.89,0,0,1,1.06-2.13,2.94,2.94,0,0,1,.49-.72c.08,0-.52,1.12-1.2,3a24.39,24.39,0,0,0-1.44,7.56,24.68,24.68,0,0,0,1,7.62C304.84,281.28,305.39,282.39,305.3,282.43Z"
                style={{fill:'#fff'}}/>
            <circle cx="313.79" cy="260.73" r="3.86" style={{fill:'#fff'}}/>
            <path
                d="M315.08,244.3c-.43.59-1.52.58-2.89.36s-3.09-.62-4.95-.92-3.54-.48-4.89-.67-2.39-.46-2.66-1.14.38-1.65,1.9-2.46a9.19,9.19,0,0,1,2.85-.91,11.86,11.86,0,0,1,3.66,0,11,11,0,0,1,5.84,2.82C315.14,242.62,315.49,243.73,315.08,244.3Z"
                style={{fill:'#263238'}}/>
            <path d="M304.86,300.29a16.42,16.42,0,0,1,27.12-18" style={{fill:'#FF725E'}}/>
            <path
                d="M332,282.29s-.48-.45-1.37-1.22a16.24,16.24,0,0,0-4.24-2.6,17.52,17.52,0,0,0-7.19-1.38A15.22,15.22,0,0,0,305,286.48a17.56,17.56,0,0,0-1.52,7.16,16.43,16.43,0,0,0,.75,4.91c.36,1.12.64,1.72.59,1.74a1.93,1.93,0,0,1-.23-.41,10.8,10.8,0,0,1-.55-1.26,15,15,0,0,1-.92-5,17.21,17.21,0,0,1,1.44-7.36,15.51,15.51,0,0,1,14.58-9.68,17.4,17.4,0,0,1,7.34,1.53,15.07,15.07,0,0,1,4.22,2.78,9.83,9.83,0,0,1,.95,1A2.13,2.13,0,0,1,332,282.29Z"
                style={{fill:'#263238'}}/>
            <path
                d="M260.08,214.9a1.66,1.66,0,0,1-.51.17l-1.49.4c-.65.16-1.44.39-2.35.67l-1.45.43-1.62.55a86.59,86.59,0,0,0-16.82,8A136.46,136.46,0,0,0,220.64,236c-1.85,1.52-3.34,2.76-4.35,3.64l-1.19,1c-.27.23-.42.34-.44.32a2.45,2.45,0,0,1,.37-.39l1.12-1.07c1-.92,2.43-2.2,4.25-3.77a121.82,121.82,0,0,1,15.17-11,79.32,79.32,0,0,1,17-7.94l1.63-.53,1.48-.39c.92-.26,1.71-.46,2.37-.59l1.52-.3A2.14,2.14,0,0,1,260.08,214.9Z"
                style={{fill:'#263238'}}/>
        </g>
        <g id="freepik--404-error--inject-7">
            <path
                d="M115.06,92.78a5.68,5.68,0,0,1-.41-1.17c-.12-.38-.25-.86-.37-1.41S114,89,113.9,88.3a34.61,34.61,0,0,1-.46-5,38.38,38.38,0,0,1,.42-6.15,39.62,39.62,0,0,1,1.47-6,34.42,34.42,0,0,1,2-4.63c.31-.65.66-1.2.94-1.7s.55-.9.78-1.23a5.82,5.82,0,0,1,.75-1c.08.05-.91,1.52-2.14,4.08a39.27,39.27,0,0,0-1.84,4.62,43.37,43.37,0,0,0-1.42,5.93,42.7,42.7,0,0,0-.45,6.07,38.92,38.92,0,0,0,.35,5C114.66,91.06,115.16,92.76,115.06,92.78Z"
                style={{fill:'#263238'}}/>
            <rect x="143.89" y="50.7" width="220.56" height="124.06" transform="translate(-16.33 48.4) rotate(-10.54)"
                  style={{fill:'#fff'}}/>
            <path
                d="M373.94,153.54s-.16-.74-.43-2.18-.68-3.58-1.21-6.37c-1.05-5.58-2.6-13.81-4.58-24.37-4-21.11-9.66-51.57-16.68-89l.24.16L134.46,72.17h0l.21-.31c8,43.14,15.69,84.4,22.68,122l-.29-.2,156-28.91,44.81-8.27,11.94-2.19,3.08-.56,1.06-.18-1,.21-3.05.58L358,156.57,313.26,165,157.14,194.13l-.24.05,0-.24c-7-37.58-14.68-78.83-22.72-122l0-.26.25,0h0L351.2,31.37l.2,0,0,.19,16.48,89.18c1.94,10.55,3.45,18.76,4.48,24.34l1.16,6.34C373.82,152.82,373.94,153.54,373.94,153.54Z"
                style={{fill:'#263238'}}/>
            <path
                d="M227.67,118.66,221,119.9l1.6,8.57-12.34,2.3-1.6-8.57L185,126.6l-1.54-8.25,14.88-31.67,13.11-2.44-13.36,29.54,9.08-1.69-1.42-7.61,12-2.22,1.42,7.6,6.64-1.23Z"
                style={{fill:'#263238'}}/>
            <path
                d="M227.66,104.38c-2.75-14.77,4-24.82,15.43-26.95s21.27,4.84,24,19.61-4,24.82-15.36,26.93S230.41,119.15,227.66,104.38Zm26.73-5C252.64,90,249,87,245,87.79s-6.38,4.82-4.63,14.22,5.34,12.37,9.43,11.61S256.14,108.8,254.39,99.4Z"
                style={{fill:'#263238'}}/>
            <path
                d="M316.23,102.18l-6.65,1.24,1.59,8.57-12.33,2.3-1.6-8.57-23.66,4.4-1.53-8.25L286.93,70.2,300,67.76,286.68,97.3l9.08-1.69L294.34,88l12-2.22,1.41,7.6,6.65-1.23Z"
                style={{fill:'#263238'}}/>
            <path
                d="M232.08,154.75l.41,2.18-12.74,2.37-3.27-17.56,12.39-2.3.4,2.18-9.88,1.84,1,5.39,8.81-1.64.4,2.14L220.79,151l1.06,5.66Z"
                style={{fill:'#263238'}}/>
            <path
                d="M248.28,154l-4.79-4.69c-.34.09-.71.19-1.09.26l-4.34.81,1,5.34-2.5.47-3.27-17.56,6.85-1.28c4.56-.85,7.75.95,8.46,4.76a5.64,5.64,0,0,1-2.83,6.26l5.26,5.12Zm-2.2-11.42c-.47-2.51-2.41-3.63-5.62-3l-4.27.79,1.47,7.9,4.27-.79C245.14,146.84,246.55,145.08,246.08,142.57Z"
                style={{fill:'#263238'}}/>
            <path
                d="M266.51,150.6l-4.79-4.69c-.34.09-.71.19-1.09.26l-4.34.8,1,5.35-2.5.46-3.27-17.55,6.85-1.28c4.56-.85,7.75,1,8.46,4.76A5.64,5.64,0,0,1,264,145l5.26,5.12Zm-2.2-11.42c-.47-2.51-2.41-3.63-5.62-3l-4.27.79,1.47,7.9,4.27-.79C263.37,143.45,264.78,141.68,264.31,139.18Z"
                style={{fill:'#263238'}}/>
            <path
                d="M269.94,140.88c-.95-5.14,2.29-9.72,7.69-10.72s10,2.08,11,7.25-2.29,9.71-7.63,10.71S270.9,146,269.94,140.88Zm16.16-3a6.94,6.94,0,1,0-5.55,8A6.71,6.71,0,0,0,286.1,137.87Z"
                style={{fill:'#263238'}}/>
            <path
                d="M305.81,143.29,301,138.6c-.35.09-.72.18-1.1.25l-4.34.81,1,5.34-2.51.47-3.27-17.56,6.85-1.27c4.57-.85,7.75.94,8.46,4.76a5.62,5.62,0,0,1-2.83,6.26l5.26,5.12Zm-2.21-11.43c-.46-2.5-2.41-3.62-5.62-3l-4.26.79,1.47,7.9,4.26-.79C302.66,136.14,304.07,134.37,303.6,131.86Z"
                style={{fill:'#263238'}}/>
            <path d="M346.67,44.78A4.52,4.52,0,1,1,342,40.44,4.52,4.52,0,0,1,346.67,44.78Z" style={{fill:'#e0e0e0'}}/>
            <path
                d="M346.67,44.78c-.05,0-.06-.4-.28-1.07a4.57,4.57,0,0,0-1.75-2.3,4.32,4.32,0,0,0-6.74,3.72,4.32,4.32,0,0,0,7,3.17,4.5,4.5,0,0,0,1.56-2.43c.17-.69.15-1.09.2-1.09s0,.1.05.29a3.32,3.32,0,0,1-.05.84,4.47,4.47,0,0,1-1.53,2.67,4.69,4.69,0,0,1-4.43.91,4.74,4.74,0,0,1-.35-8.93,4.68,4.68,0,0,1,4.49.55,4.5,4.5,0,0,1,1.73,2.55,2.94,2.94,0,0,1,.11.83C346.69,44.68,346.69,44.78,346.67,44.78Z"
                style={{fill:'#263238'}}/>
            <path
                d="M344.15,41.64a5.08,5.08,0,0,1-.62,3.51,5.08,5.08,0,0,1-2.42,2.61,25.68,25.68,0,0,0,1.95-2.85A26.36,26.36,0,0,0,344.15,41.64Z"
                style={{fill:'#263238'}}/>
            <path
                d="M345,46.43c-.13.08-.73-1.11-2.09-1.81a9.65,9.65,0,0,0-2.69-.66c0-.07.3-.21.85-.25a4.06,4.06,0,0,1,2.08.45,4,4,0,0,1,1.56,1.44C345,46.07,345.1,46.41,345,46.43Z"
                style={{fill:'#263238'}}/>
            <rect x="133.1" y="108.24" width="14.55" height="6.58" transform="translate(-38.91 111.84) rotate(-38.57)"
                  style={{fill:'#e0e0e0'}}/>
            <path
                d="M148.11,109.57s-.46-.5-1.2-1.38l-3-3.65.27,0c-2.92,2.38-6.91,5.6-11.33,9.12,0,.33,0-.44,0-.36h0l0,0,0,0,.07.09.14.17.27.33.53.67,1,1.31,2,2.5-.35,0c3.28-2.59,6.13-4.79,8.18-6.37l2.42-1.84a8.42,8.42,0,0,1,.93-.66,6.55,6.55,0,0,1-.76.68l-2.3,1.92c-2,1.64-4.82,4-8.16,6.66l-.19.15-.16-.19-2-2.49c-.35-.43-.7-.86-1.05-1.31l-.53-.66-.27-.34-.14-.17-.06-.08,0,0v0h0c0,.08.08-.69,0-.36,4.42-3.53,8.45-6.7,11.42-9l.15-.11.12.14c1.27,1.64,2.28,3,3,3.88A12.4,12.4,0,0,1,148.11,109.57Z"
                style={{fill:'#263238'}}/>
            <path d="M130.08,120a6.07,6.07,0,0,0,8.53,1L131,111.5A6.07,6.07,0,0,0,130.08,120Z"
                  style={{fill:'#e0e0e0'}}/>
            <path
                d="M130.08,120a2.24,2.24,0,0,1-.47-.53,5.8,5.8,0,0,1-.82-1.82,5.94,5.94,0,0,1,0-3.06,6.25,6.25,0,0,1,2.11-3.31l.19-.15.15.19,3.8,4.73c1.34,1.68,2.61,3.29,3.77,4.76l.15.19-.19.15a6.31,6.31,0,0,1-3.69,1.33,6.12,6.12,0,0,1-3-.7,5.76,5.76,0,0,1-1.58-1.21,2.28,2.28,0,0,1-.42-.57,9.21,9.21,0,0,0,2.13,1.52,6,6,0,0,0,6.25-.75l0,.34-3.8-4.73-3.77-4.76.33,0a6,6,0,0,0-2.12,5.94A9.57,9.57,0,0,0,130.08,120Z"
                style={{fill:'#263238'}}/>
            <path d="M131.48,116.85a3.84,3.84,0,0,0-.63,4" style={{fill:'#e0e0e0'}}/>
            <path
                d="M130.85,120.89a2.28,2.28,0,0,1-.52-2.15c.19-1.3,1.07-2,1.15-1.89s-.48.85-.64,2S131,120.82,130.85,120.89Z"
                style={{fill:'#263238'}}/>
            <path
                d="M138.63,117.5a22.67,22.67,0,0,1-2.22-2.65,22.94,22.94,0,0,1-2.2-2.65,7.7,7.7,0,0,1,2.6,2.32A8,8,0,0,1,138.63,117.5Z"
                style={{fill:'#263238'}}/>
            <path d="M140,116.44a23.08,23.08,0,0,1-2.21-2.65,22.26,22.26,0,0,1-2.2-2.66,11.76,11.76,0,0,1,4.41,5.31Z"
                  style={{fill:'#263238'}}/>
            <path
                d="M141.3,115.37a22.07,22.07,0,0,1-2.21-2.65,22.46,22.46,0,0,1-2.21-2.65,7.78,7.78,0,0,1,2.61,2.32A8.1,8.1,0,0,1,141.3,115.37Z"
                style={{fill:'#263238'}}/>
            <path
                d="M142.63,114.31a23.08,23.08,0,0,1-2.21-2.65,23.5,23.5,0,0,1-2.2-2.66,8,8,0,0,1,2.6,2.32A7.85,7.85,0,0,1,142.63,114.31Z"
                style={{fill:'#263238'}}/>
            <path
                d="M144,113.24a22.07,22.07,0,0,1-2.21-2.65,21.75,21.75,0,0,1-2.2-2.65,7.91,7.91,0,0,1,2.6,2.32A8.1,8.1,0,0,1,144,113.24Z"
                style={{fill:'#263238'}}/>
            <path
                d="M145.3,112.18a23.08,23.08,0,0,1-2.21-2.65,23.5,23.5,0,0,1-2.2-2.66,8,8,0,0,1,2.6,2.32A7.85,7.85,0,0,1,145.3,112.18Z"
                style={{fill:'#263238'}}/>
            <path
                d="M146.64,111.11a22.07,22.07,0,0,1-2.21-2.65,21.75,21.75,0,0,1-2.2-2.65,7.91,7.91,0,0,1,2.6,2.32A8.1,8.1,0,0,1,146.64,111.11Z"
                style={{fill:'#263238'}}/>
            <path
                d="M154.5,82c-.05,0-.06-.41-.28-1.08a4.51,4.51,0,0,0-1.78-2.33,4.37,4.37,0,0,0-6.83,3.76,4.37,4.37,0,0,0,7.11,3.21,4.52,4.52,0,0,0,1.58-2.46c.17-.69.15-1.1.2-1.1s0,.1.05.29a3.41,3.41,0,0,1-.05.85A4.57,4.57,0,0,1,153,85.88a4.7,4.7,0,0,1-4.48.91,4.8,4.8,0,0,1-.36-9,4.77,4.77,0,0,1,4.55.56,4.58,4.58,0,0,1,1.75,2.58,3.61,3.61,0,0,1,.12.84Q154.53,82,154.5,82Z"
                style={{fill:'#263238'}}/>
            <path
                d="M124.8,93.08a6.56,6.56,0,0,1-.4-1.17,11.47,11.47,0,0,1-.37-1.42c-.12-.55-.29-1.18-.38-1.89a34.63,34.63,0,0,1-.47-5,35.49,35.49,0,0,1,1.9-12.15,33.87,33.87,0,0,1,2-4.64c.3-.65.65-1.2.93-1.69a14.38,14.38,0,0,1,.78-1.23,6.27,6.27,0,0,1,.75-1c.09.06-.91,1.52-2.13,4.09a36.28,36.28,0,0,0-1.85,4.62,42.17,42.17,0,0,0-1.41,5.92,41.39,41.39,0,0,0-.46,6.08,38.61,38.61,0,0,0,.35,5C124.4,91.36,124.9,93.05,124.8,93.08Z"
                style={{fill:'#263238'}}/>
            <path
                d="M388.49,137a5,5,0,0,1,.41,1.17,13.33,13.33,0,0,1,.37,1.41c.12.56.29,1.19.38,1.9a34.49,34.49,0,0,1,.47,5,35.59,35.59,0,0,1-1.9,12.15,33.18,33.18,0,0,1-2,4.63c-.31.65-.65,1.2-.94,1.7a11.66,11.66,0,0,1-.78,1.23,5.54,5.54,0,0,1-.74,1c-.09-.05.91-1.52,2.13-4.08a41,41,0,0,0,1.85-4.62,42,42,0,0,0,1.41-5.93,41.36,41.36,0,0,0,.46-6.07,40.84,40.84,0,0,0-.35-5C388.9,138.76,388.4,137.06,388.49,137Z"
                style={{fill:'#263238'}}/>
            <path
                d="M395,138.76a5.27,5.27,0,0,1,.41,1.17,13.33,13.33,0,0,1,.37,1.41c.12.56.28,1.19.38,1.9a34.49,34.49,0,0,1,.47,5,39.49,39.49,0,0,1-.42,6.15,38.43,38.43,0,0,1-1.48,6,33.87,33.87,0,0,1-2,4.64c-.31.64-.65,1.19-.94,1.69A13.54,13.54,0,0,1,391,168a5.54,5.54,0,0,1-.74,1c-.09-.05.91-1.52,2.13-4.08a39.51,39.51,0,0,0,1.85-4.62,42,42,0,0,0,1.41-5.93,41.16,41.16,0,0,0,.46-6.07,40.51,40.51,0,0,0-.35-5C395.4,140.48,394.9,138.78,395,138.76Z"
                style={{fill:'#263238'}}/>
        </g>
        <g id="freepik--speech-bubble--inject-7">
            <path d="M177.53,217.21a42.23,42.23,0,1,0-10.64,10l14.52,6.43Z" style={{fill:'#fafafa'}}/>
            <path
                d="M130.92,189.52a7.77,7.77,0,0,1,1.75-4.34,7.47,7.47,0,0,1,3.75-2.63,6,6,0,0,1,6.83,2.71A6.05,6.05,0,0,1,149.6,182a7.54,7.54,0,0,1,4,2.17,7.9,7.9,0,0,1,2.25,4.11,9.45,9.45,0,0,1-.84,5.9,13.41,13.41,0,0,1-4.36,4.6c-2.91,2-6.74,4.5-6.74,4.5s-3.51-2.31-6.63-3.95a13.43,13.43,0,0,1-4.88-4.05A9.49,9.49,0,0,1,130.92,189.52Z"
                style={{fill:'#FF725E'}}/>
            <path
                d="M143.68,149.83a4.16,4.16,0,0,0-.74,0l-2.15.07c-.48,0-1,0-1.59.07l-1.9.26-2.22.32-2.48.62a39.88,39.88,0,0,0-12,5.24,42.43,42.43,0,0,0-19.48,31.88,45,45,0,0,0-.12,5.82c.07,1,0,2,.19,3s.3,2,.46,3a42.52,42.52,0,0,0,4,11.83,45.2,45.2,0,0,0,7.72,10.63,44.59,44.59,0,0,0,11.13,8,40.3,40.3,0,0,0,13.57,4.16,25.75,25.75,0,0,0,3.62.31,31.85,31.85,0,0,0,3.66.07,43.62,43.62,0,0,0,7.33-.93A42.52,42.52,0,0,0,167.27,228l-.44.05,14.86,6,.88.36-.25-.92L178,217.55l-.07.39a42.6,42.6,0,0,0,7.92-18.15,38.17,38.17,0,0,0,.58-9.3A29.83,29.83,0,0,0,186,186c-.11-.73-.23-1.45-.34-2.17s-.35-1.41-.52-2.11a42.45,42.45,0,0,0-16.57-24.16,40.84,40.84,0,0,0-9.87-5.21l-2.22-.79-2.11-.54-1.93-.48c-.62-.12-1.22-.18-1.78-.27-1.12-.14-2.11-.33-3-.38l-2.19-.07-1.33,0a1.54,1.54,0,0,0-.45,0,2.62,2.62,0,0,0,.45.06l1.33.1,2.16.15c.86.07,1.84.28,2.95.45a42.25,42.25,0,0,1,33.79,31.34c.16.69.37,1.37.5,2.07s.21,1.42.32,2.13a28.89,28.89,0,0,1,.39,4.39,37.7,37.7,0,0,1-.6,9.12,41.75,41.75,0,0,1-7.82,17.75l-.13.18.06.22c1.4,5.13,2.84,10.44,4.33,15.89l.63-.56-14.85-6-.23-.09-.22.13a41.88,41.88,0,0,1-14.26,6,42.51,42.51,0,0,1-7.16.91,32.86,32.86,0,0,1-3.58-.07,26,26,0,0,1-3.53-.3,39.48,39.48,0,0,1-13.27-4.05A43.85,43.85,0,0,1,114,221.86a44.79,44.79,0,0,1-7.59-10.39,42,42,0,0,1-4-11.57c-.15-1-.3-2-.45-2.93s-.13-2-.2-2.92a41.86,41.86,0,0,1,19.07-37.21,40.31,40.31,0,0,1,11.82-5.33l2.45-.65,2.2-.36,1.88-.31c.58-.07,1.11-.08,1.58-.12l2.15-.16A6.08,6.08,0,0,0,143.68,149.83Z"
                style={{fill:'#263238'}}/>
        </g>
    </SVG>
);