import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { SVG } from "../../SVG";

export const DesktopCat: FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <SVG {...props} height='161px' width='97px' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 97 161" xmlSpace="preserve">
            <defs>
                <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse">
                    <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" id="mask0_1_">
                <path className={classes.st0} d="M-3.5-30.1h109c3.9,0,7,3.1,7,7v254c0,3.9-3.1,7-7,7h-109c-3.9,0-7-3.1-7-7v-254C-10.5-26.9-7.3-30.1-3.5-30.1
		z"/>
            </mask>
            <g className={classes.st1}>
            </g>
            <path className={classes.st2} d="M81.7,97.4h3v-6h-3c-1.7,0-3,1.3-3,3C78.7,96,80.1,97.4,81.7,97.4z"/>
            <path className={classes.st3} d="M85,72.4l-2.8,47.8c-0.1,0.9,0.6,1.6,1.5,1.7h0c0.9,0.1,1.5,0.8,1.5,1.7l-1.8,32.6c0,0.9,0.6,1.6,1.5,1.7
	l3.3,0.2c0.9,0.1,1.6-0.6,1.7-1.5l4.6-80.5c0-0.6-0.3-1.2-0.8-1.5l-6.2-3.4C86.3,70.6,85.1,71.3,85,72.4z"/>
            <path className={classes.st4} d="M94.9,156.1H59.3c-1,0-1.7,0.8-1.7,1.7c0,1,0.8,1.7,1.7,1.7h35.7c1,0,1.7-0.8,1.7-1.7
	C96.7,156.9,95.9,156.1,94.9,156.1z"/>
            <path className={classes.st5}
                  d="M78.7,94.3c0-1.5,1-2.7,2.4-2.9v-1.3l-7.3-3l0.7,15.1l6.6-3.9v-1.1C79.7,97,78.7,95.8,78.7,94.3z"/>
            <path className={classes.st3} d="M69.5,139.3h1.6c0.7,0,1.2-0.6,1.2-1.2V54.2c0-0.7-0.6-1.2-1.2-1.2h-1.6c-0.7,0-1.2,0.6-1.2,1.2v83.9
	C68.3,138.7,68.8,139.3,69.5,139.3z"/>
            <path className={classes.st5} d="M69.1,53c-0.4,0.2-0.8,0.6-0.8,1.1v83.9c0,0.5,0.3,1,0.8,1.1V53z"/>
            <path className={classes.st4} d="M72.3,138.1c0,0,10.7-38.2,0-83.9V138.1z"/>
            <ellipse className={classes.st6} cx="30.5" cy="149.9" rx="22" ry="3"/>
            <g>
                <defs>
                    <rect id="SVGID_DC1_" x="-0.5" y="23.5" width="33" height="33"/>
                </defs>
                <clipPath id="SVGID_DC2_">
                    <use xlinkHref="#SVGID_DC1_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st7}>
                    <path className={classes.st8} d="M26.7,35.2c-1.1-2.6-4-4-6.8-3.3c-1.1,0.3-2,0.8-2.7,1.6c-1.9-1.9-4.7-2.6-7.4-1.9c-4.1,1.1-6.6,5.4-5.5,9.5
			c0,0,0,0.1,0,0.1c-2.5,1.2-4,4-3.3,6.8c0.8,3.1,4.1,4.9,7.2,4.1l20.5-5.5c3.1-0.8,5-4,4.2-7.1C32.2,36.7,29.5,34.9,26.7,35.2z"/>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_DC3_" x="74.7" y="7.6"
                          transform="matrix(0.5107 -0.8598 0.8598 0.5107 26.4595 80.7562)" width="19" height="19"/>
                </defs>
                <clipPath id="SVGID_DC4_">
                    <use xlinkHref="#SVGID_DC3_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st9}>
                    <path className={classes.st8} d="M89.1,24l0.4-0.6l2.2-3.6l2.2-3.6c0.2-0.3,0.1-0.8-0.2-1L81.4,7.9c-0.3-0.2-0.8-0.1-1,0.2l-2.2,3.6l-2.2,3.6
			l-0.4,0.6L73.2,17c-0.3,0.5-0.1,1.1,0.4,1.4L88.1,27c0.5,0.3,1.1,0.1,1.4-0.4L89.1,24z M82.2,23.1l-2.4-1.4
			c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1l2.4,1.4c0.1,0,0.1,0.2,0.1,0.2C82.4,23.2,82.3,23.2,82.2,23.1z M78.9,20.3
			l0.8-0.3l4.1,2.4l0.1,0.8L78.9,20.3z M88.9,22.4c-0.2,0.3-0.6,0.4-0.8,0.2l-10.6-6.3c-0.3-0.2-0.4-0.6-0.2-0.8l3.8-6.3
			c0.2-0.3,0.6-0.4,0.8-0.2l10.6,6.3c0.3,0.2,0.4,0.6,0.2,0.8C92.7,16.1,88.9,22.4,88.9,22.4z"/>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_DC5_" x="46.2" y="36.2" transform="matrix(0.866 -0.5 0.5 0.866 -14.5817 32.6085)"
                          width="14.7" height="14.7"/>
                </defs>
                <clipPath id="SVGID_DC6_">
                    <use xlinkHref="#SVGID_DC5_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st10}>
                    <path className={classes.st8} d="M60,42.8l-0.3-0.5l-1.6-2.8l-1.6-2.8c-0.1-0.3-0.5-0.3-0.7-0.2l-9.4,5.4c-0.3,0.1-0.3,0.5-0.2,0.7l1.6,2.8
			l1.6,2.8l0.3,0.5l-0.4,2.1c0.2,0.4,0.7,0.5,1.1,0.3l11.3-6.5c0.4-0.2,0.5-0.7,0.3-1.1L60,42.8z M56.9,47.1L55,48.2
			c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.1,0-0.2l1.9-1.1c0.1,0,0.1,0,0.2,0C57,47,56.9,47,56.9,47.1z M53.7,48.3l0.1-0.6l3.2-1.8l0.6,0.2
			L53.7,48.3z M58.9,42.3c0.1,0.2,0.1,0.5-0.2,0.6l-8.2,4.7c-0.2,0.1-0.5,0.1-0.6-0.2L47,42.6c-0.1-0.2-0.1-0.5,0.2-0.6l8.2-4.7
			c0.2-0.1,0.5-0.1,0.6,0.2C56,37.4,58.9,42.3,58.9,42.3z"/>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_DC7_" x="15.2" y="9.2" transform="matrix(0.5 -0.866 0.866 0.5 -3.0229 27.7924)"
                          width="14.7" height="14.7"/>
                </defs>
                <clipPath id="SVGID_DC8_">
                    <use xlinkHref="#SVGID_DC7_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st11}>
                    <path className={classes.st8} d="M26.4,21.7l0.3-0.5l1.6-2.8l1.6-2.8c0.1-0.3,0.1-0.6-0.2-0.7l-9.4-5.4c-0.3-0.1-0.6-0.1-0.7,0.2L18,12.5
			l-1.6,2.8l-0.3,0.5l-2,0.7c-0.2,0.4-0.1,0.9,0.3,1.1l11.3,6.5c0.4,0.2,0.9,0.1,1.1-0.3L26.4,21.7z M21.1,21.2l-1.9-1.1
			c-0.1,0-0.1-0.1,0-0.2c0-0.1,0.1-0.1,0.2,0l1.9,1.1c0.1,0,0.1,0.1,0,0.2C21.3,21.2,21.2,21.2,21.1,21.2z M18.5,19l0.6-0.2l3.2,1.8
			l0.1,0.6L18.5,19z M26.3,20.5c-0.1,0.2-0.4,0.3-0.6,0.2L17.4,16c-0.2-0.1-0.3-0.4-0.2-0.6l2.8-4.9c0.1-0.2,0.4-0.3,0.6-0.2
			l8.2,4.7c0.2,0.1,0.3,0.4,0.2,0.6C29.1,15.6,26.3,20.5,26.3,20.5z"/>
                </g>
            </g>
            <path className={classes.st8} d="M45.6,20.4c0.3-1.7,1.7-3,3.5-3c0.7,0,1.3,0.2,1.8,0.5c0.8-1.4,2.3-2.3,4-2.3c2.6,0,4.7,2.1,4.7,4.7
	c0,0,0,0,0,0c1.7,0.3,3,1.7,3,3.5c0,1.9-1.6,3.5-3.6,3.5H46.1c-1.9,0-3.6-1.6-3.6-3.5C42.5,22.1,43.9,20.6,45.6,20.4z"/>
            <g>
                <defs>

                    <rect id="SVGID_DC9_" x="33.5" y="-2.5"
                          transform="matrix(0.2588 -0.9659 0.9659 0.2588 25.7038 42.4932)" width="14" height="14"/>
                </defs>
                <clipPath id="SVGID_DC10_">
                    <use xlinkHref="#SVGID_DC9_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st12}>
                    <path className={classes.st8} d="M36,2.5c0.5-1.1,1.7-1.7,2.9-1.4c0.4,0.1,0.8,0.4,1.2,0.7c0.8-0.8,2-1.1,3.1-0.8c1.7,0.5,2.8,2.3,2.3,4
			c0,0,0,0,0,0c1.1,0.5,1.7,1.7,1.4,2.9c-0.4,1.3-1.7,2.1-3,1.7l-8.7-2.3c-1.3-0.4-2.1-1.7-1.8-3C33.7,3.1,34.8,2.3,36,2.5z"/>
                </g>
            </g>
            <g>
                <defs>

                    <rect id="SVGID_DC11_" x="74.5" y="30.5"
                          transform="matrix(0.2588 -0.9659 0.9659 0.2588 23.2054 114.2371)" width="23" height="23"/>
                </defs>
                <clipPath id="SVGID_DC12_">
                    <use xlinkHref="#SVGID_DC11_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st13}>
                    <path className={classes.st8} d="M94.1,42.8c0.2-1.9-1-3.8-3-4.3c-0.7-0.2-1.5-0.2-2.2,0c-0.5-1.8-1.9-3.2-3.8-3.7c-2.9-0.8-5.8,0.9-6.6,3.8
			c0,0,0,0,0,0.1c-1.9-0.2-3.8,1-4.3,3c-0.6,2.2,0.7,4.4,2.9,5l14.3,3.8c2.2,0.6,4.4-0.7,5-2.8C97,45.6,95.9,43.6,94.1,42.8z"/>
                </g>
            </g>
            <path className={classes.st2} d="M43,113.9H17.1c-1.4,0-2.5,1-2.5,2.2v1.5h31v-1.5C45.5,114.9,44.4,113.9,43,113.9z"/>
            <path className={classes.st2} d="M40.2,119.3h-2.4l1.2,6.9H21.1l1.2-6.9h-2.4h-2.4l-2.9,31.6h1.2H17l3.5-21h19.1l3.6,21h1.2h1.2l-2.9-31.6H40.2z
	"/>
            <g>
                <defs>
                    <rect id="SVGID_DC13_" x="8.5" y="58.9" width="52" height="66"/>
                </defs>
                <clipPath id="SVGID_DC14_">
                    <use xlinkHref="#SVGID_DC13_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className={classes.st14}>
                    <path className={classes.st15} d="M37.2,66.5c0,4.1-1.3,2.3-7.2,3c-4.7,0.5-9.4,2.2-13.8,3.7c-1.2,0.5-1.9,0-2.6,0.7
			c-3.3,4.6-1.7,9.4-2.2,10.5c-0.6,1.4-1.1,3.6-1.1,5.3c0,2.2,0.3,4,0.9,5.5c0.6,4.1,1.7,8.2,3.1,12.2c-1.1,0.6-2.3,1.5-3.3,4
			c-2.5,6.3,1.3,11,7.4,12.8c2.9,0.9,9.9,1,14.5-0.2c5-1.2,7.2-5.5,1.1-6.8c-3.1-0.7-7.5-0.1-8.6,0.1c-1.9,0.3-8,0.4-9.1-1
			c-1.5-1.9,0.4-4.4,1.4-4.6c1.5,1.1,3,2.9,15.2,2.2c14.6-0.7,13.2-10,13.2-21.6c4.3,1.1,7.3,1.1,10.3,0.7c4.9-0.6,5.4-8,0.3-7.9
			c-0.1,1.2,0.4,3-1,3.1c-1.4,0.1-1-1.7-1.1-2.8c-5.2,0.9-8.5,2.2-9.4-3.5c-0.5-2.9-1.1-7.5-2.5-10.1c-0.8-1.5-1.1-0.9-1.1-2.1
			C41.7,68.5,42,66,41,65C39.6,65,38.4,66.1,37.2,66.5z"/>
                    <path className={classes.st16} d="M13.7,71.7c-0.4-1.5,3-1.9,4.6-2.5c4-1.3,7.9-2.3,12.1-2.9c2.2-0.3,5.3-1.3,6.1-1c0,1-0.4,0.7-2.2,1.2
			c-1,0.3-2.2,0.5-3.1,0.6c-4.9,0.8-10.1,2-14.8,3.6C15.6,71.1,14.5,71.9,13.7,71.7z M16.2,73.2c4.4-1.6,9.1-3.2,13.8-3.7
			c5.4-0.6,7,0.8,7.2-2.1c0.1-1.4-1.1-5.7-1.9-6.8c-2.3-3.8-10.8,0.2-13.5,1.8L11.6,67c-5.2,1.8-2.7,4.3,0.5,6
			c0.5,0.2,0.5,0.2,0.9,0.3C14,73.7,14.9,73.7,16.2,73.2z"/>
                    <path className={classes.st17} d="M11.2,95.3c-1.7,0.8-3.6,3.1-2.1,6.5c1.9,4.3,11.2,5.8,17.8,4.1c6.3-1.6,9-6.2,7.7-13
			c-1.2-6.9-13-8.5-17.1-4.8c-0.7,0.6-1.6,2.1-1.4,3.5c0.2,2.8,6,2,7.9,1.8c0.8-0.1,1.4-0.2,2.3,0c1.3,0.2,0.7-0.7,1.7-1.1
			c-0.4-1.1-1.3-1.1-0.4-2.5c0.5-0.3,3.2,0.5,4.2,1.6c0.8,0.7,0.5,1.7-0.4,1.9c-1.1,0.2-1.7-0.2-2.6-0.3c-0.5,0.8-0.4,0.5-0.9,1.1
			c0.4,1,0.6,0.4,1.1,0.2c0.9-0.2,1.3,0.9,0.3,1.1c-0.5,0.2-0.7,0-0.6,0.6c0,0,0.6,0,0.9,0c0.5,0.1,0.8,1.2-0.3,1.1
			c-0.3,0-0.5-0.1-0.7,0.4C28.6,98,29,97.9,29.4,98c0.8,0.3,1,1.2-0.3,1c-0.5-0.1-0.5-0.5-1.8,0.5C21.4,102.5,13.4,99,11.2,95.3z"/>
                    <path className={classes.st16} d="M53.5,78.7c0.6,1,0.4,1.8,2,1.6c1.1-0.1,1.4-0.5,2-1.7c1.5-1.9,1.7-9.1-1.3-11.4
			C56.9,72.4,50.8,72.3,53.5,78.7z"/>
                    <path className={classes.st15} d="M13.7,71.7c0.8,0.1,1.8-0.6,2.6-0.9c4.6-1.6,9.9-2.8,14.8-3.6c0.9-0.2,2.1-0.3,3.1-0.6
			c1.8-0.5,2.3-0.2,2.2-1.2c-0.7-0.3-3.9,0.7-6.1,1c-4.2,0.6-8,1.6-12.1,2.9C16.7,69.8,13.4,70.3,13.7,71.7z"/>
                    <path className={classes.st15} d="M13.4,102.3c0.7,0.8,0.5,1,1.7,1.2c1.5,0.3-0.3,0.2,2.4,0.4c0.9,0.1,1.7,0.5,2.3,0.1
			c0.5-0.4,0.8-1.5,0.5-2.1c-0.5-0.7-2.4-0.6-3.4-1c-1-0.3-0.5,0-1.2,0c-0.8,0-0.2-0.1-0.9-0.4c-0.4-0.1-0.8-0.1-1.2,0
			C12.6,101,12.9,101.8,13.4,102.3z"/>
                    <path className={classes.st18} d="M28.5,82.1c-0.2,1.1,0.5,1.9,1.6,2.3c-0.2,0.8-0.5,1-0.8,1.6c-0.3,0.5,0.2,0.9,0.6,0.5
			c0.4-0.4,0.5-0.8,0.8-1.3c1.6,1.9,3.9,1.6,4.9-0.7c0.2-0.3,0.2-0.7,0.2-1c-0.1-0.8-0.9-1-1.1-0.3c-0.1,0.5,0.3,0.6,0.1,1.1
			c-0.9,1.8-2.9,1.4-3.9,0c0.2-0.4,1.1-1.5,0.3-2.8C30.5,81.2,29.1,81.7,28.5,82.1z"/>
                    <path className={classes.st15} d="M21.3,88.3c-1,0.5-1.3,2.7-1.2,3.1c0.2,0.8,0.9,0.6,1.5,0.5c0.5-0.1,1.5,0.2,2,0c0.7-0.2,0.5-0.8,0.7-1.4
			c0.2-0.6,0.7-0.9,0.4-1.6C24.5,88.1,21.8,88,21.3,88.3z"/>
                    <path className={classes.st15} d="M28.8,100.9c-1-0.2-2.6,0.1-3.3,0.5c-1,0.7-2.3,0.2-2.2,1.5c0.2,1.9,3.3,0.5,4.6,0.5c0.7,0,1-0.1,1.4-0.4
			C29.5,102.2,29.1,101.4,28.8,100.9z"/>
                    <path className={classes.st19} d="M54.8,82.5c0,4.1,0.1,8.3,0.1,12.5c0,5.4,2.4,5.2,2.3,0.2c-0.1-4.4-0.2-8.7-0.3-13
			C56.2,82.6,55.9,82.6,54.8,82.5z"/>
                    <path className={classes.st15}
                          d="M58.2,86.6l-0.6-0.3c-0.5-0.3-1.4-0.4-2,0c-0.5,0.2-0.5,0.6,0.3,0.8C56.7,87.2,57.6,87.1,58.2,86.6z"/>
                    <path className={classes.st15}
                          d="M57.5,88.7l0.1-1c-0.4-0.1-2.4-0.3-2.3,0.6c0,0.5,1.5,0.5,1.9,0.5L57.5,88.7z"/>
                    <path className={classes.st15}
                          d="M57.5,90.2l0.3-0.9c-0.5-0.1-2.5-0.3-2.3,0.6C55.6,90.5,57,90.3,57.5,90.2z"/>
                    <path className={classes.st15}
                          d="M57.6,91.7l0-0.8c-0.3-0.1-2.3-0.1-2.2,0.9c0.1,0.3,1.7,0.2,2,0L57.6,91.7z"/>
                    <path className={classes.st15} d="M54.8,82.5c0.6,0.3,1.7,0.2,2-0.3c0.3-0.6,0.6-2.4,0.7-3.6c-0.5,0.5-2.5,0.7-4.1,0.1
			C53.8,80,54.3,81.8,54.8,82.5z"/>
                    <path className={classes.st15} d="M21,113.1l-0.6-0.9c-0.6,0.5-0.8,3.3,0.2,2C20.7,114,20.8,113.4,21,113.1z M41.1,111.4l-1.1,1
			c0.3,1.3,1.6,2.4,1.3,0.1C41.3,112,41.2,111.9,41.1,111.4z M39.4,112.4l-1.1,0.5c0.1,1.6,1.4,2.7,1.1,0L39.4,112.4z M37.8,113.1
			l-1.2,0.2c-0.2-0.1,0,0.2,0.2,0.5C37.4,115.8,38.1,114.8,37.8,113.1z M36.2,113.3l-1.1,0.3C35.1,114.9,36.3,116.6,36.2,113.3z
			 M25.8,113.4l-1.1,0.1c0,0.4-0.1,2.1,0.4,1.9C25.7,115.1,25.8,114.1,25.8,113.4z M24.1,113.5l-1.3-0.1
			C22.3,114.6,23.1,117.3,24.1,113.5z M22.5,113.3l-1-0.3c-0.1,0.3-0.8,2.8,0.2,2C22,114.7,22.4,113.7,22.5,113.3z"/>
                    <path className={classes.st20} d="M31.8,75c1.6-1,3.4-0.5,4.1,1c0.1,0.3,0.2,0.5,0.2,0.8c0,0.3-0.2,0.6-0.5,0.6c-0.2,0-0.5,0-0.6-0.3
			c-0.1-0.1-0.1-0.3-0.2-0.4c-0.8-1.2-1.4-1.3-2.5-0.5c-0.4,0.3-0.8,0.8-1.2,0.3c-0.5-0.5,0-1,0.4-1.4C31.6,75.2,31.7,75.1,31.8,75z
			"/>
                    <path className={classes.st20} d="M22.7,77.6c0.9-0.5,1.9-0.4,2.9-0.1c0.3,0.1,0.5,0.3,0.5,0.7c-0.1,0.4-0.3,0.6-0.7,0.6c-0.1,0-0.2,0-0.3,0
			c-1.7-0.1-1.7-0.1-2.6,1.6c-0.2,0.3-0.4,0.6-0.8,0.5c-0.5-0.1-0.6-0.5-0.5-0.9C21.3,78.9,21.7,78.1,22.7,77.6z"/>
                </g>
            </g>
        </SVG>
    );
}