import { SaveIcon } from "../../../../icons/SaveIcon";
import { LinkIcon } from "../../../../icons/LinkIcon";
import { DownloadIcon } from "../../../../icons/DownloadIcon";

export const googleDriveInformation = [
    {
        icon: SaveIcon,
        title: 'Save',
        description: 'Upload screenshots & screen recordings allowing to store, manage, and access files from anywhere with an internet connection'
    },
    {
        icon: LinkIcon,
        title: 'Share',
        description: 'Share with anyone & anywhere via link enabling clearer communication, efficient troubleshooting, and precise feedback'
    },
    {
        icon: DownloadIcon,
        title: 'Download',
        description: 'Download screenshots in PNG or JPEG format, and videos in WebM or MP4 format'
    }
];