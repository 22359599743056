import React, { FC, ReactNode } from 'react'
import { ConfigProvider, theme } from "antd";
import useDarkMode from "../hooks/useDarkMode";
import { RerenderProvider } from "./RerenderProvider";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../components/ErrorFallback";

type ProvidersType = {
    children: ReactNode;
}

export const Providers: FC<ProvidersType> = ({ children }) => {
    const isDark = useDarkMode();

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <ConfigProvider
                theme={{
                    algorithm: isDark ? theme.darkAlgorithm : theme.defaultAlgorithm,
                    components: {
                        Switch: {
                            colorPrimary: '#00b96b',
                            colorPrimaryHover: '#02a862',
                        },
                    },
                }}
            >
                <RerenderProvider>
                    {children}
                </RerenderProvider>
            </ConfigProvider>
        </ErrorBoundary>
    );
};