import React, { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import classNames from "classnames";
import { PaddingBox } from "../PaddingBox";
import { Button } from "antd";
import { FlexBox } from "../FlexBox";

type BigButtonType = {
    children: ReactNode,
    className?: string,
    onClick?: () => void,
    disabled?: boolean,
    style?: React.CSSProperties,
}

export const BigButton: FC<BigButtonType> = (
    {
        children,
        className,
        onClick,
        disabled,
        style,
    }
) => {
    const buttonClasses = classNames(className, classes.button, {
        [classes.disabled]: disabled,
    });

    return (
        <Button className={buttonClasses} onClick={onClick} size='large' type='link' disabled={disabled} style={style}>
            <FlexBox justify='center' align='middle'>
                <PaddingBox smallVR>
                    {children}
                </PaddingBox>
            </FlexBox>
        </Button>
    );
}