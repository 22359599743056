import React, { FC } from 'react';
import { SVG } from "../../../../icons/SVG";
import classes from './styles/index.module.scss';
import classNames from "classnames";

type MenuIconPropType = {
    isOpened?: boolean;
}

export const MenuIcon: FC<React.SVGProps<SVGSVGElement> & MenuIconPropType> = ({ isOpened, ...props }) => {
    const path1Classes = classNames(classes.line, { [classes.line1]: isOpened });
    const path2Classes = classNames(classes.line, { [classes.line2]: isOpened });
    const path3Classes = classNames(classes.line, { [classes.line3]: isOpened });

    return (
        <SVG {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" isIcon>
            <path d="M3 4H21V6H3V4ZM9" fill="#232329" className={path1Classes}/>
            <path d="M3 11H21V13H9V11ZM3" fill="#232329" className={path2Classes}/>
            <path d="M3 18H21V20H3V18Z" fill="#232329" className={path3Classes}/>
        </SVG>
    );
}