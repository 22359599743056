import React, { FC } from 'react';
import { SVG } from "../../../icons/SVG";

export const GoogleDriveIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <SVG xmlns="http://www.w3.org/2000/svg" width="55" height="52" viewBox="0 0 55 52" fill="none" {...props}>
        <g clipPath="url(#clip0_115_34)">
            <path d="M3.85729 43.8648L6.28284 48.1305C6.78685 49.0286 7.51136 49.7342 8.36188 50.2474L17.0245 34.9805H-0.300781C-0.300781 35.9747 -0.0487767 36.969 0.455232 37.8671L3.85729 43.8648Z" fill="#0066DA"/>
            <path d="M27.1992 17.0195L18.5366 1.75256C17.686 2.26574 16.9615 2.97135 16.4575 3.8694L0.455233 32.0939C-0.0395073 32.9726 -0.300123 33.9677 -0.300781 34.9805H17.0245L27.1992 17.0195Z" fill="#00AC47"/>
            <path d="M46.0366 50.2474C46.8871 49.7342 47.6116 49.0286 48.1156 48.1305L49.1237 46.3665L53.9432 37.8671C54.4473 36.969 54.6993 35.9747 54.6993 34.9805H37.3727L41.0595 42.3573L46.0366 50.2474Z" fill="#EA4335"/>
            <path d="M27.1993 17.0194L35.8619 1.75255C35.0114 1.23937 34.0349 0.982788 33.0269 0.982788H21.3717C20.3637 0.982788 19.3871 1.27145 18.5366 1.75255L27.1993 17.0194Z" fill="#00832D"/>
            <path d="M37.374 34.9805H17.0246L8.36194 50.2474C9.21245 50.7605 10.189 51.0171 11.197 51.0171H43.2016C44.2096 51.0171 45.1861 50.7285 46.0366 50.2474L37.374 34.9805Z" fill="#2684FC"/>
            <path d="M45.9421 17.9817L37.9409 3.8694C37.4369 2.97135 36.7124 2.26574 35.8619 1.75256L27.1992 17.0195L37.3739 34.9805H54.6677C54.6677 33.9862 54.4157 32.992 53.9117 32.0939L45.9421 17.9817Z" fill="#FFBA00"/>
        </g>
        <defs>
            <clipPath id="clip0_115_34">
                <rect width="55" height="50.0344" fill="white" transform="translate(-0.300781 0.982788)"/>
            </clipPath>
        </defs>
    </SVG>
);